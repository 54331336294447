import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 * Regex for Plain URL Text
 * @type {RegExp}
 */
const linkRegexPattern = new RegExp(/(https?\/:\/\/)?(www\.)?[^\s]+\[^\s]+/g);

/**
 * Regex for Links with multiple query parameters
 * @type {RegExp}
 */
const linkUrlParamsRegexPattern = new RegExp(/(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);

/**
 * Regex for anchors in URL
 * @type {RegExp}
 */
const linkAnchorRegexPattern = new RegExp(/<a[^>]*>(.*?)<\/a>/g);

export default class NimbusUtilities {
    parseRowValue(data, col) {
        let colName = col.field;
        let colArr = colName.split('.');

        if (colArr.length > 1 && data[colArr[0]] !== null && data[[colArr[0]]] !== undefined) {
            if (data[colArr[0]][colArr[1]] !== undefined && data[colArr[0]][colArr[1]][colArr[2]] !== undefined) {
                return data[colArr[0]][colArr[1]]['firstname'] + ' ' + data[colArr[0]][colArr[1]]['lastname'] ?? ''; // temporary workaround for created by
            } else if (data[colArr[0]][colArr[1]] !== null) {
                return data[colArr[0]][colArr[1]] ?? '';
            }
        } else {
            return data[colName];
        }
    }

    nullChecker(data) {
        if (typeof data === undefined || data === undefined || data === null) {
            return 'N/A';
        }
        return data;
    }

    parseDropDownValues(data) {
        
        var values = [];

        if (Array.isArray(data)) {
            data.forEach((element) => {
                values.push({
                    name: element.name,
                    id: element.id,
                });
            });
        }

        return values;
    }

    returnStatusClasses(prefix) {
        return [
            { name: 'Low', class: prefix + '-neutral' },
            { name: 'Medium', class: prefix + '-orange' },
            { name: 'High', class: prefix + '-danger' },
            { name: 'New', class: prefix + '-info' },
            { name: 'Assigned', class: prefix + '-info' },
            { name: 'Escalated', class: prefix + '-orange' },
            { name: 'Waiting on end user', class: prefix + '-info' },
            { name: 'Waiting on provider', class: prefix + '-info' },
            { name: 'Closed', class: prefix + '-neutral' },
            { name: 'Completed', class: prefix + '-success' },
            { name: 'Duplicate', class: prefix + '-danger' },
            { name: 'Invalid', class: prefix + '-danger' },
            { name: 'Bubble', class: 'numberCircle' },
            { name: 'Successful', class: prefix + '-success' },
            { name: 'Failed', class: prefix + '-danger' },
        ];
    }

    getStatusClassName(data, prefix) {
        let dataClass = this.returnStatusClasses(prefix).filter((status) => {
            return status.name === data;
        });

        return dataClass.length > 0 ? dataClass[0].class : '';
    }

    /**
     * This will convert the error from API validation
     *
     * @param data error.response.data.errors
     * @returns {string}
     */
    parseValidationErrorMessage(data) {
        let errors = '';
        if (data) {
            Object.keys(data).forEach((key, index) => {
                data[key].forEach((error) => {
                    errors += error;
                });
            });
        }

        return errors;
    }

    displayValidationNimbusForms(refs, errors) {
        for (var errorDisplay in refs) {
            if (refs[errorDisplay]) refs[errorDisplay].innerText = '';
        }

        for (const [key, value] of Object.entries(errors)) {
            if (refs[key]) refs[key].innerText = value.join();
        }
    }

    /**
     * Convert timestamp field from Calendar component to ISO Local for Oracle Compatibility
     * @param d date
     * @returns {string}
     */
    toISOLocal(d) {
        let timezone = (n) => ('0' + n).slice(-2);
        let timezoneMilli = (n) => ('00' + n).slice(-3);
        let offset = d.getTimezoneOffset();
        let sign = offset > 0 ? '-' : '+';
        offset = Math.abs(offset);
        let preFormattedDate =
            d.getFullYear() +
            '-' +
            timezone(d.getMonth() + 1) +
            '-' +
            timezone(d.getDate()) +
            'T' +
            timezone(d.getHours()) +
            ':' +
            timezone(d.getMinutes()) +
            ':' +
            timezone(d.getSeconds()) +
            '.' +
            timezoneMilli(d.getMilliseconds()) +
            sign +
            timezone((offset / 60) | 0) +
            ':' +
            timezone(offset % 60);

        return preFormattedDate.replace(/T/, ' ').replace(/\..+/, '');
    }
}

/**
 * Return initials of name
 * i.e:
 * - John Doe returns JD
 * - John Doe Smith returns JS
 * @param string
 * @returns {string}
 */
export function nameInitials(string) {
    if (!string) {
        return '';
    }

    return string
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
}

/**
 * Map all ref elements and display validation error
 *
 * @param {*} pageName ex order_types
 * @param {*} index 0
 * @param {*} name name of the attribute or model ex key
 * @returns order_types.0.key
 * look for all elements with ref order_types.0.key
 */
export function parseValidationElement(pageName, index, name) {
    return `${pageName}.${index}.${name}`;
}

/**
 * Format currency
 *
 * @param value
 * @returns {string}
 */
export function formatCurrency(value) {
    let formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumSignificantDigits: 2,
    });

    return formatter.format(value);
}

export function formatInternationalNumber(value, numberFormat = 'en-AU') {
    let formatter = new Intl.NumberFormat(numberFormat, {
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
}

export function formatAmountWithoutCurrency(value) {
    let formatter = new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: 2,
    });

    return formatter.format(value);
}

/**
 * Reassign the object to maintain reactivity
 * @param {*} model
 * @returns
 */
export function insertNewModel(model) {
    let temp = {};
    Object.assign(temp, model);
    Object.keys(temp).forEach((i) => (temp[i] = null));

    return temp;
}

export function getTemporaryRefNumbers(length) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
}

export function nullChecker(data) {
    if (typeof data === undefined || data === undefined || data === null) {
        return 'N/A';
    }
    return data;
}

export function parsePhoneNumber(data) {
    let _phoneNumber = { prefix: '', suffix: data ? data.phonenumber : '' };
    if (typeof data != undefined || data != undefined || data != null) {
        if (data?.phonenumber) {
            let _parsed = parsePhoneNumberFromString(data.phonenumber);
            if (_parsed) {
                _phoneNumber = {
                    prefix: '+' + _parsed.countryCallingCode,
                    suffix: _parsed.nationalNumber,
                };
            }
        }
    }
    return _phoneNumber;
}

/**
 * Return corresponding data for statuses
 *
 * @param value
 */
export function statusLabelFormat(value) {
    const statuses = [
        {
            label: ['services-active'],
            class: 'bg-green-700 text-white',
        },
        {
            label: ['services-cancelled'],
            class: 'bg-pink-600 text-white',
        },
        {
            label: ['services-pendingcancellation', 'services-pending'],
            class: 'bg-blue-100 text-blue-800',
        },
        {
            label: ['services-preactive', 'internal'],
            class: 'bg-bluegray-100 text-blue-gray-700',
        },
        {
            label: ['services-scpportcancel', 'services-pendingprovcancel'],
            class: 'bg-pink-100 text-pink-800',
        },
        {
            label: ['services-initialised'],
            class: 'bg-cyan-100 text-cyan-800',
        },
        {
            label: ['services-aborted'],
            class: 'bg-orange-100 text-orange-800',
        },
        {
            label: ['ticket-high'],
            class: 'bg-pink-200 text-pink-800',
        },
        {
            label: ['ticket-low'], // neutral
            class: 'bg-bluegray-200 text-bluegray-800',
        },
        {
            label: ['ticket-medium'],
            class: 'bg-orange-200 text-orange-800',
        },
        {
            label: ['customer visible', 'escalated'],
            class: 'bg-yellow-100 text-yellow-800',
        },
        {
            label: ['ticket-new'],
            class: 'nb-ticket-status-new',
        },
        {
            label: ['ticket-assigned'],
            class: 'nb-ticket-status-assigned',
        },
        {
            label: ['ticket-escalated'],
            class: 'nb-ticket-status-escalated',
        },
        {
            label: ['ticket-in-progress'],
            class: 'nb-ticket-status-in-progress',
        },
        {
            label: ['ticket-completed'],
            class: 'nb-ticket-status-completed',
        },
        {
            label: ['ticket-waiting-on-end-user'],
            class: 'nb-ticket-status-waiting-on-end-user',
        },
        {
            label: ['ticket-waiting-on-provider'],
            class: 'nb-ticket-status-waiting-on-provider',
        },
        {
            label: ['ticket-closed'],
            class: 'nb-ticket-status-closed',
        },
        {
            label: ['ticket-duplicate'],
            class: 'nb-ticket-status-duplicate',
        },
        {
            label: ['ticket-invalid'],
            class: 'nb-ticket-status-invalid',
        },
        {
            label: ['linked-service-active'],
            class: 'nb-linked-service-status-active',
        },
        {
            label: ['payment-successful', 'payment-active'],
            class: 'nb-payment-status-successful',
        },
        {
            label: ['payment-deleted'],
            class: 'nb-payment-status-failed',
        },
        {
            label: ['payment-enabled', 'payment-info-enabled'],
            class: 'nb-payment-account-bar-status-enabled',
        },
        {
            label: ['payment-info-disabled'],
            class: 'nb-account-status-inactive',
        },
        {
            label: ['payment-info-suspended', 'payment-info-suspended by system'],
            class: 'nb-account-status-suspended'
        },
        {
            label: ['payment-status-successful', 'payment-status-precompleted'],
            class: 'nb-payment-status-successful'
        },
        {
            label: ['payment-status-awaiting processing', 'payment-status-uncleared'],
            class: 'bg-bluegray-100 text-blue-gray-700'
        },
        {
            label: ['payment-status-system failure', 'payment-status-prefailed'],
            class: 'bg-pink-100 text-pink-800'
        },
        {
            label: ['payment-system failure'],
            class: 'bg-pink-100 text-pink-800'
        },
        {
            label: ['payment-failed'],
            class: 'bg-pink-700 text-pink-50'
        },
        {
            label: ['payment-status-pending', 'payment-status-withdrawn', 'payment-status-returned to agent'],
            class: 'bg-blue-100 text-blue-900'
        },
        {
            label: ['payment-pending'],
            class: 'bg-blue-100 text-blue-900'
        },

        {
            label: ['payment-status-processing', 'payment-status-submitted in ui', 'payment-status-submitted pending approval'],
            class: 'bg-blue-900 text-blue-100'
        },
        {
            label: ['payment-status-failed', 'payment-status-rejected'],
            class: 'bg-pink-700 text-pink-50'
        },
        {
            label: ['payment-status-voided'],
            class: 'bg-bluegray-700 text-bluegray-50'
        },
        {
            label: ['account-active'],
            class: 'nb-account-status-active',
        },
        {
            label: ['account-inactive'],
            class: 'nb-account-status-inactive',
        },
        {
            label: ['account-cancelled'],
            class: 'nb-account-status-cancelled',
        },
        {
            label: ['account-restricted-by-parent'],
            class: 'nb-account-status-restricted-by-parent',
        },
        {
            label: ['account-preactive'],
            class: 'nb-account-status-preactive',
        },
        {
            label: ['account-suspended', 'account-suspended-billing-and-order', 'account-system-suspended'],
            class: 'nb-account-suspended',
        },
        {
            label: ['active', 'preactive', 'completed', 'processed'],
            class: 'badge-success',
        },
        {
            label: ['inactive', 'grandfathered', 'cancelled', 'suspended', 'pending', 'processing', 'pendingprovcancel'],
            class: 'badge-neutral',
        },
        {
            label: ['deleted', 'restricted by parent', 'system suspended', 'suspended - billing and ordering', 'failed', 'aborted'],
            class: 'badge-danger',
        },
        {
            label: ['invoice-list-approval-approved'],
            class: 'badge-success'
        },
        {
            label: ['invoice-list-approval-pending'],
            class: 'bg-blue-100 text-blue-900'
        },
        {
            label: ['invoice-list-approval-rejected'],
            class: 'bg-pink-700 text-pink-50'
        },
        {
            label: ['invoice-list-approval-rerating'],
            class: 'bg-blue-900 text-blue-100'
        },
        {
            label: ['invoice-list-approval-approved_-_do_not_send'],
            class: 'bg-green-800 text-white'
        },
        {
            label: ['invoice-list-approval-approval in progress'],
            class: 'bg-bluegray-100 text-blue-gray-700'
        }
    ];

    const matchedStatus = statuses.find((status) => status.label.includes(value.toLowerCase()));

    // Add a fallback
    return matchedStatus || { label: ['default'], class: 'bg-gray-200 text-gray-800' }
}

/**
 * format value by currency or percentage
 * @param value
 * @param type
 * @returns {string}
 */
export function formatCurrencyPercentage(value, type) {
    let formatValue = formatCurrency(value);

    if (type !== 'type.fixed') {
        formatValue = `${value} %`;
    }
    return formatValue;
}

/**
 * get decimal value of percentage
 * @param value
 * @returns {number}
 */
export function percentageToAmount(value) {
    let finalValue = nullChecker(value);
    return parseFloat(finalValue) / 100;
}

/**
 * parse the object from the column field datatable to get the value
 * @param data
 * @param col
 * @returns {*|string|string}
 */
export function parseRowValue(data, col) {
    let colName = col.field;
    let colArr = colName.split('.');

    if (colArr.length > 1 && data[colArr[0]] !== null) {
        return parseData(data, colName.split('.'));
    } else {
        return data[colName];
    }

}

/**
 * parse value base on key to get the value
 * @param data
 * @param key
 * @returns {*|string|string}
 */
export function parseColumnValue(data, key) {
    return parseData(data, key.split('.'));
}

export function parseData(data, colName) {
    let colArr = colName;
    if (data !== null) {
        if (colArr.length > 0) {
            return parseData(data[colArr.splice(0, 1)], colArr);
        } else {
            return data;
        }
    } else {
        return '';
    }
}

/**
 * Generic rounding of amounts
 * @param amount
 * @return {string}
 */
export function roundAmount(amount, numDecimals = 2) {
    return parseFloat(amount).toFixed(numDecimals);
}

/**
 * Get contrasting text color depending on color
 *
 * Blank Hex Color will return white to be used as color
 *
 * @param hexcolor
 * @returns {string}
 */
export function getTextColor(hexcolor) {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
        hexcolor = hexcolor
            .split('')
            .map(function (hex) {
                return hex + hex;
            })
            .join('');
    }

    // Convert to RGB value
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    let yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? 'black' : 'white';
}

/**
 * Check if a valid color name
 * @param strColor
 * @return {boolean}
 */
function isColor(strColor) {
    let s = new Option().style;
    s.color = strColor;
    return s.color === strColor;
}

/**
 * Check color name, prepend # or return false if invalid
 * @param str
 * @return {string|boolean}
 */
export function getColor(str) {
    if (!str) return false;

    const FIRST_STRING = 0;
    const MIN_HEXCODE_LENGTH = 4;
    const MAX_HEXCODE_LENGTH = 7;

    if (isColor(str)) {
        return str;
    }

    if (str[FIRST_STRING] !== '#') {
        str = '#' + str;
    }

    if (!(str.length === MIN_HEXCODE_LENGTH || str.length === MAX_HEXCODE_LENGTH)) {
        return false;
    }

    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    return regex.test(str) === true ? str : false;
}

/**
 * Set CSS variables
 *
 * @param cssVar
 * @param hexColor
 */
export function setThemeColor(cssVar, hexColor) {
    const textColor = getTextColor(hexColor);

    const css = `:root {
                    --${cssVar}-color: #${hexColor} ;
                    --${cssVar}-color-text: ${textColor} ;
                }`;

    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.appendChild(document.createTextNode(css));
}

/**
 * Strong regex for password validation
 *
 * medium
 * At least one lowercase
 * At least one uppercase or one number
 * Minimum 6 characters
 *
 * strong
 * At least one lowercase
 * At least one uppercase
 * At least one numeric
 * Minimum 8 characters
 *
 * @param type
 * @return {object}
 */
export function passwordRegex(type = 'strong') {
    return type === 'strong' ? '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})' : '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).';
}

/**
 * Escape string for REGEX prep
 * @param string
 * @returns {*}
 */
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Allow numbers and dot in text field
 *
 * @param evt
 * @returns {boolean}
 */
export function numbersOnly(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

/**
 * Convert string to url with specific delimeter
 * @param data
 * @param delimeter
 * @returns {string}
 */
export function convertStringToUrl(data, delimeter = '-') {
    return data.replace(/[^A-Z0-9]/gi, delimeter).toLowerCase();
}

/**
 * Remove url protocol
 * @param {*} url
 * @returns
 */
export function removeHttp(url) {
    if (url) {
        if (url.startsWith('https://')) {
            const https = 'https://';
            return url.slice(https.length);
        }

        if (url.startsWith('http://')) {
            const http = 'http://';
            return url.slice(http.length);
        }
    }

    return url;
}

/**
 * Complete url to access with protocol
 * @param {*} url
 * @returns
 */
export function completeHttp(url) {
    return 'https://' + url;
}

/**
 * Format newline to breakline
 *
 * @param text
 * @returns {string}
 */
export function formatTextWithBreakline(text) {
    return String(text).replace(/\n/g, '<br />');
}

/**
 * Strip the Content to masks all the links
 *
 * @param content
 * @returns {*}
 */
export function maskedContentLinks(content) {
    const replacementText = '<b>**Link available only to recipients**</b>';

    if (content) {
        content = content.replace(linkRegexPattern, replacementText)
            .replace(linkUrlParamsRegexPattern, replacementText)
            .replace(linkAnchorRegexPattern, replacementText);

    }

    return content;
}


/**
 * get the bubble icon value
 * @param bubbleIconList
 * @param data
 * @returns []
 */
export function getBubbleIconValue(bubbleIconList, data) {

    let defaultBubbleIconList = [
        {
            toCheckValue: 0,
            displayValue: 'No',
            badgeClass: 'badge-danger',
            iconClass: 'pi pi-times vertical-align-middle',
        },
        {
            toCheckValue: 1,
            displayValue: 'Yes',
            badgeClass: 'badge-success',
            iconClass: 'pi pi-check vertical-align-middle',
        },
    ];

    let statusBubbleClass = (bubbleIconList.length > 0) ? bubbleIconList : defaultBubbleIconList;
    let bubbleIconValue = statusBubbleClass.filter((status) => {
        return status.toCheckValue == data;
    });

    return bubbleIconValue.length > 0 ? bubbleIconValue[0] : null;
}

/**
 * parsed the grouped object property to request form
 * @param objectProperties
 * @returns {*[]}
 */
export function parseObjectPropertyRequest(objectProperties) {

    if (objectProperties.length === 0) return [];
    let parsedObject = {};

    objectProperties.forEach(data => {
        parsedObject = { ...parsedObject, ...{ [data.key]: data.value ?? '' } };
    });
    return parsedObject;

}

/**
 * get object by key
 * @param obj
 * @returns {{id: *, attribute_value: *, operator}|null}
 */
export function getKeyValueObjectByKey(obj) {
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            return {
                'id': obj[key].typeid,
                'operator': obj[key].operator,
                'attribute_value': obj[key].attribute_value,
            };
        }
    }

    return null;
}

/**
 * Show or hide the child property
 * @param objectProperties
 * @param currentProperty
 * @returns {boolean}
 */
export function shouldShowChildProperty(objectProperties, currentProperty) {
    let shouldShow = false;
    let parentObject = getKeyValueObjectByKey(currentProperty.parent_info);

    if (parentObject !== null) {

        // get the parent property
        let selectedParentObject = objectProperties.find((obj) => obj.id === parentObject.id);
        let operator = parseInt(parentObject.operator);

        // check if the selected value of parent property is equal
        // to attribute value then show the property otherwise hide
        switch (operator) {
            case 0:
                shouldShow = true;
                break;
            case 1:
                if (selectedParentObject.value == parentObject.attribute_value) {
                    shouldShow = true;
                }
                break;
            case 2:
                if (selectedParentObject.value >= parentObject.attribute_value) {
                    shouldShow = true;
                }
                break;
            case 4:
                if (selectedParentObject.value <= parentObject.attribute_value) {
                    shouldShow = true;
                }
                break;
            case 8:
                if (selectedParentObject.value != parentObject.attribute_value) {
                    shouldShow = true;
                }
                break;
            default:
                shouldShow = false;
        }
    } else {
        shouldShow = true;
    }
    return shouldShow;
}

export function getMaskedCardNumber(number) {
    const cardNumberLength = number.length;
    if (cardNumberLength < 5) {
      return '*'.repeat(cardNumberLength);
    }
    const masked = '*'.repeat(cardNumberLength - 4) + number.slice(-4);
    return masked.match(/.{1,4}/g).join(' ');
  };
