export default [
    {
        path: '/login',
        name: 'login',
        exact: true,
        component: () => import('@/pages/login/Login.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/mfa',
        name: 'mfa',
        exact: true,
        component: () => import('@/pages/login/Login.vue'),
        meta: {
            auth: false,
        },
    },
];
