import {defineStore} from 'pinia';
import {ref} from 'vue';
import { arrayBufferFromBase64, decryptData } from '@/utilities/crypto';
import { UserPortalConstants } from '@/service/Constants';

export const useEUPStore = defineStore(
    'eup-store',
    () => {
        const eupSettings = ref({});

        /** Set the details for user upon authenticated **/
        const setEUPSettings = async () => {
            if (sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_KEY) !== null && sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_CRYPT_IV) !== null) {
                const
                    encryptedData = arrayBufferFromBase64(sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_KEY)),
                    iv = arrayBufferFromBase64(sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_CRYPT_IV));

                const decryptedData = await decryptData(encryptedData, iv, process.env.VUE_APP_ENCRYPTION_KEY);
                eupSettings.value = JSON.parse(decryptedData);
            }
        };

        return {
            /**
             * States
             */
            eupSettings,
            /**
             * Actions
             */
            setEUPSettings
        }
    });
