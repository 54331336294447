<template>
    <NimbusInputWrapper v-bind="$props" ref="wrapper" @right-icon-click="(e) => $emit('rightIconClick', e)">
        <template #input>
            <div class="w-full">
                <Password
                    v-bind="$attrs"
                    v-if="template"
                    :modelValue="modelValue"
                    class="w-full"
                    :class="[inputClass]"
                    :inputClass="[wrapper?.inputBorderColorClass, 'niw-dynamic-border w-full']"
                    :strongRegex="strongRegex"
                    @input="updateInput">
                        <template #header>
                            <slot name='header'></slot>
                        </template>
                        <template #footer>
                            <slot name='footer'></slot>
                        </template>
                </Password>
                <!-- v-tooltip directive throws error when given a null value -->
                <Password
                    v-else-if="tooltip"
                    :disabled="disabled"
                    v-tooltip="tooltip"
                    v-bind="$attrs"
                    :class="[inputClass]"
                    @input="updateInput"
                    :modelValue="modelValue"
                    class="w-full"
                    :inputClass="[wrapper?.inputBorderColorClass, 'niw-dynamic-border w-full']"
                />
                <!-- render component with no v-tooltip directive if tooltip is null -->
                <Password
                    v-else
                    :feedback="feedback"
                    :disabled="disabled"
                    v-bind="$attrs"
                    :class="[inputClass]"
                    @input="updateInput"
                    :modelValue="modelValue"
                    class="w-full"
                    :inputClass="[wrapper?.inputBorderColorClass, 'niw-dynamic-border w-full']"
                />
            </div>
        </template>
        <template #bottom>
            <slot></slot>
        </template>
    </NimbusInputWrapper>
</template>

<script>
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';
import Password from 'primevue/password';
import {passwordRegex} from '@/service/NimbusUtilities';

import { ref } from 'vue';

export default {
    name: 'NimbusInputPassword',
    components: {
        NimbusInputWrapper,
        Password,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: String,
        },
        template: {
            type: [String, Boolean],
            default: null,
        },
        tooltip: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        feedback: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);
        const strongRegex = ref(passwordRegex('strong'));

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = (event) => {
            emit('update:modelValue', event.target.value);
        };

        return {
            /**
             * Data
             */
            wrapper,
            strongRegex,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>
