export default [
    {
        path: '/reconciliation',
        name: 'bank-reconciliation.access',
        exact: true,
        component: () => import('@/pages/finance/reconciliation/Main.vue'),
        meta: {
            auth: true,
            permission: ['bank-reconciliation.access']
        },
    },
]