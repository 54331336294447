<template>
    <EupLogin v-if="isEup"/>
    <Login v-else/>
</template>

<script>
import { onMounted, ref } from 'vue';
import Login from "./Login";
import EupLogin from "@/pages/end_user_portal/login/EupLogin";
import {getEndUserPortalHost} from '@/service/EndUserPortalService';
import { UserPortalConstants } from '@/service/Constants';

export default {
    components: {
        Login,
        EupLogin
    },
    setup() {
        // computed value to check whether we need to show the EUP or Default Login
        const isEup = ref(false);

        onMounted(() => {
            isEup.value = getEndUserPortalHost() ? true : false
        })

        return {
            isEup,
        };
    },
};
</script>

<style scoped></style>
