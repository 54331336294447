export default [
    {
        path: '/main',
        name: 'page-group.access',
        exact: true,
        component: () => import('@/pages/pagegroup/Main.vue'),
        meta: {
            auth: true,
            permission: ['page-group.access']
        },
    },
]