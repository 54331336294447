import * as http from '@/core/apis';

/**
 * Request for forgot password
 * @param {*} payload 
 * @returns 
 */
export async function forgotPassword(payload) {
    return await http.post(`forgot-password`, payload);
}

/**
 * Request for reset password
 * @param {*} id 
 * @param {*} token 
 * @returns 
 */
export async function resetPassword(id, token) {
    return await http.get(`reset-password/${id}/${token}`);
}

/**
 * Update password
 * @param {*} token 
 * @param {*} payload 
 * @returns 
 */
export async function updatePassword(payload) {
    return await http.put(`update-password`, payload);
}