export default class DataTableService {

    setColumnItems(tableId, value) {
        localStorage.setItem('nimbus-dt-columns-' + tableId, JSON.stringify(value));
    }

    getColumnItems(tableId) {
        return JSON.parse(localStorage.getItem('nimbus-dt-columns-' + tableId));
    }

    hasColumnItems(tableId) {
        let lsColumn = this.getColumnItems('nimbus-dt-columns-' + tableId);

        return !(lsColumn === 'null' || lsColumn === null);
    }

    removeColumnItems(tableId) {
        localStorage.removeItem('nimbus-dt-columns-' + tableId);
    }

    setFilterItems(tableId, value) {
        localStorage.setItem('nimbus-dt-filter-' + tableId, JSON.stringify(value));
    }

    getFilterItems(tableId) {
        return JSON.parse(localStorage.getItem('nimbus-dt-filter-' + tableId));
    }

    hasFilterItems() {
        let lsFilter = this.getFilterItems();
        return !(lsFilter === 'null' || lsFilter === null);
    }

    removeFilterItems(tableId) {
        localStorage.removeItem('nimbus-dt-filter-' + tableId);
    }

    removeColumnFilterItems(tableId) {
        localStorage.removeItem('nimbus-dt-columns-' + tableId);
        localStorage.removeItem('nimbus-dt-filter-' + tableId);
    }

    clearDTColumnsAndFilter() {
        let keys = Object.keys(localStorage);
        let i = keys.length;

        while (i--) {
            if (keys[i].includes("nimbus-dt-")) {
                localStorage.removeItem(keys[i]);
            }
        }

    }
}
