export default [
    {
        path: '/services',
        // name: 'portal.services.access',
        name: 'services.access',
        exact: true,
        component: () => import('@/pages/services/Main.vue'),
        meta: {
            auth: true,
            permission: ['services.access', 'portal.services.access'],
            title : 'Services List'
        },
    },
];
