import t from '@/service/Translate';

export const ACCOUNT_VERIFICATION = {
    pageTitle: t('lbl_account_verified', 'Account Verified'),
    description: t(
        'msg_account_verified_desc',
        'Thank you for verifying your account. In order to finish setting up please create a username and password for your account.',
    ),
    btnSave: t('btn_save', 'Save'),
};

export const DIALOG_DETAILS = {
    SUCCESS: {
        icon: 'pi-thumbs-up',
        iconSeverity: 'success',
        summary: 'Your username and password has been saved',
        detail: 'You can now use your login credentials next time you log in to your account.',
        btnLabel: 'Start using your account',
        btnSeverity: 'primary',
        btnOutline: false,
    },
    EXPIRED: {
        icon: 'pi-exclamation-circle',
        iconSeverity: 'danger',
        summary: 'Email verification link has already expired',
        detail: 'We\'re sorry but your verification is no longer valid.',
        btnLabel: 'Resend email',
        btnSeverity: 'secondary',
        btnOutline: true,
    },
    INVALID_TOKEN: {},
};
