<template>
    <div class="grid center">
        <div class="col-12" style="margin-top: 200px;">
            <img src="assets/layout/images/404-graphic.svg" alt="Error 404" style="width:300px;"/>
            <h1 class="nimbus-text-gray">{{ $t('lbl_page_not_found', 'Page Not Found') }}</h1>
            <h2 class="nimbus-text-gray-faded">{{ $t('lbl_error_404', 'Error 404') }}</h2>

            <h4 class="nimbus-text-gray">
                {{ $t('lbl_rsrc_not_available', 'Request Resource Not Available') }} <br/>
                <small
                    class="nimbus-text-gray-faded">{{ $t('lbl_page_not_found_description_1', 'We could not find the page you are looking for')
                    }}</small> <br/>
                <small
                    class="nimbus-text-gray-faded">{{ $t('lbl_page_not_found_description_2', 'It may have been moved, renamed or might not even existed. Please contact your administrator for help')
                    }}</small>
            </h4>
        </div>
    </div>
</template>

<script>
import UserAuthenticationService from '../service/UserAuthenticationService';

export default {
    data() {
        return {
            userService: new UserAuthenticationService(),
            prevPage: '',
            shown: false,
            page: null,
        };
    },
    mounted() {
        this.page = JSON.parse(this.userService.getPreviousVisitedUrl());

        if (!this.userService.isAuthenticated()) {
            this.prevPage = 'login';
            this.page.fullPath = '/login';
            this.$router.push('404');
        } else {
            if (this.userService.getPreviousVisitedUrl()) {

                //Default pages return to ticket or Dashboard
                if (this.page.fullPath == '/' || this.page.fullPath == '/access-denied' || this.page.fullPath == '/error' || this.page.fullPath == '/not-found' || this.page.path == '/login') {
                    this.prevPage = 'ticket';
                    this.page.fullPath = '/ticket';
                } else {
                    this.prevPage = this.page.fullPath.replace('/', '');
                }

                this.shown = true;
            }
        }
    },
    methods: {
        goToPage() {
            this.$router.push(this.page.fullPath);
        },
    },
};
</script>
