import LanguageService from "@/service/LanguageService";

const translate = (string, fallbackText = "") => {
  const languageService = new LanguageService();
  const translation = JSON.parse(languageService.getTranslation())

  //Check the translation if undefined, if true return the fallback text
  if (typeof translation[string] === "undefined") {
    return fallbackText;
  }

  return translation[string];
}

export default translate;