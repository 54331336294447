<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="assets/layout/images/emersion-logo-blue.png" alt="Nimbus Logo" />
            <span class="app-name">Project Nimbus &trade; </span>
        </div>
        <span class="copyright">&#169; Emersion Systems Pty Ltd - 2021</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
};
</script>