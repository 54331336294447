export default [
    {
        path: '/change-payment-method/:tokenRequestId',
        name: 'change.payment.method.otp',
        exact: true,
        component: () => import('@/pages/finance/payment/payment-methods/credit-cards/OTPCreditCardVerification.vue'),
        meta: {
            auth: false,
            title : 'Verify your otp'
        },
    }
]