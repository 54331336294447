import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLoaderStore = defineStore(
    'loader-store',
    () => {
        /**
         * States
         */
        const isLoading = ref(false);

        /**
         * Getters
         */

        /**
         * Actions
         */
        const setLoader = (status) => {
            isLoading.value = status;
        }

        return {
            /**
             * States
             */
            isLoading,

            /**
             * Getters
             */

            /**
             * Actions
             */
            setLoader,
        };
    },
);