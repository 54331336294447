import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import UserAuthenticationService from '@/service/UserAuthenticationService';

export const useAuthStore = defineStore(
    'auth',
    () => {
        const authService = new UserAuthenticationService();

        /**
         * States
         */
        /** auth details **/
        const details = ref();
        const accessToken = ref(authService.getToken() || '');

        /**
         * Getters
         */
        const isAuthenticated = computed(()=> !!accessToken.value);
        const headerConfig = computed(() => ({
            headers: {
                Authorization: `Bearer ${ accessToken.value }`
            }
        }));

        /**
         * Actions
         */
        async function loadDetails() {
            details.value = await authService.getAuthenticatedUser();
        }

        return {
            /**
             * States
             */
            details,

            /**
             * Getters
             */
            headerConfig,

            /**
             * Actions
             */
            loadDetails,
        };
    },
);