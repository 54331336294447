export default [
    {
        path: '/starter',
        name: 'Starter Page',
        exact: true,
        component: () => import('@/pages/starter_page/Index.vue'),
        meta: {
            auth: true,
            permission: ['starter.access']
        },
    },
    {
        path: '/starter/create',
        name: 'Create Starter',
        exact: true,
        component: () => import('@/pages/starter_page/Create.vue'),
        meta: {
            auth: true,
            permission: ['starter.create']
        },
    },
    {
        path: '/starter/:id',
        name: 'View Starter',
        exact: true,
        component: () => import('@/pages/starter_page/View.vue'),
        meta: {
            auth: true,
            permission: ['starter.view']
        },
    },
    {
        path: '/single-page',
        name: 'single-page',
        component: () => import('@/pages/SinglePageComponent.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/component-gallery',
        name: 'component.gallery.access',
        exact: true,
        component: () => import('@/pages/nimbus/Main.vue'),
        meta: {
            auth: true,
            permission: ['component.gallery.access']
        },
    },
    {
        path: '/test-order',
        name: '',
        exact: true,
        component: () => import('@/pages/nimbus/TestOrder.vue'),
        meta: {
            auth: false
        },
    }
]