<template>
    <layout>
        <div>
            <DataTable
                class="p-datatable-sm"
                @export-to-csv="exportCSV"
                stripedRows
                responsiveLayout="scroll"
                breakpoint="960px"
                :value="rows"
                ref="dt"
                :lazy="true"
                :resizableColumns="false"
                columnResizeMode="fit"
                :totalRecords="totalRecords"
                :loading="loading"
                :rows="20"
                :paginator="true"
                :rowsPerPageOptions="[20, 50, 100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                @page="onPage($event)"
                @sort="onSort($event)"
                filterDisplay="false"
                v-model:filters="filters"
            >
                <!-- @state-restore="onStateRestore($event)" stateStorage="session" stateKey="session-dt-tasks-index" -->
                <!-- :globalFilterFields="['name', 'description', 'created_at', 'status.name']" -->

                <Column v-for="col of tableColumnHeader" :field="col.field" :header="col.header" :key="col.field"
                        :sortable="col.sortable" :ref="col.field" :filterField="col.field" :showFilterMatchModes="false" :showFilterMenu="false">
                    <!-- <template #body="slotProps" v-if="col.field == 'name'">
                        <inertia-link :href="`/tasks/${slotProps.data.id}`" title="Show">
                            <Button :label="slotProps.data.name" class="p-button-text p-button-rounded" />
                        </inertia-link>
                    </template> -->
                    <!-- <template #body="slotProps" v-else-if="col.field == 'status.name'">
							<span :class="'customer-badge status-' + slotProps.data.status">{{slotProps.data.status}}</span>
						</template> -->
                    <!-- <template #filter="{ filterModel, filterCallback }" v-if="col.field == 'status.name'">
                        <Dropdown v-model="filterModel.value" :options="statuses" optionLabel="label" optionValue="value" placeholder="Any" class="p-column-filter" :showClear="true" @change="filterCallback()" />
                    </template>
                    <template #filter="{ filterModel, filterCallback }" v-else>
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" :placeholder="`Search`" class="p-column-filter" />
                    </template> -->


                    <template #body="slotProps" v-if="col.field == 'action'">
                        <slot name="button-actions" v-bind:data="slotProps">{{ slotProps }}</slot>
                    </template>

                    <template #body="slotProps" v-else-if="col.type == 'date'">
                        {{ convertDate(slotProps.data.modified_date) }}
                    </template>

                    <template #body="slotProps" v-else-if="col.type == 'anchor'">
                        <router-link :to="convertToUrl(slotProps.data.id)">{{ slotProps.data.id }}</router-link>
                    </template>


                </Column>
                <template #empty> No records found.</template>
            </DataTable>
        </div>
    </layout>
</template>

<script>
import EventBus from '../event-bus';
import DateService from '../service/DateService';
import UserAuthenticationService from '../service/UserAuthenticationService';

export default {
    //Component parameters
    props: {
        tableColumnHeader: Array,
        tableFilters: Object,
        api: String,
        componentName: String,
    },
    watch: {
        filters() {
            this.onFilter();
        },
    },
    data() {
        return {
            dateService: null,
            rows: null,
            loading: false,
            totalRecords: 0,
            lazyLoadParams: {},
            lazyLoader: {},
            filters: this.tableFilters,
            userService: null
        };
    },
    mounted() {
        this.dateService = new DateService()
        this.userService = new UserAuthenticationService()
        this.loading = false;

        this.lazyLoadParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters,
        };

        this.loadLazyData();

        EventBus.on('reload-table', () => {
            this.loadLazyData();
        });

        EventBus.on('export-to-csv', () => {
            this.exportCSV();
        });

    },
    methods: {
        exportCSV() {
            if (this.$refs.dt !== null) {
                this.$refs.dt.exportCSV();
            } else {
                this.loadLazyData ();
            }
        },
        convertToUrl(id) {
            return "/ticket/" + id
        },
        convertDate(date) {
            return this.dateService.getCreatedDateFormat(date)
        },

        loadLazyData() {
            this.loading = true;

            // Prepare GET params for lazyloading request
            this.lazyLoader = {
                // 'total': this.totalRecords,
                pageLength: this.lazyLoadParams.rows,
                page: (this.lazyLoadParams.page ?? 0) + 1,
                filters: [],
                // 'filters': this.lazyLoadParams.filters,
            };
            if (this.lazyLoadParams.sortField !== undefined) {
                this.lazyLoader.sortBy = this.lazyLoadParams.sortField;
                this.lazyLoader.sortOrder = this.lazyLoadParams.sortOrder == 1 ? 'asc' : 'desc';
            }
            // console.log(this.lazyLoadParams.filters);
            if (this.lazyLoadParams.filters && Object.keys(this.lazyLoadParams.filters).length) {

                // this.lazyLoader.filters = this.lazyLoadParams.filters;
                this.lazyLoader.filters = [];
                Object.keys(this.lazyLoadParams.filters).forEach((key) => {
                    let this_filter = this.lazyLoadParams.filters[key];

                    if (this_filter.value != '') {
                        this.lazyLoader.filters[key] = this_filter.value;
                    }
                });

            }

            let requestParams = ""

            Object.keys(this.lazyLoader).forEach((key, value) => {
                if (key == "page") {
                    if (this.lazyLoadParams.originalEvent) {
                        requestParams += key + "=" + (value + this.lazyLoadParams.originalEvent.page) + "&pageLength=" + this.lazyLoadParams.originalEvent.rows
                    } else {
                        requestParams += key + "=" + value + "&"
                    }
                }
            })

            this.axios.get(this.api + "?" + requestParams, this.userService.getBearer())
                .then((d) => {
                    this.rows = d.data.results.data;
                    this.totalRecords = d.data.totalRecords;
                    this.loading = false;
                }).catch(function(error){
                    if (error.response.status == 403) {
                        document.getElementsByClassName('p-datatable-loading-overlay')[0].style.display = "none";
                        document.getElementsByClassName('p-datatable-emptymessage')[0].innerHTML =
                            "<td colspan='14' style='text-align: center; color: red'><strong>Forbidden Access</strong></td>";
                    }
                });
        },
        onPage(event) {
            this.lazyLoadParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyLoadParams = event;
            this.loadLazyData();
        },
        onStateRestore(event) {
            this.lazyLoadParams = event;
            this.loadLazyData();
        },
        filterCallback() {
            console.log('here in CALLBACK');
        },
        onFilter() {

            this.loading = true;
            this.lazyLoadParams.filters = this.filters;
            this.loadLazyData();
        },
        action(data, action) {
            EventBus.emit(action, data);
        },
    },
};
</script>