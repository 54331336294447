<template>
    <h5 v-if='is_from_login' class='mt-3 font-bold'>{{ heading }}</h5>
    <div v-if='!user_realm.secret_2fa'>
        <TwoFAQRCode
            @twoFAEvents='twoFAFormEvents'
            :is_from_login='is_from_login'
            :twofa_code_heading='t("two_fa_code_heading", "A code will be generated from the app that you need to input below.")'></TwoFAQRCode>
    </div>
    <div v-else>
        <TwoFAForm v-if='is_from_login'
            @twoFAEvents='twoFAFormEvents'
            :twofa_code_heading='t("two_fa_form_heading", "A code will be generated from the app that you need to input below.")'></TwoFAForm>
    </div>
</template>

<script>

import TwoFAForm from '@/components/two-factor-auth/TwoFAForm';
import TwoFAQRCode from '@/components/two-factor-auth/TwoFAQRCode';
import t from '@/service/Translate';

export default {
    components: { TwoFAQRCode, TwoFAForm },
    props: {
        heading: {
            type: String,
            default: '',
        },
        user_realm: {
            type: String,
            default: '',
        },
        is_from_login: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['twoFAStatus'],
    setup(props, { emit }) {

        const twoFAFormEvents = (response) => {
            emit('twoFAStatus', response);
        }

        return {
            t,
            twoFAFormEvents,
        };
    },
};
</script>
