import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';
import { getTimeLogEntryInCache, storeTimeLogEntryInCache, destroyTimeLogEntryInCache, updateTimeLogEntryInCache } from '@/service/TimeTrackingService';
import { fixedDateTimeFormat } from '@/service/DateService';

export const useAutoTimer = defineStore(
    'auto-timer',
    () => {
        /**
         * State
         */
        const route = useRoute();
        const timer = ref(0);
        const timerInterval = ref(null);

        // this will be used to know if the timer is running
        const isTimerStarted = ref(false);

        // this will hold the active ticket started in timer
        const globalTicket = ref(null);

        // get the start_date when the timer started
        const timerStartedAt = ref(null);

        // indicator weather the time stopped outside the form
        const timeStopOutside = ref(false);

        // indicator for the sidebar collapsed
        const isSideBarCollapsed = ref(false);

        /**
         * Getters
         */

        // Check if Ticket View Page is Active
        const checkIfTicketPageIsActive = computed(() => {
            return route.name != undefined && route.name == 'ticket.id.access' ? true : false;
        });

        // check weather timer is active and ticket is not null
        const checkTimerAndTicketIsActive = computed(() => {
            return isTimerStarted.value && globalTicket.value ? true : false;
        });

        // calculate hours
        const hours = computed(() => Math.floor((timer.value % (60 * 60 * 24)) / (60 * 60)));

        // calculate minutes
        const minutes = computed(() => Math.floor((timer.value % (60 * 60)) / 60));

        // calculate seconds
        const seconds = computed(() => Math.floor(timer.value % 60));

        // format date into display
        const getTimerFormatted = computed(() => {
            let dHours = hours.value < 10 ? `0${hours.value}` : hours.value;
            let dMinutes = minutes.value < 10 ? `0${minutes.value}` : minutes.value;
            let dSeconds = seconds.value < 10 ? `0${seconds.value}` : seconds.value;

            return `${dHours}:${dMinutes}:${dSeconds}`;
        });

        /**
         * Actions
         */

        // Set the global ticket
        const setGlobalTicketValueWhenLoaded = (ticketID) => {
            globalTicket.value = globalTicket.value && timer.value > 0 ? globalTicket.value : ticketID;
        };

        /**
         * start auto timer
         *
         * @param useClearFirst
         * @param bypassCondition
         */
        const autoStartTimer = (useClearFirst = false, bypassCondition = false) => {
            if (useClearFirst) {
                forceTimerToStop();
            }
            if ((globalTicket.value && isTimerStarted.value) || bypassCondition) {
                isTimerStarted.value = true;
                timerInterval.value = window.setInterval(() => {
                    if (!isTimerStarted.value) {
                        forceTimerToStop();
                        return;
                    }
                    timer.value++;
                }, 1000); // interval set to one sec.
            }

            timeStopOutside.value = false;
        };

        // function to store time log entry upon starting the timer
        const storeTimeLogEntry = async () => {
            try {
                if (timer.value == 0 && globalTicket.value && timerStartedAt.value) {
                    await storeTimeLogEntryInCache({
                        ticket_id: globalTicket.value,
                        start_time: fixedDateTimeFormat(timerStartedAt.value),
                    });
                } else {
                    await updateTimeLogEntry({
                        ticket_id: globalTicket.value,
                        is_stopped: false,
                        time_started: fixedDateTimeFormat(moment()),
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        // function to update time log entry
        const updateTimeLogEntry = async (payload) => {
            try {
                await updateTimeLogEntryInCache(payload);
            } catch (error) {
                console.log(error);
            }
        };

        // Start the Timer
        const startTimer = async () => {
            timerStartedAt.value = moment();
            await storeTimeLogEntry();

            autoStartTimer(false, true);
        };

        // Stop the Timer
        const stopTimer = async () => {
            isTimerStarted.value = false;
            forceTimerToStop();

            await updateTimeLogEntry({
                ticket_id: globalTicket.value,
                is_stopped: true,
                seconds_added: timer.value,
                time_stopped: fixedDateTimeFormat(moment()),
            });
        };

        // get the timer entry in cache for the user
        const getTimeEntryInCache = async () => {
            try {
                const response = await getTimeLogEntryInCache();
                if (response && response.total_seconds > 0) {
                    timer.value = response.total_seconds;
                    globalTicket.value = response.ticket_id;
                    timerStartedAt.value = fixedDateTimeFormat(response.start_time);
                    if (!response.is_stopped) {
                        // if not stopped, start the timer upon login
                        isTimerStarted.value = true;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        // reset state value when login
        const resetStateValues = async (destroyDataInCache = false) => {
            if (destroyDataInCache) {
                await destroyTimeLogEntryInCache();
            }

            forceTimerToStop();
            timer.value = 0;
            isTimerStarted.value = false;
            globalTicket.value = null;
            timerStartedAt.value = null;
            timeStopOutside.value = false;
            isSideBarCollapsed.value = false;
        };

        // force stop the timer
        const forceTimerToStop = () => {
            clearInterval(timerInterval.value);
        };

        return {
            /**
             * State
             */
            timer,
            isTimerStarted,
            globalTicket,
            timerStartedAt,
            timeStopOutside,
            isSideBarCollapsed,
            /**
             * Getters
             */
            getTimerFormatted,
            checkIfTicketPageIsActive,
            checkTimerAndTicketIsActive,
            hours,
            minutes,
            seconds,
            /**
             * Actions
             */
            setGlobalTicketValueWhenLoaded,
            autoStartTimer,
            startTimer,
            stopTimer,
            resetStateValues,
            getTimeEntryInCache,
        };
    },
    {
        persist: true,
        share: {
            // An array of fields that the plugin will ignore.
            omit: [
                'route',
                'timerStartedAt',
                'getTimerFormatted',
                'checkIfTicketPageIsActive',
                'checkTimerAndTicketIsActive',
                'hours',
                'seconds',
                'minutes',
                'setGlobalTicketValueWhenLoaded',
                'autoStartTimer',
                'startTimer',
                'stopTimer',
                'resetStateValues',
                'getTimeEntryInCache',
            ],
            // Override global config for this store.
            enable: true,
            initialize: true,
        },
    }
);
