export default [
    {
        path: '/event-template',
        name: 'Event',
        exact: true,
        component: () => import('@/pages/event_template/Index'),
        meta: {
            auth: true,
            permission: ['event.template.access'],
        },
    },
    {
        path: '/event-template/create',
        name: 'Event Template Create',
        exact: true,
        component: () => import('@/pages/event_template/Create'),
        meta: {
            auth: true,
            permission: ['event.template.create'],
        },
    },
    {
        path: '/event-template/:id/edit',
        name: 'Event Template Edit',
        exact: true,
        component: () => import('@/pages/event_template/Edit'),
        meta: {
            auth: true,
            permission: ['event.template.edit'],
        },
    },
    {
        path: '/event-template/:id/view',
        name: 'Event Template View',
        exact: true,
        component: () => import('@/pages/event_template/View'),
        meta: {
            auth: true,
            permission: ['event.template.view'],
        },
    },
];
