<template>
    <div>
        <div class="login-body">
            <div class="login-wrapper">
                <div class="login-panel">
                    <div class="forgot-password-form">
                        <h3>
                            <strong>{{ t('lbl_password_link_expired', 'Your reset password link has already expired') }}</strong>
                        </h3>
                        
                        <button class="p-button p-button-outlined mt-3 p-button-secondary" @click="$router.push('/')">
                            <span>{{ t('lbl_back_to_login', 'Back to log in') }}</span>
                        </button>
                    </div>
                </div>

                <login-image></login-image>
            </div>
        </div>
    </div>
</template>
<script>
import t from '@/service/Translate';
import LoginImage from '@/components/login/LoginImage.vue';

export default {
  components: { LoginImage },
    setup() {

        return {
            /**
             * Variables
             */
            t,
        };
    },
};
</script>
<style scoped>
.forgot-password-form {
    margin-top: 50%;
    text-align: left;
}
</style>
