import * as http from '@/core/apis';
import { destroy } from '@/core/apis';
import DataTableService from '@/service/DataTableService';
import UserAuthenticationService from '@/service/UserAuthenticationService';

/**
 *
 * @returns list of order types
 */

export async function getDefaultAccountCountry() {
    return await http.get('user/default-country');
}

export async function userRealm() {
    return await http.get(`user-realm`);
}

export async function clearBearerToken() {
    const dtService = new DataTableService();
    const userService = new UserAuthenticationService();

    await logoutAll();
    dtService.clearDTColumnsAndFilter();
    userService.removeItemInLocalStorage('nimbus-session');

    await userService.setBearer(null);
    await userService.setTwoFactorAuthentication(null);
}

/**
 * logout same account all devices
 * @returns {Promise<void>}
 */
export async function logoutAll() {
    await destroy('/logout/all');
}

/**
 * logout same account on other devices
 * @returns {Promise<void>}
 */
export async function logoutOther() {
    await destroy('/logout/others');
}

/**
 * logout account on current device
 * @returns {Promise<void>}
 */
export async function logoutCurrent() {
    await destroy('/logout');
}
