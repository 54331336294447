import * as http from '@/core/apis';
import { arrayBufferFromBase64, arrayBufferToBase64, decryptData, encryptData } from '@/utilities/crypto';
import { UserPortalConstants } from '@/service/Constants';

export async function getUserPortalSettings(host) {
    if (!host) {
        host = window.location.hostname;
    }

    return await http.get(`/user-portal-setting/${host}`);
}

export async function setCacheUserPortalSettings(data) {
    const { encryptedData, iv } = await encryptData(
        JSON.stringify(data),
        process.env.VUE_APP_ENCRYPTION_KEY, // note: create a separate file for constants
    );

    sessionStorage.setItem(UserPortalConstants.END_USER_PORTAL_SETTING_KEY, arrayBufferToBase64(encryptedData));
    sessionStorage.setItem(UserPortalConstants.END_USER_PORTAL_SETTING_CRYPT_IV, arrayBufferToBase64(iv));
}

export async function getCacheUserPortalSettings() {
    const
        encryptedData = arrayBufferFromBase64(sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_KEY)),
        iv = arrayBufferFromBase64(sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_SETTING_CRYPT_IV));

    const decryptedData = await decryptData(encryptedData, iv, process.env.VUE_APP_ENCRYPTION_KEY);
    return JSON.parse(decryptedData);
}

export function unsetCacheUserPortalSettings() {
    sessionStorage.removeItem(UserPortalConstants.END_USER_PORTAL_SETTING_KEY);
    sessionStorage.removeItem(UserPortalConstants.END_USER_PORTAL_SETTING_CRYPT_IV);
    sessionStorage.removeItem(UserPortalConstants.END_USER_PORTAL_KEY);
}

export function setEndUserPortalHost(host) {
    sessionStorage.setItem(UserPortalConstants.END_USER_PORTAL_KEY, host);
}

export function getEndUserPortalHost() {
    return sessionStorage.getItem(UserPortalConstants.END_USER_PORTAL_KEY);
}