import * as api from '@/core/apis';
import * as http from '@/core/apis';
/**
 *
 * @returns list of currency types
 */
export async function fetchCurrencyTypes() {
    return await api.get('currency');
}

export async function getCurrencyByCountry(countryId) {
    return api.get(`currency/country/` + countryId);
}

export async function getCurrencyByServiceProvider() {
    try {
        const response = await http.get('/account/default-currency');
        const currency = JSON.stringify(response.data);
        setCurrencySetting(currency);
    } catch (error) {

    }
}

export function setCurrencySetting(currency) {
    localStorage.setItem('nimbus-currency', currency);
}

export function getCurrencySetting() {
    let currency = null;
    let lsCurrency = localStorage.getItem('nimbus-currency');
    if (lsCurrency !== undefined) {
        currency = JSON.parse(lsCurrency);
    }
    return currency;
}

export function removeCurrency() {
    localStorage.removeItem('nimbus-currency');
}
