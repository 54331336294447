import { useUserStore } from '@/stores/user-store';

const page = 'invoice';

export default [
    {
        path: '/invoice',
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/account/account-list/AccountMain'),
        meta: {
            auth: true,
            permission: ['invoices.access', 'portal.invoices.access'],
            title: 'Invoices',
            prefix: `${page}-main`,
            redirect: `${page}-list-account`,
        },
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            if (userStore.isEup) {
                next({ name: `${page}-list-account`, params: { accountId: userStore.userData.accountId } });
            } else {
                next();
            }
        },
    },
    {
        path: '/invoice-account/:accountId/',
        name: `${page}-account`,
        exact: true,
        component: () => import('@/pages/invoice/Main.vue'),
        meta: {
            auth: true,
            permission: ['invoices.access', 'portal.invoices.access'],
            title: 'Invoices',
            prefix: `${page}-account`,
        },
        children: [
            {
                path: 'invoice-list',
                name: `${page}-list-account`,
                exact: true,
                component: () => import('@/pages/invoice/InvoiceMain.vue'),
                meta: {
                    auth: true,
                    permission: ['invoices.access', 'portal.invoices.access'],
                    title: 'Invoices',
                    prefix: `${page}-account`,
                },
            },
            {
                path: 'transactions',
                name: `${page}-transaction-account`,
                exact: true,
                component: () => import('@/pages/transactions/Main'),
                meta: {
                    auth: true,
                    permission: ['transaction.access', 'portal.transaction.access'],
                    title: 'Transactions',
                    prefix: `${page}-transaction-account`,
                },
            },
        ]
    },

];
