import axios from 'axios';

export default class HandOverToken {

    checkHandOverParams(handover_token) { 
        return axios.get("api/v1/oauth/handover?handover_token=" + handover_token)
            .then(res => res)
            .catch(error => error);
    }

    requestHandOver(bearer) {
        return axios.get("api/v1/handover/token" , bearer)
            .then(res => res)
            .catch(error => error);
    }

}
