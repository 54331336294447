import * as http from '@/core/apis';
/**
 * API's for 2FA
 */
export async function disable2FA(params) {
    return await http.destroy(`user/mfa/two-factor-authentication`, params);
}

export async function enable2FA(params) {
    return await http.post(`user/mfa/two-factor-authentication`, params);
}

export async function skip2FAEnrollment() {
    return await http.post(`user/mfa/skip-two-factor-authentication`);
}

export async function twoFactorQRCode() {
    return await http.get(`user/mfa/two-factor-qr-code`);
}

export async function twoFactorRecoveryCodes() {
    return await http.get(`user/mfa/two-factor-recovery-codes`);
}

export async function verify2FACode(data) {
    return await http.post(`user/mfa/confirmed-two-factor-authentication`, data);
}

export async function verifyEmailAuthenticationCode(data) {
    return await http.post(`/user/email_authentication/verify`, data);
}

export async function SendEmailAuthenticationCode() {
    return await http.post(`/user/email_authentication/send`);
}

export async function verifyUserAuthenticationCodeByUrl(url,data) {
    return await http.post(url, data);
}

export async function sendUserAuthenticationCodeByUrl(url) {
    return await http.post(url);
}

export async function checkValidityOfTokenRequest(tokenRequestId) {
    return await http.get('/otp-check-validity/' + tokenRequestId);
}