<template>
    <div v-if="warningDialog">
        <Dialog
            :visible="true"
            :modal="true"
            :closable="false"
            :close-on-escape="false"
            @hide="autoLogoutStore.resetTimer(true)">
            <div class="confirmation-content">
                <h1 class="text-center text-6xl">{{ getWarningTimerFormatted }}</h1>
                <h2 class="text-lg text-orange-600">
                    <i class="pi pi-exclamation-triangle text-2xl pr-2"/>
                    <span class="text-2xl">{{ t('lbl_logged_out', "You're about to be logged out.") }}</span>
                </h2>
                <p v-html="t('msg_logged_out_dialog', 'You have been idle for some time and to keep your account secure,<br> we will log you out.')"></p>
            </div>
            <template #footer>
                <Button :label="t('btn_logout', 'Log Out')"
                        class="p-button p-button-outlined p-button-secondary"
                        @click="logout"/>
                <Button :label="t('btn_stay_signed_in', 'Stay Signed In')"
                        class="p-button"
                        @click="staySignedIn"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import {onMounted, ref, computed, watch, onBeforeUnmount} from "vue";
import {useRouter} from "vue-router";
import t from "@/service/Translate";
import {calculateHours, calculateMinutes, calculateSeconds, formattedTimer} from "@/service/DateService";
import UserAuthenticationService from "@/service/UserAuthenticationService";
import {useAutoLogout} from "@/stores/auto-logout";
import ls from "@/stores/local-storage"

export default {
    name: "NimbusAutoLogout",
    setup() {
        const autoLogoutStore = useAutoLogout();
        const router = useRouter()
        const userService = new UserAuthenticationService();
        const events = [
            'mousemove',
            'mousedown',
            'scroll',
            'keypress',
            'load'
        ];

        const warningDialog = ref(false);
        const warningTimerStarted = ref(false)
        const warningTimer = ref(autoLogoutStore.warningTimerInterval)
        const warningInterval = ref(null);

        const warningHours = computed(() => calculateHours(warningTimer.value));
        const warningMinutes = computed(() => calculateMinutes(warningTimer.value));
        const warningSeconds = computed(() => calculateSeconds(warningTimer.value));
        const getWarningTimerFormatted = computed(() => formattedTimer(warningHours.value, warningMinutes.value, warningSeconds.value));

        // registered added events
        const registeredEvents = () => {
            events.forEach((event) => {
                document.addEventListener.call(window, event, () => {
                    let timeWarned = ls.get('al-is-timer-warned');
                    if (!timeWarned) {
                        autoLogoutStore.resetStoreValues();
                    }
                })
            }, autoLogoutStore)
        }

        // button to stay signed in
        const staySignedIn = () => {
            autoLogoutStore.staySignedIn();
            resetWarningData();
            autoLogoutStore.autoStartTimer();
            autoLogoutStore.setActiveTimer()
        }

        // button to logout
        const logout = async () => {
            await autoLogoutStore.logoutActiveUser();
            autoLogoutStore.clearActiveInterval()

            resetWarningData();
            ls.set('al-auto-logout', false);
        }

        const startWarningTimer = () => {
            if (!warningTimerStarted.value) {
                warningTimerStarted.value = true;
                warningInterval.value = window.setInterval(() => {
                    if (userService.isAuthenticated()) {
                        if (!warningTimerStarted.value) return;
                        // check for time warned interval
                        if (warningTimer.value <= 0) {
                            resetWarningData();

                            // set the idle warning and it will show to login page
                            autoLogoutStore.showIdleWarningInLogin = true;
                            autoLogoutStore.logoutActiveUser();

                            ls.set('al-auto-logout', true);
                            return false;
                        }
                        warningTimer.value--;
                        autoLogoutStore.warningTimerInterval = warningTimer.value
                    } else {
                        resetWarningData();
                        autoLogoutStore.removeLSInAutoLogout();
                        return false;
                    }
                }, 1000); // interval set to one sec.
            }
        }

        onMounted(() => {
            if (userService.isAuthenticated()) {
                warningDialog.value = ls.get('al-is-timer-warned') ?? false;

                // event to trigger when stay logged in button has been click
                ls.on('al-stay-logged-in', () => {
                    autoLogoutStore.resetStoreValues();
                    resetWarningData();
                })

                // event to trigger when warning time has been reached
                ls.on('al-is-timer-warned', (value) => {
                    if (userService.isAuthenticated()) {
                        warningDialog.value = value;
                        if (value) startWarningTimer();
                    }
                })

                // event to trigger when auto logout has been clicked idle/click event
                ls.on('al-auto-logout', (value) => {
                    resetWarningData();

                    if (value) {
                        autoLogoutStore.showIdleWarningInLogin = true;
                    }

                    if (userService.isAuthenticated()) {
                        autoLogoutStore.removeLSInAutoLogout();
                        autoLogoutStore.logoutActiveUser();
                    }

                    // force the user to redirect in login page
                    router.push('/login');
                })

                // register the events listener
                registeredEvents();
                // start the timer when the component mounted
                autoLogoutStore.autoStartTimer();
                // start the active timer
                autoLogoutStore.setActiveTimer()
            }
        })

        // function to reset data in warning
        const resetWarningData = () => {
            warningTimerStarted.value = false;
            warningDialog.value = false;
            autoLogoutStore.warningTimerInterval = autoLogoutStore.warningTimer
            warningTimer.value = autoLogoutStore.warningTimer

            clearInterval(warningInterval.value)
        }

        // watch isTimeWarned value to trigger the dialog
        watch(() => warningDialog.value, (value) => {
            if (userService.isAuthenticated()) {
                if (value) {
                    warningTimer.value = autoLogoutStore.warningTimerInterval;
                    startWarningTimer();
                } else {
                    resetWarningData();
                }
            }
        })

        // watch isTimeWarned value to trigger the dialog
        watch(() => autoLogoutStore.isTimeWarned, (value) => {
            if (userService.isAuthenticated()) {
                warningDialog.value = value;
                if (!value) {
                    resetWarningData();
                }
                startWarningTimer();
            }
        })

        onBeforeUnmount(() => {
            if (!userService.isAuthenticated()) {
                autoLogoutStore.resetStoreValues();
                autoLogoutStore.resetActiveTimerValues()
            }
        })

        return {
            /**
             * Constants
             */
            t,
            autoLogoutStore,
            warningDialog,
            getWarningTimerFormatted,
            /**
             * functions
             */
            staySignedIn,
            logout
        }
    }
}
</script>

<style scoped>

</style>
