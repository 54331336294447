<template>
    <div>
        <Toast/>
       
        <NimbusDialog v-model="spinnerDIV">
            <div class="text-center block">
                <ProgressSpinner />
                <div class="text-center block">Please wait while checking the link</div>
            </div>
        </NimbusDialog>

        <NimbusDialog v-model="showDialog">
            <OTPBlackBox
                @submitVerificationCodeEventCallbackEmail='submitVerificationCodeEventCallback'
                :showEmailOtp='true'
                :showSmsOtp='false'
                :emailOtpProps='emailOtpProps'
                smsOtpProps=''
                :automaticSendToken="false"
                :hideResendTimer="true"
            ></OTPBlackBox>
        </NimbusDialog>

        <NimbusDialog v-model="isSuccessful" icon="pi-thumbs-up" icon-severity="success">
            <h5>Account changes</h5>
            <p>You have successfully verified your OTP.</p>
        </NimbusDialog>

        <NimbusDialog v-model="isExpired" icon="pi-thumbs-down" icon-severity="error">
            <h5>Account changes</h5>
            <p>The link you are using is already expired</p>
        </NimbusDialog>
    </div>
</template>
<script setup>
import OTPBlackBox from '@/components/otp/OTPBlackBox';
import NimbusDialog from '@/components/dialog/NimbusDialog.vue';
import ProgressSpinner from 'primevue/progressspinner';
import { OTPValidationStatus } from '@/service/Constants'
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { checkValidityOfTokenRequest } from '@/service/TwoFactorAuthService'

const showDialog = ref(false);
const route = useRoute();
const tokenRequestId = route.params.tokenRequestId;
const toast = useToast();
const isSuccessful = ref(false);
const isExpired = ref(false);
const spinnerDIV = ref(true);
 
const emailOtpProps = ref({
    heading: 'Email Authentication',
    subheading: 'Enter the one time passcode we sent you to make changes to your account',
    validateTokenApi: '/validate-otp-change-payment-method/' + tokenRequestId,
    requestTokenApi: '',
    resendTimer: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER,
    resendTimerInMinutes: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES,
});

onMounted(() => {
    checkValidityOfTokenRequest(tokenRequestId)
        .then(response => {
            spinnerDIV.value = false;

            if (response.data == OTPValidationStatus.FAILED) {
                isExpired.value = true;
            } else {
                showDialog.value = true;
            }
        })
})

const submitVerificationCodeEventCallback = (event, data) => {    
    if (data.data.otp_validation_status_id == OTPValidationStatus.SUCCESS) {
        isSuccessful.value = true;
        showDialog.value = false;
    } else if (data.data.otp_validation_status_id == OTPValidationStatus.FAILED) {
        toast.add({
            severity: 'error',
            summary: 'Verification code is incorrect',
            detail: '',
            life: 3000,
        });
    } else {
        isExpired.value = true;
        showDialog.value = false;
    }

}
</script>
