<template>
    <div id="time-log-wrapper" v-if="isTicketLoaded">
        <div class="timer-trigger-border fixed left-0 bottom-0">
            <div class="timer-collapsed">
                <div class="icon-wrapper flex flex-row justify-content-end">
                    <i v-if="store.isSideBarCollapsed || isTimerHasCount" @click="openTimeTrackingUsingIcon"
                       class="pi pi-angle-up border-round-top-md text-lg"></i>
                </div>
            </div>
            <div class="timer-full-view">
                <div class="timer-wrapper flex flex-row align-items-center"
                     :class="[isTimerHasCount ? 'justify-content-between': 'justify-content-end']">
                    <div class="pl-2" v-if="isTimerHasCount">
                        <label class="block relative"
                               style="top:3px;font-size: 0.65rem !important;">{{ t('lbl_time_elapsed', 'TIME ELAPSED')
                            }}</label>
                        <label>
                            <span class="text-lg font-bold">{{ getTimerFormatted }}</span>
                        </label>
                    </div>
                    <div>
                        <div class="start-timer" v-if="!isTimerHasCount">
                            <Button type="button"
                                    class="p-button p-component p-button-sm p-button-rounded bg-bluegray-600 border-transparent mr-2"
                                    :label="t('lbl_start_timer', 'Start Timer')"
                                    @click="openTimeTracking"/>
                        </div>
                        <div class="start-timer" v-else-if="!store.isTimerStarted">
                            <Button type="button"
                                    class="p-button p-component p-button-sm p-button-outlined p-button-secondary p-button-rounded border-2 font-bold mr-2"
                                    @click="startTimerAndShowTheForm">
                                <span>{{ t('lbl_start_timer', 'Start Timer') }}</span>
                                <img src="assets/source-icons/play.svg" class="custom-icon"/>
                            </Button>
                        </div>
                        <div class="stop-timer" v-if="store.isTimerStarted">
                            <Button type="button"
                                    class="p-button p-component p-button-sm p-button-outlined p-button-secondary p-button-rounded border-2 font-bold mr-2"
                                    @click="stopTimerAndShowTheForm"
                            >
                                <span>{{ t('lbl_stop_timer', 'Stop Timer') }}</span>
                                <img src="assets/source-icons/stop.svg" class="custom-icon"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="timer-mobile-view">
                <div class="timer-wrapper flex flex-row align-items-center justify-content-center">
                    <div class="start-timer" v-if="!isTimerHasCount">
                        <Button type="button"
                                class="p-button p-component p-button-sm p-button-rounded border-transparent w-0 custom-button-icon"
                                @click="openTimeTracking">
                            <img src="assets/source-icons/mobile-timer-with-bg.svg" class="custom-icon"/>
                        </Button>
                    </div>
                    <div class="start-timer" v-else-if="!store.isTimerStarted">
                        <Button type="button"
                                class="p-button p-component p-button-sm p-button-outlined p-button-secondary border-transparent w-0 custom-button-icon"
                                @click="startTimerAndShowTheForm">
                            <img src="assets/source-icons/mobile-timer-play-with-border.svg" class="custom-icon"/>
                        </Button>
                    </div>
                    <div class="stop-timer" v-if="store.isTimerStarted">
                        <Button type="button"
                                class="p-button p-component p-button-sm p-button-outlined p-button-secondary border-transparent w-0 custom-button-icon"
                                @click="stopTimerAndShowTheForm"
                        >
                            <img src="assets/source-icons/mobile-timer-stop-with-border.svg" class="custom-icon"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <Sidebar class="time-log-form border-round-top-md"
                 v-model:visible="showTimeLogForm"
                 :baseZIndex="9999"
                 :dismissable="false"
                 position="bottom"
                 :show-close-icon="false"
                 :style="[`max-width:${sideBarMaxWidth}rem !important`]"
                 id="time-tracking-sidebar">
            <div class="time-log-content mt-3">
                <TabView @tab-click="tabClickEvent" v-model:active-index="activeTabIndex">
                    <TabPanel v-can="'ticket.time-log.create.own'" class="mr-3" :header="t('lbl_timer', 'Timer')">
                        <auto-timer-form
                            :ticketID="activeTicketID"
                            :billable-types="billableTypes"
                            :item-labours="itemsLabours"
                            @time-log-added="timeLogAdded"
                            @close-time-tracking-form="closeTimeTrackingForm"
                            ref="autoTimerForm"
                            :ticketDetails="ticketDetails"
                        >

                        </auto-timer-form>
                    </TabPanel>
                    <TabPanel v-if="store.timer == 0" :header="t('lbl_manual', 'Manual')">
                        <manual-form
                            v-if="manualFormComponent"
                            :ticketID="activeTicketID"
                            :accounts="accountsListing"
                            :billable-types="billableTypes"
                            :item-labours="itemsLabours"
                            @time-log-added="timeLogAdded"
                            @close-time-tracking-form="closeTimeTrackingForm"
                            ref="manualForm"
                            :ticketDetails="ticketDetails"
                        >
                        </manual-form>
                    </TabPanel>
                </TabView>
            </div>
        </Sidebar>
    </div>

    <Dialog v-model:visible="tabWarningDialog" :style="{ width: '19%' }"
            :modal="true"
            class="p-fluid">
        <template #header>
            <h5 class="dialog-header b text-orange-600">
                <i class="pi pi-exclamation-triangle relative text-xl" style="top:3px"></i>
                <label class="ml-2 text-xl">{{ t('lbl_warning_dialog', 'Warning') }}</label>
            </h5>
        </template>
        <div>
            <p class="text-lg">Information you have entered in Manual Time Entry will be lost.</p>
            <p class="text-lg">Are you sure you want to continue?</p>
        </div>
        <template #footer>
            <Button class="p-button p-button-outlined p-button-secondary p-component" :label="t('btn_no','No')"
                    @click="tabWarningDialog = !tabWarningDialog"/>
            <Button class="p-mr-2" :label="t('btn_yes','Yes')"
                    @click="cancelManualTimeEntryForm"/>
        </template>
    </Dialog>
</template>

<script>
import {ref, onMounted, computed} from "vue";
import {useRoute} from "vue-router";
import t from "@/service/Translate"
import {can} from "@/directives/permissions";
import {fetchAccounts, fetchBillableTypes} from "@/service/TimeTrackingService";
import {useAutoTimer} from "@/stores/auto-timer";
import AutoTimerForm from "@/components/time-tracking/AutoTimerForm";
import ManualForm from "@/components/time-tracking/ManualForm";
import EventBus from "@/event-bus";
import {fetchItemsViaKey} from "@/service/ItemService";

export default {
    name: "TimeTracking",
    components: {
        AutoTimerForm,
        ManualForm
    },
    setup() {
        const route = useRoute()
        const store = useAutoTimer();
        const activeTicketID = ref(null);
        const showTimeLogForm = ref(false);
        const activeTabIndex = ref(0);
        const accountsListing = ref([]);
        const billableTypes = ref([]);
        const itemsLabours = ref([]);
        const isTimerHasCount = computed(() => store.timer ?? false);

        const autoTimerWidth = ref(35); // fixed width for Auto Timer Form Sidebar, unit is on rem
        const manualTimerWidth = ref(72) // fixed width for Manual Timer Form Sidebar, unit is on rem
        const sideBarMaxWidth = ref(35); // default width when opening the time tracking component, unit is on rem
        const tabWarningDialog = ref(false);
        const autoTimerForm = ref(null);
        const manualForm = ref(null);
        const manualFormComponent = ref(true);
        const ticketDetails = ref(null);
        const isTicketLoaded = ref(false);

        /**
         * Buttons Actions
         */
        const buttonTriggers = ref({
            start: {
                show: true
            },
            stop: {
                show: false
            },
            resume: {
                show: false
            }
        })

        /**
         * Open time tracking form
         */
        const openTimeTracking = () => {
            activeTicketID.value = route.params.id
            showTimeLogForm.value = true;
            activeTabIndex.value = 0;
            sideBarMaxWidth.value = autoTimerWidth.value;
        }

        /**
         * Open time tracking form via collapsed icon
         */
        const openTimeTrackingUsingIcon = () => {
            showTimeLogForm.value = true;
            store.isSideBarCollapsed = false;
        }

        /**
         * Start the timer and open the form
         */
        const startTimerAndShowTheForm = () => {
            store.startTimer()
            showTimeLogForm.value = true;
        }

        /**
         * Stop the timer and open the form
         */
        const stopTimerAndShowTheForm = () => {
            store.stopTimer();
            store.timeStopOutside = true
            showTimeLogForm.value = true;
        }

        const timeLogAdded = async (event) => {
            showTimeLogForm.value = false;

            await EventBus.emit('time-log-entry-added', event)

            // turn off the event once emitted
            EventBus.off('time-log-entry-added', false);
        }

        const closeTimeTrackingForm = () => {
            showTimeLogForm.value = false;
        }

        /**
         * Fetch list of account when the component is mounted
         * @returns {Promise<void>}
         */
        const getListOfAccounts = async () => {
            try {
                const response = await fetchAccounts()
                accountsListing.value = response.data;
            } catch (error) {
                console.log(error)
            }
        }

        /**
         * Fetch list of billable types
         *
         * @returns {Promise<void>}
         */
        const getListofBillableTypes = async () => {
            try {
                const response = await fetchBillableTypes()
                billableTypes.value = response.data;
            } catch (error) {
                console.log(error)
            }
        }

        /**
         * Fetch list of labour items types
         *
         * @returns {Promise<void>}
         */
        const getListofItemLabours = async () => {
            try {
                const response = await fetchItemsViaKey('product_labour');
                itemsLabours.value = response.data;

            } catch (error) {
                console.log(error)
            }
        }

        /**
         * Tab Event
         *
         * @param event
         */
        const tabClickEvent = (event) => {
            if (event.index === 0) {
                // check if there's an existing progress in manual form
                if (manualForm.value.requestFormModal[0].hours
                    || manualForm.value.requestFormModal[0].minutes) {
                    setTimeout(() => {
                        activeTabIndex.value = 1;
                        tabWarningDialog.value = true;
                    }, 1)
                    return;
                }
                sideBarMaxWidth.value = autoTimerWidth.value;
            } else {
                sideBarMaxWidth.value = manualTimerWidth.value;
            }
        }

        /**
         * Cancel Manual Entry Form
         */
        const cancelManualTimeEntryForm = () => {
            reloadManualFormComponent();

            tabWarningDialog.value = false;
            activeTabIndex.value = 0;
            sideBarMaxWidth.value = autoTimerWidth.value;
        }

        // reload manual form data table
        const reloadManualFormComponent = () => {
            manualFormComponent.value = false;
            setTimeout(() => {
                manualFormComponent.value = true;
            }, 100);
        }

        onMounted(async () => {
            // only load the users list if the logged user has the permission to add log time for others
            if (can('ticket.time-log.create.others')) {
                await getListOfAccounts();
            }
            await getListofBillableTypes();
            await getListofItemLabours();

            ticketDetails.value = JSON.parse(localStorage.getItem("ticket-details"));

            if (ticketDetails.value != null) {
                isTicketLoaded.value = true;
            }
        })

        return {
            /**
             * Constants
             */
            t,
            store,
            activeTicketID,
            showTimeLogForm,
            isTimerHasCount,
            accountsListing,
            billableTypes,
            itemsLabours,
            buttonTriggers,
            sideBarMaxWidth,
            activeTabIndex,
            autoTimerForm,
            manualForm,
            tabWarningDialog,
            manualFormComponent,
            ticketDetails,
            isTicketLoaded,
            /**
             * Functions
             */
            tabClickEvent,
            openTimeTracking,
            openTimeTrackingUsingIcon,
            closeTimeTrackingForm,
            timeLogAdded,
            startTimerAndShowTheForm,
            stopTimerAndShowTheForm,
            cancelManualTimeEntryForm,
            /**
             * Getters
             */
            getTimerFormatted: computed(() => store.getTimerFormatted),
        }
    }
}
</script>
<style scoped>
#time-log-wrapper .custom-icon {
    padding-left: 5px;
    width: 20px;
}
</style>
<style>
.timer-trigger-border {
    z-index: 99909;
    width: 16rem;
    height: 5.9rem !important;
}

.timer-trigger-border .timer-collapsed {
    height: 1.9rem !important;
}

.timer-trigger-border .timer-collapsed i {
    background-color: #DADEE3;
    padding: 4px;
    cursor: pointer;
    position: relative;
    top: 0.3rem;
    right: 0.3rem;
}

.timer-trigger-border .timer-wrapper {
    background-color: #DADEE3;
    height: 4.2rem !important;
}

/**
Custom size for the Sidebar Button
 */
.time-log-form {
    left: 16rem !important;
    height: 62rem !important;
}

/**
Custom Style for the Icon X
 */
.time-log-form .p-sidebar-header {
    position: absolute !important;
    z-index: 10001 !important;
    right: 0 !important;
}

/**
Custom Style for Tab View Panels Title
 */
.time-log-content .p-tabview-nav > li:first-child {
    margin-right: 1rem !important;
}

/**
Custom Style for Tab View Panels to remove the padding
 */
.time-log-content .p-tabview-panels {
    padding-left: 0 !important;
}

/**
Custom style for dropdown panel options
 */
.time-log-content .p-dropdown-panel {
    max-width: 380px !important;
}

.timer-full-view {
    display: block;
}

.timer-mobile-view {
    display: none;
}


.p-sidebar-mask:has(#time-tracking-sidebar) {
   justify-content: normal !important;
}

/**
Responsive CSS
 */
@media only screen and (max-width: 992px) {
    .time-log-form {
        left: 5rem !important;
    }

    .timer-trigger-border {
        width: 5rem;
    }

    .timer-full-view {
        display: none !important;
    }

    .timer-mobile-view {
        display: block !important;
    }

    .custom-button-icon {
        background-color: transparent !important;
    }

    #time-log-wrapper .custom-icon {
        width: 40px !important;
    }
}
</style>
