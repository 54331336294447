import request from './init';
import requestOauth from './init_oauth';
/**
 * Request for GET
 *
 * @param url
 * @param data
 * @returns {*}
 */
export function get(url, data = {}) {
    return request({
        url: url,
        method: 'GET',
        data,
    });
}

/**
 * Request for POST
 *
 * @param url
 * @param data
 * @param headers
 * @returns {*}
 */
export function post(url, data = {}, headers = {}) {
    return request({
        url: url,
        method: 'POST',
        data,
        headers,
    });
}

/**
 * Request for PATCH
 *
 * @param url
 * @param data
 * @param headers
 * @returns {*}
 */
export function update(url, data = {}, headers = {}) {
    return request({
        url: url,
        method: 'PATCH',
        data,
        headers,
    });
}

/**
 * Request for HTTP:PUT
 *
 * @param url
 * @param data
 * @param headers
 * @returns {*}
 */
export function put(url, data = {}, headers = {}) {
    return request({
        url: url,
        method: 'PUT',
        data,
        headers,
    });
}

/**
 * Request for DELETE
 *
 * @param url
 * @param data
 * @returns {*}
 */
export function destroy(url, data = {}) {
    return request({
        url: url,
        method: 'DELETE',
        data,
    });
}

/**
 * Note: Add "Oauth" suffix for oauth requests
 */

/**
 * Request for POST Oauth
 *
 * @param url
 * @param data
 * @param headers
 * @returns {*}
 */

export function postOauth(url, data = {}, headers = {}) {
    return requestOauth({
        url: url,
        method: 'POST',
        data,
        headers,
    });
}
