import { get } from '@/core/apis';

export default class LanguageService {
    /**
     * Set selected-language in localstorage
     *
     * @param lang
     */
    setSelectedLanguage(lang) {
        localStorage.setItem('nimbus-selected-language', lang);
        localStorage.setItem('nimbus-translation-expiry', this.setTranslationExpirationDate())
    }

    /**
     * set translation expiration date
     * 2.628e+6 is equivalent to 1month
     *
     * @returns {integer}
     */
    setTranslationExpirationDate() {
        return Date.now() + (86400 * 1000);
    }

    /**
     * get value of nimbus-translation-expiry in local storage
     *
     * @returns {array}
     */
    getTranslationExpirationDate() {
        const expirationDate = localStorage.getItem('nimbus-translation-expiry') != null
            ? localStorage.getItem('nimbus-translation-expiry')
            : Date.now() - (160 * 1000);
        return expirationDate;
    }

    /**
     * get value of visited-url in local storage
     *
     * @returns {string}
     */
    getSelectedLanguage() {
        const lang = localStorage.getItem('nimbus-selected-language') != null
            ? localStorage.getItem('nimbus-selected-language')
            : 'en-gb';
        return lang;
    }

    /**
     * Set translations in localstorage
     *
     * @param translations
     */
    setTranslation(translations) {
        localStorage.setItem('nimbus-translation', translations);
        localStorage.setItem('nimbus-translation-expiry', this.setTranslationExpirationDate())
    }

    /**
     * get value of translation in local storage
     *
     * @returns {string}
     */
    getTranslation() {
        const lang = localStorage.getItem('nimbus-translation') != null
            ? localStorage.getItem('nimbus-translation')
            : '{}';
        return lang;
    }

    /**
     * an api call to get the translation,
     *
     */
    async callApiTranslation() {
        try {
            const response = await get('translation')
            const translations = JSON.stringify(response)

            this.setTranslation(translations)
        } catch (error) {
            console.error(error)
        }
    }
}
