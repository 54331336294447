export default [
    {
        path: '/reports',
        name: 'reports.access',
        exact: true,
        component: () => import('@/pages/reports/Main.vue'),
        meta: {
            auth: true,
            permission: ['reports.access','portal.reports.access'],
        },
    },
    {
        path: '/reports/download',
        name: 'reports.download',
        exact: true,
        component: () => import('@/pages/reports/Main.vue'),
        meta: {
            auth: true,
            permission: ['reports.access','portal.reports.access'],
        },
    },
];
