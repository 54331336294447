import Storage from 'vue-ls';
import {Prefixes} from "@/service/Constants";

const options = {
    namespace: Prefixes.nimbusLocalStorage,
    name: 'ls',
    storage: 'local', // storage name session, local, memory
};

const {ls} = Storage.useStorage(options)

export default ls
