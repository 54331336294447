<template>
    <div class="exception-body error">
        <div class="exception-content">
            <img src="assets/layout/images/emersion-logo-white.png" alt="Nimbus Logo" style='width:200px;'/>
            <div class="exception-title">{{ $t('lbl_error', 'Error') }}</div>
            <div class="exception-detail"><h1>{{ $t('lbl_error_401', '401 - Unauthorized') }}</h1></div>
            <div class="exception-detail"><h5>{{ $t('lbl_authentication_failed', 'Cannot access Nimbus because you are not logged in') }}</h5></div>
            <router-link to="/login">{{ $t('btn_login', 'Login') }}</router-link>
        </div>
    </div>
</template>

<script>
export default {};
</script>