import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOtpStore = defineStore(
    'otp-store',
    () => {
        /**
         * State
         */
        const otpTokenRequest = ref({});
        const otpValidationResponse = ref({});

        /** Set latest token request */
        const setOtpValidationResponse = (data) => {
            otpValidationResponse.value = data;
        }

        const setOtpTokenRequest = (data) => {
            otpTokenRequest.value = data;
        }


        return {
            /**
             * State
             */
            otpTokenRequest,
            otpValidationResponse,
            /**
             * Actions
             */
            setOtpValidationResponse,
            setOtpTokenRequest
        };
    },
    {
        persist: true,
    }
);
