<template>
    <img src='assets/layout/images/icons/email-authentication-icon.svg' title='Account Locked' />
    <h5 class='mt-3 font-bold'>{{ heading }}</h5>
    <p class='mb-3'>{{ subheading }}</p>
    <OTPForm
        :subheading='t("two_fa_form_heading", "Enter the one time passcode we sent you to log in to your account.")'
        :validateTokenApi='validateTokenApi'
        :resendTimer='resendTimer'
        :resendTimerInMinutes='resendTimerInMinutes'
        :otpTokenRequestId='otpTokenRequestId'
        @submitVerificationCodeEvent='submitVerificationCodeEvent'
        @sendEmailAuthenticationCodeApi='sendEmailAuthenticationCodeApi'
        :hideVerifyCodeButton="hideVerifyCodeButton"
        :hideInputBox="hideInputBox"
        :hideResendTimer="hideResendTimer"
        :automaticSendToken="automaticSendToken"
    ></OTPForm>
</template>

<script>

import OTPForm from '@/components/otp/OTPForm.vue';
import t from '@/service/Translate';
import { sendUserAuthenticationCodeByUrl } from '@/service/TwoFactorAuthService';
import { ref, watch, onMounted } from 'vue';
import { useUserStore } from '@/stores/user-store';
import { useOtpStore } from '@/stores/otp-store';

export default {
    components: { OTPForm },
    props: {
        emailOtpProps: {
            type: Object,
        },
        heading: {
            type: String,
            default: t('email_authentication_title', 'Email Authentication'),
        },
        subheading: {
            type: String,
            default: 'Enter one time passcode we sent you to log in to your account.',
        },
        showEmailOtp: {
            default: false,
        },
        requestTokenApi: {
            type: String,
            required: true,
        },
        validateTokenApi: {
            type: String,
            required: true,
        },
        resendTimer: {
            type: String,
        },
        resendTimerInMinutes: {
            type: String,
        },
        hideVerifyCodeButton : {
            type: Boolean,
            default : false
        },
        hideInputBox : {
            type: Boolean,
            default : false
        },
        hideResendTimer : {
            type: Boolean,
            default : false
        },
        automaticSendToken : {
            type : Boolean,
            default : true
        }
    },
    emits: ['submitVerificationCodeEventCallback'],
    setup(props, { emit }) {
        const requestTokenApiProps = ref(props.requestTokenApi);
        const otpTokenRequestId = ref(null);
        const userStore = useUserStore();
        const otpStore = useOtpStore();

        /**
         * Methods
         */
        function submitVerificationCodeEvent(event, isSuccess, data) {
            emit('submitVerificationCodeEventCallback', event, isSuccess, data);
        }

        onMounted(async () => {
            if (props.automaticSendToken) {
                await sendEmailAuthenticationCodeApi();
            }
        });

        async function sendEmailAuthenticationCodeApi() {
            try {
                let response = await sendUserAuthenticationCodeByUrl(requestTokenApiProps.value);
                otpTokenRequestId.value = response.data.otp_token_request_id;
                userStore.setLatestTokenRequest(otpTokenRequestId.value);
                otpStore.setOtpTokenRequest(response.data);
                return response;
            } catch (error) {
                console.log('@error')
            }
        }

        return {
            /**
             * Default variables
             */

            t,
            otpTokenRequestId,
            /**
             * Default Methods
             */
            submitVerificationCodeEvent,
            sendEmailAuthenticationCodeApi,
        };
    },
};
</script>
