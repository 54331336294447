export default [
    {
        path: '/user',
        name: 'account.user.access',
        exact: true,
        component: () => import('@/pages/user/Index'),
        meta: {
            auth: true,
            permission: ['account.user.access'],
        },
    },
    {
        path: '/user/:id',
        name: 'account.user.view',
        exact: true,
        component: () => import('@/pages/user/View'),
        meta: {
            auth: true,
            permission: ['account.user.view'],
        },
    },
];
