<template>
    <div class="layout-sidebar" @click="onMenuClick">
        <router-link to="/" class="logo">
            <img id="app-logo" class="logo-image" :src="smallLogo" alt="" :class="isEUP ? 'w-full' : ''"/>
            <span v-if="!isEUP" class="app-name">Nimbus</span>
        </router-link>

        <div class="layout-menu-container">
            <AppSubmenu :items="model" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="active" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
            <div v-can="'switch.to.cumulus'">
                <br />
                <a @click="switchToCumulus" class="p-ripple layout-root-menuitem switch-to-cumulus">
                    <i :class="['layout-menuitem-icon', 'pi pi-arrow-left']"></i>
                    <span class="layout-menuitem-text" style="margin-left: 5px">Switch to Cumulus</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
import { useEUPStore } from "@/stores/eup-store";
import { onMounted, computed, ref } from "vue";
import { getEndUserPortalHost } from '@/service/EndUserPortalService';
import HandoverToken from './service/HandOverToken';
import UserAuthenticationService from './service/UserAuthenticationService';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    props: {
        model: Array,
        layoutMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: { AppSubmenu },
    setup () {
        const eupStore = useEUPStore();
        const isEUP = ref(false);

        onMounted(() => {
            // by default set the eup settings in state
            useEUPStore().setEUPSettings();
            isEUP.value = getEndUserPortalHost() ? true : false;
        })

        const smallLogo = computed(() => {
            return eupStore.eupSettings.public_path
                ? eupStore.eupSettings.public_path.small_logo
                : "assets/layout/images/emersion-logo-white.png"
        });

        const switchToCumulus = () => {
            let handoverToken = new HandoverToken();
            let userService = new UserAuthenticationService();

            handoverToken
                .requestHandOver(userService.getBearer())
                .then((res) => {
                    let path = process.env.VUE_APP_CUMULUS_URL + '?handover_token=' + res.data.handover_token;

                    window.location.href = path;
                })
                .catch((error) => {
                    this.$router.push('/error')
                });
        }

        return {
            smallLogo,
            isEUP,
            switchToCumulus
        }
    }
};
</script>
<style scope>
.switch-to-cumulus {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 5px;
}
</style>
