<template>
    <div class='flex'>
        <div class='flex w-6 h-screen align-items-center justify-content-center flex-column'>
            <div v-if='!submitted' class='flex gap-3 flex-column w-6'>
                <div>
                    <div>
                        <h2 class='font-bold'>{{ t('lbl_reset_password', 'Reset Password') }}</h2>
                    </div>
                    <div>
                        <span class=''>{{ t('lbl_reset_password_subtitle', 'You have reset your password') }}</span>
                    </div>
                    <div class='mt-5 mb-3'>
                        <span class='font-bold'>{{ t('lbl_reset_password_subtitle', 'Create New Password') }}</span>
                    </div>

                    <NimbusInputPassword :label="t('lbl_password', 'Password')" required
                                         v-model='formData.password'
                                         :errorMessage='errors.password'
                                         toggle-mask template='yes'>
                        <template #header>
                            <h6>{{ t('lbl_pick_password', 'Pick a new password') }}</h6>
                        </template>
                        <template #footer>
                            <Divider />
                            <p class='mt-2'>{{ t('lbl_suggestions', 'Suggestions') }}</p>
                            <ul class='pl-2 ml-2 mt-0' style='line-height: 1.5'>
                                <li>{{ t('lbl_least_one_lowercase', 'At least one lowercase') }}</li>
                                <li>{{ t('lbl_least_one_uppercase', 'At least one uppercase') }}</li>
                                <li>{{ t('lbl_least_one_numeric', 'At least one numeric') }}</li>
                                <li>{{ t('lbl_minimum_8_characters', 'Minimum 8 characters') }}</li>
                            </ul>
                        </template>
                    </NimbusInputPassword>

                    <NimbusInputPassword :label="t('lbl_confirm_password', 'Confirm Password')" required
                                         :feedback='false'
                                         v-model='formData.password_confirmation'
                                         :errorMessage='errors.confirm_password'
                                         toggle-mask />
                    <Button class='mt-5' :label="$t('btn_save_password', 'Save Password')" @click='submitForm'></Button>
                </div>
            </div>
            <div v-else>
                <div>
                    <h4 class='font-bold'>{{ t('lbl_reset_password_success_title', 'Your new password has been saved')
                        }}</h4>
                    <span>{{ t('lbl_reset_password_success_subtitle', 'You can now use your new password next time you log in to your account')
                        }}</span>
                </div>
                <Button class='mt-5' :label="$t('btn_start_using_account', 'Start using your account')"></Button>
            </div>
        </div>
        <div class='hidden w-6 h-screen p-8 pb-0 md:flex flex-column bg-primary'>
            <div class='flex-grow-1 flex align-items-center'>
                <div class='pr-8 flex flex-column gap-6 w-full'>
                    <div class='w-full h-12rem bg-gray-500'></div>
                    <p v-if='settings.login_message' class='text-3xl font-semibold'>{{ settings.login_message }}</p>
                    <div v-else class='w-full flex flex-column gap-4'>
                        <div class='h-2rem bg-gray-500 w-12'></div>
                        <div class='h-2rem bg-gray-500 w-6'></div>
                    </div>
                </div>
            </div>
            <div class='flex gap-5 p-4 font-semibold align-self-end'>
                <div v-if='settings.terms_and_condition_id'>{{ t('lbl_terms_and_condition', 'Terms and Conditions') }}
                </div>
                <div v-if='settings.privacy_policy_id'>{{ t('lbl_privacy_policy', 'Privacy Policy') }}</div>
                <div v-if='settings.footer_message'>{{ settings.footer_message }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import t from '@/service/Translate';
import { getCacheUserPortalSettings } from '@/service/EndUserPortalService';
import NimbusInputPassword from '@/components/nimbus/NimbusInputPassword';

export default {
    name: 'SelLogin',
    components: {
        NimbusInputPassword,
    },
    setup() {
        const formData = ref({
            'password': '',
            'password_confirmation': '',
        });
        const errors = ref({});
        const submitted = ref(false);

        const settings = getCacheUserPortalSettings();

        const submitForm = async () => {
            console.log(formData.value);
            if (formData.value.password === '') {
                submitted.value = false;
                errors.value = {
                    'password': [
                        'This field is required',
                    ],
                    'confirm_password': [
                        'Your password does not match',
                    ],
                };
            } else {
                submitted.value = true;
            }
        };

        return {
            submitted,
            errors,
            formData,
            settings,
            submitForm,
            t,
        };
    },
};
</script>

<style scoped></style>