export default [
    {
        path: '/tax-group',
        name: 'Tax Groups',
        exact: true,
        component: () => import('@/pages/tax_group/Index.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.access'],
        },
    },
    {
        path: '/tax-group/create',
        name: 'Create Tax Group',
        exact: true,
        component: () => import('@/pages/tax_group/Create.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.create'],
        },
    },
    {
        path: '/tax-group/:id',
        name: 'View Tax Group',
        exact: true,
        component: () => import('@/pages/tax_group/View.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.view'],
        },
    },
    {
        path: '/tax-group/:id/edit',
        name: 'Edit Tax Group',
        exact: true,
        component: () => import('@/pages/tax_group/Edit.vue'),
        meta: {
            auth: false,
            permission: ['tax-group.edit'],
        },
    },
    {
        path: '/tax-group/config',
        name: 'Tax Groups Configuration',
        exact: true,
        component: () => import('@/pages/tax_group/config/Index.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.config.access'],
        },
    },
    {
        path: '/tax-group/config/create',
        name: 'Create Tax Group Configuration',
        exact: true,
        component: () => import('@/pages/tax_group/config/Create.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.config.create'],
        },
    },
    {
        path: '/tax-group/config/:id/edit',
        name: 'Edit Tax Group Configuration',
        exact: true,
        component: () => import('@/pages/tax_group/config/Edit.vue'),
        meta: {
            auth: true,
            permission: ['tax-group.config.edit'],
        },
    },
];
