export default [
    {
        path: '/ticket/sub-category',
        name: 'Ticket Sub Category Index',
        exact: true,
        component: () => import('@/pages/ticket_sub_category/Index.vue'),
        meta: {
            auth: true,
            permission: ['ticket.sub-category.access'],
        },
    },
];
