export default [
    {
        path: '/module',
        name: 'Modules',
        exact: true,
        component: () => import('@/pages/module/Index.vue'),
        meta: {
            auth: true,
            permission: ['module.access'],
        },
    },
    {
        path: '/module/create',
        name: 'Create Module',
        exact: true,
        component: () => import('@/pages/module/Create.vue'),
        meta: {
            auth: true,
            permission: ['module.create'],
        },
    },
    {
        path: '/module/edit/:id',
        name: 'Module Edit',
        component: () => import('@/pages/module/Edit.vue'),
        meta: {
            auth: true,
            permission: ['module.edit'],
        },
    },
    {
        path: '/module/:id',
        name: 'View Module',
        exact: true,
        component: () => import('@/pages/module/View.vue'),
        meta: {
            auth: true,
            permission: ['module.view'],
        },
    },
    {
        path: '/module/:id/account',
        name: 'View Module Account',
        exact: true,
        component: () => import('@/pages/module/Account.vue'),
        meta: {
            auth: true,
            permission: ['module.id.account.access'],
        },
    },
];
