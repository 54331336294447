export default [
    {
        path: '/orders',
        name: 'Orders',
        exact: true,
        component: () => import('@/pages/order/Index.vue'),
        meta: {
            auth: true,
            permission: ['order.access']
        }
    },
    {
        path: '/order/create',
        name: 'Create Order',
        exact: true,
        component: () => import('@/pages/order/Create.vue'),
        meta: {
            auth: true,
            permission: ['order.create']
        },
    },
    {
        path: '/order/:id',
        name: 'View Order',
        exact: true,
        component: () => import('@/pages/order/View.vue'),
        meta: {
            auth: true,
            permission: ['order.view']
        }
    },
    {
        path: '/order/summary',
        name: 'orderSummary',
        exact: true,
        component: () => import('@/pages/order/View.vue'),
        props : true,
        meta: {
            auth: true,
            permission: ['order.create']
        },
    },
    {
        path: '/order/edit',
        name: 'Edit Order',
        exact: true,
        component: () => import('@/pages/order/Create.vue'),
        meta: {
            auth: true,
            permission: ['order.create']
        },
    },
]