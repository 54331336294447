import t from '@/service/Translate';

/**
 * Prefixes are value that are concatenated on any variables
 *
 * @type {{lsStorage: string}}
 */
export const Prefixes = {
    'nimbusLocalStorage': 'nimbus__'
};

export const Constants = {
    NO_PERMISSION_LONG: t('no_permission_long', "You don't have permissions to access this feature. Please login as a different user with the permission to access this feature."),
    UNABLE_TO_PROCESS_REQUEST: t('unable_to_process_request', 'We are having trouble processing your request. Please try again later.'),
    SERVER_ERROR: t('server_error', 'Server error - please contact your administrator'),
    UNAUTHORISED: t('unauthorised_error', "You're session has expired or you're logged-out somewhere. Please login again."),
    DEFAULT_MAIN_COLOR: '#343a40',
    DEFAULT_ACTIVE_COLOR: '#3f474e',
    DEFAULT_HOVER_COLOR: '#4b545c',
};

/** Timer Delay values to be used mostly on setTimeout function */
export const DelayConstants = {
    RELOAD_DELAY_500: 500,
    RELOAD_DELAY_2000: 2000,
    RELOAD_DELAY_2500: 2500,
    RELOAD_DELAY_3000: 3000,
    RELOAD_DELAY_1000: 1000,
    TOAST_DELAY_2000: 2000,
    TOAST_DELAY_3000: 3000,
}

/**
 * Constants for End User Portal
 * These constants are based on the database
 * table column which is currently set to char(1)
 * That is why the value returned is a String
 */
export const UserPortalConstants = {
    FORGOT_PASSWORD_FEATURE_ON: "1",
    FORGOT_PASSWORD_FEATURE_OFF: "0",
    CUSTOM_URL_IS_VERIFIED: "1",
    END_USER_PORTAL_KEY : "neup-url",
    END_USER_PORTAL_SETTING_KEY : "portal-settings",
    END_USER_PORTAL_SETTING_CRYPT_IV : "iv",
}

/**
 * Response Constants
 */
export const Response = {
    UNPROCESSIBLE_ENTITY: 422,
    SUCCESS: 200,
    SERVER_ERROR: 500,
    BAD_REQUEST : 400
}


/**
 * CUMULUS USER ACCOUNT LOGIN SECURITY
 */
export const Security = {
    APP : 1,
    EMAIL : 2,
    OTP : 3
}

/**
 * Ticket Status Constants
 */
export const TicketStatus = {
    NEW: 1,
    CLOSED: 6,
}


export const OTPValidationStatus = {
    SUCCESS: 1,
    FAILED: 2,
    EXPIRED_TOKEN: 3,
    TOO_MANY_ATTEMPTS : 4
}