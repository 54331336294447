import * as http from '@/core/apis';

/**
 *
 * @returns item details
 */
export async function getItem(id) {
    return await http.get(`item/${id}`);
}

/**
 *
 * @returns list of item types
 */
export async function fetchItemTypes() {
    return await http.get('item-type');
}

/**
 *
 * @returns list of item categories
 */
export async function fetchItemCategories() {
    return await http.get('item-category');
}

/**
 *
 * @param {*} payload
 * @returns list of item
 */
export async function fetchItems(payload) {
    return await http.post('item/item-look-up', payload);
}

/**
 *
 * @param {*} payload
 * @returns list of item
 */
export async function fetchItemBySKU(payload) {
    return await http.post('item/item-look-up/sku', payload);
}

/**
 *
 * @param {*} accountId
 * @param {*} productId
 * @returns item price
 */
export async function fetchItemPrice(accountId, productId, payload) {
    return await http.post(`item/pricing/${accountId}/${productId}`, payload);
}

/**
 * Fetch list of items based on the key
 *
 * @param key
 * @returns {Promise<*>}
 */
export async function fetchItemsViaKey(key) {
    return await http.get(`item/list/key?search=${key}`);
}
