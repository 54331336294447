export const Permissions = {
    beforeMount(el, binding) {
        if (!can(binding.value)) {
            el.style.display = 'none';
        }
    },
};

export function can(permission) {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions == null) {
        permissions = []
    }
    
    return !!permissions.includes(permission);
}

export function cannot(permission) {
    return !can(permission);
}
