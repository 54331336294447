import {postOauth} from '@/core/apis';
import UserAuthenticationService from '@/service/UserAuthenticationService';

export const refreshToken = async () => {
    const session = JSON.parse(localStorage.getItem('nimbus-session'));

    try {
        const response = await postOauth(`token`, {
            refresh_token: session.refresh_token,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            grant_type : "refresh_token",
            scope : ""
        });

        const userService = new UserAuthenticationService();
        // set the corresponding bearer
        userService.setBearer(response.access_token);
        userService.setBearerSession(response);
    } catch (error) {
        /**
         * @TODO
         * Handle Possible Server Error
         * - Unauthenticated
         * - Forbidden
         */
        console.error(error);
    }
};
