import { createRouter, createWebHistory } from 'vue-router';
import UserAuthenticationService from '@/service/UserAuthenticationService';
import HandoverToken from '@/service/HandOverToken';
import EventBus from '@/event-bus';
import appRoutes from '@/routes/modules';
import { useLoaderStore } from '@/stores/loaderStore'; // DEV-912: Load all the list of routes in modules/index.js

const baseURL = '/#';

// default routes
let routes = appRoutes;

const router = createRouter({
    history: createWebHistory(baseURL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});
router.beforeEach((to, from, next) => {
    const loaderStore = useLoaderStore();
    loaderStore.setLoader(true);

    document.title = to.meta.title || 'Project Nimbus';

    let userService = new UserAuthenticationService();
    let permissions = userService.getPermissions();

    let handoverToken = new HandoverToken();
    let handover_token = to.query.handover_token;

    if (handover_token !== undefined) {
        handoverToken
            .checkHandOverParams(to.query.handover_token)
            .then((res) => {
                // set the token
                userService.setBearer(res.data.access_token);

                EventBus.emit('loggedIn', 1);
                // call this emit just in case the navigation ended first
                EventBus.emit('translateMenu');

                // set this two factor authentication
                userService.setTwoFactorAuthentication(Date.now());

                // request in api to set the permission
                userService.cachePermission().then(() => {
                    next();
                });
            })
            .catch((error) => {
                router.push({ path: '/login' });
            });
    } else {
        //check if page has restrictions

        let pagePermission = to.meta.permission ?? [];
        let permissionIncluded = false;

        for (let i = 0; i<pagePermission.length; i++) {
            if (permissions.includes(pagePermission[i])) {
                permissionIncluded = true;
            }
        }

        if (to.name !== 'access-denied' && !permissionIncluded && to.meta.auth) {
            userService.cachePermission();

            // Only show access denied when accessing page that they doesn't have access
            next(
                !userService.isAuthenticated()
                    ? {
                          name: 'login',
                          query: { redirect: to.path },
                      }
                    : { name: 'access-denied' }
            );
        } else {
            if (to.meta.auth) {
                // check two factor authentication
                // need to check first if the nimbus-two-factor-authentication key contains value,
                // if user has 2fa enable, user need to go to the 2FA code verification if valid will set timestamp as the value
                // when user tries to go to other pages without validating the 2FA code will set the token to null and user will be redirected to the login page
                if (userService.getTwoFactorAuthentication() === 'null' || userService.getTwoFactorAuthentication() === null) {
                    // set bearer token to null
                    userService.setBearer(null);
                }

                // check token exist
                if (!userService.isAuthenticated()) {
                    // set visited url
                    next({ name: 'login', query: { redirect: to.path } });
                } else {
                    // verify token if expired
                    userService.getAuthenticatedUser().then((res) => {
                        if (!res.toString().includes('401')) {
                            // authenticated, proceed to the next page and recache permissions
                            userService.cachePermission();
                            next();
                        } else {
                            // set visited url
                            next({ name: 'login', query: { redirect: to.path } });
                        }
                    });
                }
            } else {
                if (to.name === 'login') {
                    if (userService.isAuthenticated()) {
                        let prevUrl = to.query.redirect;
                        let paths = ['/error', '/login', '/access-denied', '/', '/confirm-otp'];
                        let url = paths.includes(prevUrl) || paths.includes(to.path) ? '/user-profile' : prevUrl;

                        router.push({ path: url });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        }
    }
});

router.afterEach(() => {
    const loaderStore = useLoaderStore();
    loaderStore.setLoader(false);
});

export default router;
