<template>
    <NimbusInputWrapper v-bind="$props" ref="wrapper" @right-icon-click="(e) => $emit('rightIconClick', e)" right-icon-out left-icon-disabled>
        <template #input>
            <Textarea v-if="tooltip" v-tooltip="tooltip" v-bind="$attrs" @input="updateInput" :class="[wrapper?.inputBorderColorClass, inputClass]" :modelValue="modelValue" class="w-full niw-dynamic-border" />
            <Textarea v-else v-bind="$attrs" @input="updateInput" :class="[wrapper?.inputBorderColorClass, inputClass]" :modelValue="modelValue" class="w-full niw-dynamic-border" />
        </template>
        <template #bottom>
            <slot></slot>
        </template>
    </NimbusInputWrapper>
</template>

<script>
import Textarea from "primevue/textarea";
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';

import { ref } from 'vue';

export default {
    name: 'NimbusTextarea',
    components: {
        NimbusInputWrapper,
        Textarea,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: String,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = (event) => {
            emit('update:modelValue', event.target.value);
        };

        return {
            /**
             * Data
             */
            wrapper,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>

<style></style>
