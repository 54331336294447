import { getLSItem } from '@/service/NimbusLSService';

const EXPIRY_TIME = 900; // 15 minutes
const WARNING_TIME = 780; // 13 minutes
const ACTIVE_TIME = 600; // 10 minutes

const LS_DEBUG_EXPIRY = 'debug-al-expiry-time';
const LS_DEBUG_WARNING = 'debug-al-warning-time';
const LS_DEBUG_ACTIVE = 'debug-al-active-time';

/**
 * Get the Default Expiry Time
 *
 * @returns {number|number}
 */
export function getExpiryTime() {
    return getLSItem(LS_DEBUG_EXPIRY) ? parseInt(getLSItem(LS_DEBUG_EXPIRY)) : EXPIRY_TIME;
}

/**
 * Get the Default Expiry Time
 *
 * @returns {number|number}
 */
export function getWarningTime() {
    return getLSItem(LS_DEBUG_WARNING) ? parseInt(getLSItem(LS_DEBUG_WARNING)) : WARNING_TIME;
}


/**
 * Get the Default Active Time
 * @returns {number|number}
 */
export function getActiveTime() {
    return getLSItem(LS_DEBUG_ACTIVE) ? parseInt(getLSItem(LS_DEBUG_ACTIVE)) : ACTIVE_TIME;
}