<template>
    <div class="time-log-auto-timer-form">
        <div class="absolute text-3xl custom-pi-times-icon"
             @click="hideTimerForm"
        ><i class="pi pi-angle-down"></i></div>
        <div class="grid grid-nogutter">
            <div class="col-6 mb-3">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_log_time_against', 'Log Time Against') }}</h5>
                <label><a :href="`#/ticket/${ticketDetails.id}`">Ticket No. {{ ticketDetails.id }}</a></label>
            </div>
            <div class="col-6 mb-5">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_account_name', 'Account Name') }}</h5>
                <label>{{ ticketDetails.accountName }}</label>
            </div>
            <div class="col-12 mb-5">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_subject', 'Subject') }}</h5>
                <label>{{ ticketDetails.subject }}</label>
            </div>
            <div class="col-12 mt-2 mb-2">
                <div class="timer-text">
                    <h1 class="text-6xl">{{ getTimerFormatted }}</h1>
                </div>
            </div>
            <div class="col-12">
                <Button
                    v-if="!store.isTimerStarted"
                    type="button"
                    class="p-button p-component"
                    @click="store.startTimer">
                    <span class="font-semibold">{{ t('lbl_start_timer', 'Start Timer') }}</span>
                    <img src="assets/source-icons/play-with-circle.svg" class="custom-icon"/>
                </Button>
                <Button
                    v-if="store.isTimerStarted"
                    type="button"
                    class="p-button p-component"
                    @click="store.stopTimer">
                    <span class="font-semibold">{{ t('lbl_stop_timer', 'Stop Timer') }}</span>
                    <img src="assets/source-icons/stop-with-circle.svg" class="custom-icon"/>
                </Button>
            </div>

            <div class="col-12" v-if="!store.isTimerStarted && store.timer > 0">
                <div class="grid grid-nogutter">
                    <div class="col-7 mt-6">
                        <div class="auto-time-zone-card flex flex-wrap justify-content-start card-container gap-1">
                            <NimbusFlatCard class='flex'>
                                <Content :label-text="t('lbl_time_zone', 'Time Zone')" layout='column'>
                                    <template #content>
                                        <div class="pt-2">
                                            <p class='text-gray-600'>{{ userStore.getUserTimezoneDisplay }}</p>
                                        </div>
                                    </template>
                                </Content>
                            </NimbusFlatCard>
                        </div>
                    </div>
                    <div class="col-5">&nbsp;</div>

                    <div class="col-6 mt-4">
                        <NimbusDropdown
                            class="p-0"
                            v-model="formInputs.billable_type"
                            :options="billableTypes"
                            optionLabel="name"
                            optionValue="id"
                            :filter="true"
                            :showClear="true"
                            :label="t('lbl_time_log_billable_type', 'Time Log Type')"
                            :placeholder="t('lbl_time_log_billable_type', 'Time Log Type')"
                            :errorMessage="errors.billable_type"
                            :class="{ 'p-invalid': errors.billable_type }"
                            required
                            style="min-width: 0 !important"/>
                    </div>

                    <div class="col-6 mt-4 pl-3">
                        <NimbusDropdown
                            class="p-0"
                            v-model="formInputs.item"
                            :options="itemLabours"
                            optionLabel="name"
                            optionValue="id"
                            :filter="true"
                            :showClear="true"
                            :label="t('lbl_item', 'Item')"
                            :placeholder="t('lbl_item', 'Item')"
                            style="min-width: 0 !important"/>
                    </div>

                    <div class="col-12 mt-2">
                        <NimbusTextarea
                            :label="t('lbl_internal_note', 'Internal Note')"
                            :placeholder="t('lbl_enter_internal','Enter interal note')"
                            :autoResize="false"
                            rows="3"
                            v-model.trim="formInputs.internal_note"
                            :required="true"
                            :errorMessage="errors.internal_note"
                        />
                    </div>

                    <div class="col-12 mt-3">
                        <NimbusTextarea
                            :label="t('lbl_external_note', 'External Note')"
                            :placeholder="t('lbl_enter_external_note','Enter external note')"
                            :autoResize="false"
                            rows="3"
                            v-model.trim="formInputs.external_note"
                        />
                    </div>

                    <div class="col-12 mt-2 text-right">
                        <div class="action-button">
                            <Button class="p-button-outlined p-button-secondary font-bold"
                                    :label="t('btn_cancel', 'Cancel')"
                                    @click="cancelFormConfirmDialog = !cancelFormConfirmDialog"/>
                            <Button class="ml-3 font-bold"
                                    :label="t('btn_save_time_entry', 'Save Time Entry')"
                                    @click="saveTimeLogEntry"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="cancelFormConfirmDialog" :style="{ width: '19%' }"
            :modal="true"
            class="p-fluid">
        <template #header>
            <h5 class="dialog-header b text-orange-600">
                <i class="pi pi-exclamation-triangle relative text-xl" style="top:3px"></i>
                <label class="ml-2 text-xl">{{ t('lbl_warning_dialog', 'Warning') }}</label>
            </h5>
        </template>
        <div>
            <p class="text-lg">
                {{ t('msg_time_cancel_dialog', 'Are you sure you want to cancel this time log?') }}
            </p>
        </div>
        <template #footer>
            <Button class="p-button p-button-outlined p-button-secondary p-component" :label="t('btn_no','No')"
                    @click="cancelFormConfirmDialog = !cancelFormConfirmDialog"/>
            <Button class="p-mr-2" :label="t('btn_yes','Yes')"
                    @click="closeTimeTrackingForm"/>
        </template>
    </Dialog>

</template>

<script>
import {ref, toRefs, computed, onMounted} from "vue";
import {useToast} from "primevue/usetoast";
import {numbersOnly} from "@/service/NimbusUtilities";
import {
    storeTimeLogAutoTimerEntry
} from "@/service/TimeTrackingService";
import t from "@/service/Translate"
import UserAuthenticationService from "@/service/UserAuthenticationService";
import {useAutoTimer} from "@/stores/auto-timer";
import {useUserStore} from "@/stores/user-store";
import NimbusTextarea from "@/components/nimbus/NimbusTextarea";
import NimbusDropdown from "@/components/nimbus/NimbusDropdown";
import NimbusFlatCard from "@/components/nimbus/NimbusFlatCard";
import Content from '@/components/account/summary/ReadOnlyCard/Content';

export default {
    name: "AutoTimerForm",
    emits: ['timeLogAdded', 'closeTimeTrackingForm'],
    components: {
        NimbusTextarea,
        NimbusDropdown,
        NimbusFlatCard,
        Content
    },
    props: {
        ticketID: {
            type: Number,
            default: null
        },
        accountID: {
            type: Number,
            default: new UserAuthenticationService().getAuthenticatedUserID(),
        },
        timerStoppedOutside: {
            type: Boolean,
            default: false
        },
        billableTypes: {
            type: Array,
            default: () => []
        },
        itemLabours: {
            type: Array,
            default: () => []
        },
        ticketDetails: {
            type: Object,
            default: () => {} 
        }
    },
    setup(props, {emit}) {
        const toast = useToast();
        const store = useAutoTimer();
        const userStore = useUserStore();
        const {ticketID, accountID} = toRefs(props);
        const formInputs = ref({
            ticket_id: parseInt(ticketID.value),
            owned_by: parseInt(accountID.value),
            billable_type: null,
            item: null
        })
        const errors = ref({});

        const cancelFormConfirmDialog = ref(false);
        const saveTimeLogEntry = async () => {
            try {
                formInputs.value.ticket_id = store.globalTicket;
                if (!formInputs.value.owned_by) {
                    formInputs.value.owned_by = new UserAuthenticationService().getAuthenticatedUserID();
                }

                await storeTimeLogAutoTimerEntry(formInputs.value);
                await emit('timeLogAdded', formInputs.value);

                // show notifications
                let details = t('msg_new_time_added', 'A new time entry for') + ` ${store.globalTicket} ` + t('msg_new_time_is_saved', 'is saved.');
                toast.add({
                    severity: 'success',
                    summary: t('msg_success_time_entry', 'Time Entry Added'),
                    detail: details,
                    life: 3000,
                });

                //clear the forms
                clearForms();
            } catch (error) {
                if (error.response.data.errors) {
                    errors.value = error.response.data.errors;
                }
            }
        }

        /**
         * Clear the whole manual form
         */
        const clearForms = () => {
            //reset state values
            store.resetStateValues(true);

            formInputs.value.external_note = '';
            formInputs.value.internal_note = '';

            errors.value = {};
        }

        /**
         * Close the Form
         */
        const closeTimeTrackingForm = () => {
            // clear form first
            clearForms();

            cancelFormConfirmDialog.value = false;

            toast.add({
                severity: 'success',
                summary: t('msg_time_cancelled', 'Time Entry Cancelled'),
                detail: '',
                life: 3000,
            });

            emit('closeTimeTrackingForm');
        }

        /**
         * Hide the form
         */
        const hideTimerForm = () => {
            if (store.timer > 0) {
                store.isSideBarCollapsed = true;
            }
            emit('closeTimeTrackingForm');
        }

        onMounted(() => {
            store.setGlobalTicketValueWhenLoaded(ticketID.value);
        })

        return {
            /**
             * Constants
             */
            store,
            userStore,
            t,
            numbersOnly,
            formInputs,
            errors,
            cancelFormConfirmDialog,
            /**
             * Functions
             */
            saveTimeLogEntry,
            closeTimeTrackingForm,
            hideTimerForm,
            /**
             * Stores
             */
            getTimerFormatted: computed(() => store.getTimerFormatted)
        }
    }
}
</script>

<style scoped>
.time-log-auto-timer-form .custom-icon {
    width: 25px;
    padding-left: 5px;
}

.time-log-auto-timer-form .custom-pi-times-icon {
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.auto-time-zone-card .flex-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>
