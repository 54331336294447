import { useUserStore } from '@/stores/user-store';

export default [
    {
        path: '/payments',
        name: 'Payments',
        exact: true,
        component: () => import('@/pages/account/account-list/AccountMain'),
        meta: {
            auth: true,
            permission : ['payments.access', 'portal.payments.access'],
            prefix: 'finance-payment',
            redirect: 'payment-main', //name of the route
            title : 'Accounts'
        },
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            if (userStore.isEup) {
                next({ name: 'payment-main', params: { accountId: userStore.userData.accountId } });
            } else {
                next();
            }
        },
    },
    {
        path: '/payment-account/:accountId',
        name: 'payment-main',
        exact: true,
        redirect: { name: 'payment-list' },
        component: () => import('@/pages/finance/payment/payments/Main'),
        meta: {
            auth: true,
            permission: ['account.access'],
            title : 'Payments'
        },
        children: [
            {
                path: 'history',
                name: 'payment-list',
                exact: true,
                component: () => import('@/pages/finance/payment/payments/payments/PaymentMain'),
                meta: {
                    auth: true,
                    permission : ['payments.access', 'portal.payments.access'],
                    title : 'Payments',
                    prefix: 'payment',
                },
            },
            {
                path: 'payment/:id/details',
                name: 'payment-details',
                exact: true,
                component: () => import('@/pages/finance/payment/payments/payments/PaymentDetails'),
                meta: {
                    auth: true,
                    permission : ['payments.access', 'portal.payments.access'],
                    title : 'Payment Details',
                    prefix: 'payment-details',
                },
            },
            {
                path: 'receive',
                name: 'receive-payments',
                exact: true,
                component: () => import('@/pages/finance/payment/payments/receive-payments/ReceivePaymentMain'),
                meta: {
                    auth: true,
                    permission : ['payments.access', 'portal.payments.access'],
                    title : 'Receive Payment',
                    prefix: 'receive-payments',
                },
            },
            {
                path: 'take',
                name: 'take-payments',
                exact: true,
                component: () => import('@/pages/finance/payment/payments/take-payments/TakePaymentMain'),
                meta: {
                    auth: true,
                    permission : ['payments.access', 'portal.payments.access'],
                    title : 'Take Payment',
                    prefix: 'take-payments',
                },
            },
        ]
    },
]