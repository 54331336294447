export default [
    {
        path: '/permissions',
        name: 'Permissions',
        exact: true,
        component: () => import('@/pages/permission/Main.vue'),
        meta: {
            auth: true,
            permission: ['permission.access']
        },
    },
    {
        path: '/permission/:id',
        name: 'View Permission',
        exact: true,
        component: () => import('@/pages/permission/View.vue'),
        meta: {
            auth: true,
            permission: ['permission.view']
        },
    },
    {
        path: '/permission/create',
        name: 'Permission Create',
        exact: true,
        component: () => import('@/pages/permission/Create.vue'),
        meta: {
            auth: true,
            permission: ['permission.create']
        },
    },
    {
        path: '/permission/:id/edit',
        name: 'Permission Edit',
        exact: true,
        component: () => import('@/pages/permission/Edit.vue'),
        meta: {
            auth: true,
            permission: ['permission.edit']
        }
    },
]