export default [
    {
        path: '/item',
        name: 'Items',
        exact: true,
        component: () => import('@/pages/item/Index.vue'),
        meta: {
            auth: true,
            permission: ['item.access'],
        },
    },
    {
        path: '/item/:id',
        name: 'View Item',
        exact: true,
        component: () => import('@/pages/item/View.vue'),
        meta: {
            auth: true,
            permission: ['item.view'],
        },
    },
];
