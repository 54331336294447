export default [
    {
        path: '/profit-centre/group',
        name: 'Profit Centre Group',
        exact: true,
        component: () => import('@/pages/profit_centre/group/Index.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.group.access']
        },
    },
    {
        path: '/profit-centre/group/create',
        name: 'Profit Centre Group Create',
        exact: true,
        component: () => import('@/pages/profit_centre/group/Create.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.group.create']
        },
    },
    {
        path: '/profit-centre/type',
        name: 'Profit Centre Type List',
        exact: true,
        component: () => import('@/pages/profit_centre/type/Index.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.type.access']
        },
    },
    {
        path: '/profit-centre/type/create',
        name: 'Profit Centre Type Create',
        exact: true,
        component: () => import('@/pages/profit_centre/type/Create.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.type.create']
        },
    },
    {
        path: '/profit-centre/type/:id/edit',
        name: 'Profit Centre Type Edit',
        exact: true,
        component: () => import('@/pages/profit_centre/type/Edit.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.type.edit']
        },
    },
    {
        path: '/profit-centre/group/:id/edit',
        name: 'Profit Centre Group Edit',
        exact: true,
        component: () => import('@/pages/profit_centre/group/Edit.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.group.edit']
        },
    },
    {
        path: '/profit-centre',
        name: 'Profit Centre',
        exact: true,
        component: () => import('@/pages/profit_centre/Index.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.access']
        }
    },
    {
        path: '/profit-centre/create',
        name: 'Profit Centre Create',
        exact: true,
        component: () => import('@/pages/profit_centre/Create.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.create']
        }
    },
    {
        path: '/profit-centre/:id',
        name: 'Profit Centre View',
        exact: true,
        component: () => import('@/pages/profit_centre/View.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.view']
        }
    },
    {
        path: '/profit-centre/:id/edit',
        name: 'Profit Centre Edit',
        exact: true,
        component: () => import('@/pages/profit_centre/Edit.vue'),
        meta: {
            auth: true,
            permission: ['profit-centre.edit']
        }
    },
]