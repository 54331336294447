/**
 * This file is to mapped all the routes define on each .js file
 * Import files in modules dir
 *
 * If you have a new file/routes just import here then register on the export
 */
import auth from '@/routes/modules/auth';
import starter from '@/routes/modules/starter';
import finance from '@/routes/modules/finance';
import ticket from '@/routes/modules/ticket';
import pagegroup from '@/routes/modules/pagegroup';
import role from '@/routes/modules/role';
import permission from '@/routes/modules/permission';
import customer from '@/routes/modules/customer';
import profit_centre from '@/routes/modules/profit_centre';
import module from '@/routes/modules/module';
import error from '@/routes/modules/error';
import feature from '@/routes/modules/feature';
import dashboard from '@/routes/modules/dashboard';
import item from '@/routes/modules/item';
import tax_group from '@/routes/modules/tax_group';
import tax_rate from '@/routes/modules/tax_rate';
import order from '@/routes/modules/order';
import account from '@/routes/modules/account';
import contact from '@/routes/modules/contact';
import user_profile from '@/routes/modules/user_profile';
import user from '@/routes/modules/user';
import portal from '@/routes/modules/portal';
import event_template from '@/routes/modules/event_template';
import time_tracking from "@/routes/modules/time_tracking";
import end_user_portal from "@/routes/modules/end_user_portal";
import event from "@/routes/modules/event";
import forgot_password from "@/routes/modules/forgot_password";
import ticket_queue from "@/routes/modules/ticket_queue";
import ticket_category from "@/routes/modules/ticket_category";
import ticket_sub_category from "@/routes/modules/ticket_sub_category";
import services from "@/routes/modules/services";
import reports from "@/routes/modules/reports";
import invoice from "@/routes/modules/invoice";
import payment from "@/routes/modules/payment";
import payment_method from '@/routes/modules/payment_method';
import otp_verification from '@/routes/modules/otp-verification';

// Once imported using spread operator syntax, you can easily register the routes
export default [
    ...auth,
    ...starter,
    ...finance,
    ...ticket,
    ...pagegroup,
    ...role,
    ...permission,
    ...customer,
    ...profit_centre,
    ...module,
    ...error,
    ...feature,
    ...dashboard,
    ...item,
    ...tax_group,
    ...tax_rate,
    ...tax_rate,
    ...order,
    ...account,
    ...contact,
    ...portal,
    ...user_profile,
    ...user,
    ...event_template,
    ...time_tracking,
    ...end_user_portal,
    ...event,
    ...forgot_password,
    ...ticket_queue,
    ...ticket_category,
    ...ticket_sub_category,
    ...services,
    ...reports,
    ...invoice,
    ...payment,
    ...payment_method,
    ...otp_verification,
    {
        path: '/:catchAll(.*)', // unrecognized path automatically redirect to error page
        redirect: '/not-found',
    },
];
