export async function encryptData(data, key) {
    const encodedData = new TextEncoder().encode(data);
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        'AES-GCM',
        true,
        ['encrypt'],
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await window.crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        encodedData,
    );
    return { encryptedData, iv };
}

export async function decryptData(encryptedData, iv, key) {
    try {
        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            new TextEncoder().encode(key),
            'AES-GCM',
            true,
            ['decrypt'],
        );
        const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
            { name: 'AES-GCM', iv },
            cryptoKey,
            encryptedData,
        );
        return String.fromCharCode.apply(null, new Uint8Array(decryptedArrayBuffer));
    } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
    }
}

/**
 * Convert ArrayBuffer to Base64-encoded string
 *
 * @param arrayBuffer
 * @returns {string}
 */
export function arrayBufferToBase64(arrayBuffer) {
    const binary = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
    return btoa(binary);
}

/**
 * Convert Base64-encoded string to ArrayBuffer
 *
 * @param base64String
 * @returns {ArrayBufferLike}
 */
export function arrayBufferFromBase64(base64String) {
    const binaryString = atob(base64String);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
