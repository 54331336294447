<script setup>
import { computed } from 'vue';
import { useLoaderStore } from '@/stores/loaderStore';
import { storeToRefs } from 'pinia/dist/pinia';

const
    loaderStore = useLoaderStore(),
    { isLoading } = storeToRefs(loaderStore);

// eslint-disable-next-line no-undef
const props = defineProps({
    text: {
        type: String,
        required: false,
    },
    align: {
        type: String,
        default: 'center', // left | center | right
    },
    withSpinner: {
        type: Boolean,
        default: true,
    },
    type: {
        type: String,
        default: 'stacked', // stacked | inline | full-screen
    },
    size: {
        type: String,
        default: '100%', // @todo: should have a sizes like sm, md, lg, but for now make it arbitrary.
    },
    /** Intended for router */
    isRouteLoader: {
        type: Boolean,
        default: false,
    },
});

const
    alignment = computed(() => `text-${props.align}`),
    type = computed(() => props.type === 'stacked' ? 'flex-column' : 'flex-row align-items-center');
</script>

<template>
    <div
        v-if="isLoading && isRouteLoader"
        class="component-nb-loader__router"
    >
        <div class="flex flex-column gap-2 align-items-center justify-content-center">
            <div id="throbber" class="bounce"></div>
        </div>
    </div>
    <div
        v-else-if="!isRouteLoader"
        class="component-nb-loader flex" :class="[alignment, type]"
    >
        <ProgressSpinner v-if="props.withSpinner" :style="{ height: props.size }" />
        <span v-if="props.text">{{ props.text }}</span>
    </div>
</template>

<style scoped>
#throbber {
    width: 200px;
    height: 200px;
    background-image: url("../../public/assets/layout/images/emersion-logo-blue.png");
    background-size: contain;
    animation: bounce 0.7s infinite alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}
</style>