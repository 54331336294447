<template>
    <div>
        <div class="login-body">
            <div class="login-wrapper">
                <div class="login-panel">
                    <div class="forgot-password-form">
                        <h4>
                            <strong>{{ t('lbl_forgot_password', 'Forgot Password') }}</strong>
                        </h4>
                        <label class="b" for="input">{{ t('lbl_select_reset_method', 'Select reset method') }}<span class="nimbus-text-red line-height-2 ml-1">●</span></label>
                        <SelectButton for="input" v-model="value" :options="options" optionLabel="name" optionValue="value" aria-labelledby="basic" @change="selectResetMethod" />
                        <br />
                        <NimbusInputText :errorMessage="errors['data']" :label="inputLabel" required :placeholder="inputLabel" v-model="inputResetDetails" />
                        <button class="p-button mt-3" @click="resetPassword()">
                            <span>{{ t('lbl_reset', 'Reset Password') }}</span>
                        </button>
                    </div>
                </div>

                <login-image></login-image>

                <Dialog v-model:visible="showDialog" :header="t('lbl_confirmation', 'Confirmation')" :closable="true" :modal="true" :style="{ width: '25vw' }">
                    <div class="confirmation-content">
                        <i class="pi pi-send" style="font-size: 5rem"></i>
                        <h2>{{ t('lbl_reset_dialog_header', 'We received your request to reset your password') }}</h2>
                        <label>{{ t('lbl_reset_dialog_message', 'If your email is in our database, we will send you an email to reset your password. Check your spam folders too') }}</label>
                    </div>
                </Dialog>

                <Dialog v-model:visible="showDialogFailed" :header="t('lbl_confirmation', 'Confirmation')" :closable="true" :modal="true" :style="{ width: '25vw' }">
                    <div class="confirmation-content">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M70 20H10C8.67392 20 7.40215 20.5268 6.46447 21.4645C5.52678 22.4021 5 23.6739 5 25V47.5C5 48.8261 5.52678 50.0979 6.46447 51.0355C7.40215 51.9732 8.67392 52.5 10 52.5H17.5V62.5C17.5 63.163 17.7634 63.7989 18.2322 64.2678C18.7011 64.7366 19.337 65 20 65C20.663 65 21.2989 64.7366 21.7678 64.2678C22.2366 63.7989 22.5 63.163 22.5 62.5V52.5H57.5V62.5C57.5 63.163 57.7634 63.7989 58.2322 64.2678C58.7011 64.7366 59.337 65 60 65C60.663 65 61.2989 64.7366 61.7678 64.2678C62.2366 63.7989 62.5 63.163 62.5 62.5V52.5H70C71.3261 52.5 72.5979 51.9732 73.5355 51.0355C74.4732 50.0979 75 48.8261 75 47.5V25C75 23.6739 74.4732 22.4021 73.5355 21.4645C72.5979 20.5268 71.3261 20 70 20ZM70 40.2156L54.7844 25H70V40.2156ZM25.2156 25L47.7156 47.5H32.2844L10 25.2156V25H25.2156ZM10 32.2844L25.2156 47.5H10V32.2844ZM70 47.5H54.7844L32.2844 25H47.7156L70 47.2875V47.5Z"
                                fill="#D32F2F"
                            />
                        </svg>
                        <h2>{{ t('lbl_reset_dialog_header_failed', 'Resetting your password too soon') }}</h2>
                        <label>{{ t('lbl_reset_dialog_message_failed', 'In order to keep your account safe, we only let you reset your password at every 5 minutes intervals') }}</label>
                    </div>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import t from '@/service/Translate';
import { ref } from 'vue';
import NimbusInputText from '@/components/nimbus/NimbusInputText.vue';
import LoginImage from '@/components/login/LoginImage.vue';
import { forgotPassword } from '@/service/ForgotPasswordService';
import { Response } from '@/service/Constants';

export default {
    components: { NimbusInputText, LoginImage },
    setup() {
        const EMAIL_ADDRESS = 1;
        const USERNAME = 0;

        const value = ref(EMAIL_ADDRESS);
        const options = ref([
            { name: 'Username', value: USERNAME },
            { name: 'Email Address', value: EMAIL_ADDRESS },
        ]);
        const inputLabel = ref(t('lbl_enter_email_address', 'Enter email address'));
        const showDialog = ref(false);
        const showDialogFailed = ref(false);
        const inputResetDetails = ref('');
        const errors = ref([]);

        const selectResetMethod = () => {
            if (value._rawValue === EMAIL_ADDRESS) {
                inputLabel.value = t('lbl_enter_email_address', 'Enter email address');
            } else {
                inputLabel.value = t('lbl_enter_username', 'Enter username');
            }
        };

        const resetPassword = () => {
            errors.value['data'] = null;

            let payload = {
                option: value._rawValue === EMAIL_ADDRESS ? 'email' : 'username',
                data: inputResetDetails.value,
                isValidate: false
            };

            forgotPassword(payload).then((response) => {
                errors.value['data'] = null;
                showDialog.value = true;
           })
           .catch ((error) => {
                if (error.response.status == Response.BAD_REQUEST) {
                   showDialogFailed.value = true;
                } else {
                    let responseError = error.response.data;
                    errors.value['data'] = responseError.data ? responseError.data : responseError.detail;
                }
           })
        };

        return {
            /**
             * Variables
             */
            t,
            options,
            value,
            inputLabel,
            showDialog,
            inputResetDetails,
            errors,
            showDialogFailed,

            /**
             * Functions
             */
            selectResetMethod,
            resetPassword
        };
    },
};
</script>
<style scoped>
.forgot-password-form {
    margin-top: 50%;
    text-align: left;
}
</style>
