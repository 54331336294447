import * as http from '@/core/apis';
import moment from 'moment';

// default second
export const defaultSeconds = 60;

// 6 minutes into seconds
export const roundedInterval = 360;

// Endpoints Lists
/**
 * Fetch list of accounts
 *
 * @returns {Promise<*>}
 */
export async function fetchAccounts() {
    return await http.get('user/list');
}

/**
 * Fetch time log entry
 *
 * @returns {Promise<*>}
 */
export async function getTimeLogEntry(time_log_id) {
    return await http.get(`time-log/${time_log_id}`);
}

/**
 * Store time log entry
 * Fetch list of billable types
 *
 * @returns {Promise<*>}
 */
export async function fetchBillableTypes() {
    return await http.get('time-log/billable-type');
}

/**
 * Store time log entry for auto timer
 *
 * @param payload
 * @returns {Promise<*>}
 */
export async function storeTimeLogAutoTimerEntry(payload) {
    return await http.post('time-log/auto-timer', payload);
}

/**
 * Store time log entry for manual timer
 *
 * @param payload
 * @returns {Promise<*>}
 */
export async function storeTimeLogManualEntry(payload) {
    return await http.post('time-log/manual', payload);
}

/**
 * Update Time Log entry for parent and child data
 *
 * @param id
 * @param payload
 * @returns {Promise<*>}
 */
export async function updateTimeLogEntry(id, payload) {
    return await http.update(`time-log/${id}`, payload)
}

/**
 * Fetch time log entry in cache
 *
 * @returns {Promise<*>}
 */
export async function getTimeLogEntryInCache() {
    return await http.get('time-log/cache');
}

/**
 * Store time log entry in cache
 *
 * @param payload
 * @returns {Promise<*>}
 */
export async function storeTimeLogEntryInCache(payload) {
    return await http.post('time-log/cache', payload);
}

/**
 * Delete time log entry
 *
 * @param time_log_id
 * @returns {Promise<*>}
 */
export async function destroyTimeLogEntry(time_log_id) {
    return await http.destroy(`time-log/${time_log_id}`);
}

/**
 * Update time log entry in cache
 *
 * @param payload
 * @returns {Promise<*>}
 */
export async function updateTimeLogEntryInCache(payload) {
    return await http.post('time-log/cache/update', payload);
}

/**
 * Destroy time log entry in cache
 *
 * @returns {Promise<*>}
 */
export async function destroyTimeLogEntryInCache() {
    return await http.get('time-log/cache/destroy');
}

/**
 * Get chart data of a certain ticket
 *
 * @param ticket_id
 * @returns {Promise<*>}
 */
export async function getTicketTimeLogChart(ticket_id) {
    return await http.get(`time-log/${ticket_id}/chart`);
}

/**
 * This will return the total logged and computed rounded time log
 *
 * @param ticket_id
 * @returns {Promise<*>}
 */
export async function getTicketTotalLogged(ticket_id) {
    return await http.get(`time-log/rounding/${ticket_id}`);
}

// Functions Utilities
/**
 * Convert value into seconds
 *
 * @param value
 * @param type
 * @returns {number}
 */
export function convertNumberToSeconds(value, type) {
    let seconds = 0;

    switch (type) {
        case 'Hour':
            seconds = value * 3600;
            break;
        case 'Minute':
            seconds = value * 60;
            break;
    }

    return seconds;
}

/**
 * Convert seconds to formatted strings
 *
 * @param seconds
 * @param format
 * @returns {string}
 */
export function convertSecondsToFormatted(seconds, format = 'H[h] m[m]') {
    return moment.unix(seconds).utc().format(format);
}

/**
 * Convert Time Duration into AsHours format
 *
 * @param value
 * @param format
 * @returns {number}
 */
export function convertTimeDurationToAsHours(value, format = 'seconds') {
    return moment.duration(value, format).asHours();
}

/**
 * Convert Time Duration into Minutes format
 *
 * @param value
 * @param format
 * @returns {number}
 */
export function convertTimeDurationToMinutes(value, format = 'seconds') {
    return moment.duration(value, format).minutes();
}

export function convertSecondsToHoursAndMinutesDisplay(value) {
    return `${Math.floor(convertTimeDurationToAsHours(value))}h ${convertTimeDurationToMinutes(value)}m`;
}
