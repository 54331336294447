import axios from 'axios';
import UserAuthenticationService from '@/service/UserAuthenticationService';
import { parseColumnValue, parseRowValue } from '@/service/NimbusUtilities';
export default class NimbusService {
    save(url, request, bearer) {
        return axios
            .post(url, request, bearer)
            .then((res) => res.data)
            .catch((error) => error);
    }

    show(url, bearer) {
        return axios
            .get(url, bearer)
            .then((res) => res.data)
            .catch((error) => error);
    }

    delete(url, request, bearer) {
        return axios
            .delete(url, request, bearer)
            .then((res) => res.data)
            .catch((error) => error);
    }

    /**
     * This method is for saving on bulk with array of objects
     * Also automatically populate the validation if elements
     * are present.
     *
     * @param {*} url - url for the api to save bulk
     * @param {*} request - this is the request payload
     * @param {*} instance - instance of the DOM to access thru refs
     * @param {*} utils - NimbusUtilities class object
     * @param {*} notifs - NotificationService class object
     * @param {*} toast - The toast context
     *
     * Change the messages if neccessary
     */
    saveBulk(url, request, notifs, toast) {
        let userService = new UserAuthenticationService();

        axios
            .post(url, request, userService.getBearer())
            .then((res) => {
                console.log(res);
                notifs.fire(toast, 'success', 'Success Message', res.data.message, 3000);
                //utils.displayValidationNimbusForms(instance, null); //reset the validated fields if success
            })
            .catch((error) => {
                if (error.response != undefined) {
                    if (error.response.status === 422) {
                        notifs.fire(toast, 'error', 'Error Message', 'You have some errors in the page', 3000);
                        let errors = error.response.data.errors;
                        //utils.displayValidationNimbusForms(instance, errors);
                    }
                }
            });
    }

    /**
     * this method is for update the order of list
     * base on pass value (keyToChange) as column
     * @param url
     * @param newData
     * @param oldData
     * @param keyToChange
     * @returns {{}}
     */

    updateReorder(url, newData, oldData, keyToChange) {
        let userService = new UserAuthenticationService();

        var items = oldData.map(function (item, index) {
            let columnValue = parseColumnValue(newData[index], keyToChange);

            return { id: item.id, order: columnValue };
        });
        let payload = { re_order: items };
        return axios
            .post(url, payload, userService.getBearer())
            .then((res) => {
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}