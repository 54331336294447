<template>
    <div class="p-2 bg-gray-100 border-round">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "NimbusFlatCard"
}
</script>

<style scoped>

</style>