/**
 *
 * @param {*} vModel
 * clear model content
 */
export function clearContent(vModel) {
    vModel = null;
}

/**
 *
 * @param {*} condition returns true if the condition meets the requirements
 * @param {*} errors array of errors on page
 * @param {*} errorMessageField particular error message field on page
 * @param {*} message error message
 * @returns array of errors
 */
export function validateAutoComplete(condition, errors, errorMessageField, message) {
    errors[errorMessageField] = condition ? message : null;

    return errors;
}

/**
 *
 * @returns auto complete search delay
 */
export function getDelay() {
    return 800;
}

/**
 *
 * @returns search style with icon
 */
export function getAutoCompleteStyleWithIcon() {
    return {
        'background-image': 'url(assets/layout/images/icons/search.svg)',
        'background-size': '1.4em',
        'background-position': '5px 5px',
        'background-repeat': 'no-repeat',
        'text-indent': '20px',
        width: '100%',
    };
}
