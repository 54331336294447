<template>
    <div v-if="isEUP && settings" class="hidden w-full h-screen p-8 pb-0 md:flex flex-column" :style="{ background: getImage(settings.public_path.login_background) }">
        <div class="flex-grow-1 flex align-items-center">
            <div class="grid">
                <div class="col-12">
                    <img class="large-logo-cover" :src="settings.public_path.large_logo" alt="" />
                </div>
                <div class="col-12"  v-if="settings.login_message">
                    <span v-html="settings.login_message"></span>
                </div>
            </div>
        </div>
        <div class="flex gap-5 p-4 font-semibold align-self-end">
            <div v-if="settings.terms_and_conditions_link">
                <strong><a :href="settings.terms_and_conditions_link" target="_blank">{{ t('lbl_terms_and_condition', 'Terms and Conditions') }}</a></strong>
            </div>
            <div v-if="settings.privacy_policy_link">
                <strong><a :href="settings.privacy_policy_link" target="_blank">{{ t('lbl_privacy_policy', 'Privacy Policy') }}</a></strong>
            </div>
            <div v-if="settings.footer_content"><strong>{{ settings.footer_content }}</strong></div>
        </div>
    </div>
    <div v-if="!isLoading && !isEUP" class="login-image">
        <div style="display: inline-block; width: 100%">
            <a href="javascript:void(0)" class="flag flag-us" @click="changeLanguage('en-us')"
                style="width: 20px; height: 15px; margin-left: 10px; float: right">&nbsp;</a>
            <a href="javascript:void(0)" class="flag flag-gb" @click="changeLanguage('en-gb')"
                style="width: 20px; height: 15px; margin-left: 10px; float: right">&nbsp;</a>
            <a href="javascript:void(0)" class="flag flag-au" @click="changeLanguage('en-au')"
                style="width: 20px; height: 15px; margin-left: 10px; float: right">&nbsp;</a>
        </div>
        <div class="login-image-content">
            <h1>{{ t('login_heading1', 'Billing Automation for') }}</h1>
            <h1>{{ t('login_heading2', 'Growing Businesses') }}</h1>
            <h3>{{ t('login_heading3', 'Connect Emersion with everything you already use.') }}</h3>
            <h3>
                {{ t('login_subhead1', 'Emersion integrates to over 100 business systems, vendors, distributors,') }}<br />
                {{ t('login_subhead2', 'payment merchants and enterprise software platforms.') }}
            </h3>
        </div>
        <div class="image-footer">
            <p>{{ t('lbl_copyright', 'Copyright') }} &copy; 2021 Emersion <small> {{ version }}</small></p>
        </div>
    </div>
</template>
<script>
import t from '@/service/Translate';
import EventBus from '@/event-bus';
import { useEUPStore } from "@/stores/eup-store";
import { onMounted, computed, ref } from "vue";
import { getEndUserPortalHost, getCacheUserPortalSettings } from '@/service/EndUserPortalService';
import LanguageService from '@/service/LanguageService';

export default {
    setup() {
        const eupStore = useEUPStore();
        const isEUP = ref(false);
        const settings = ref(null);
        const isLoading = ref(false);
        const languageService = ref(null);
        const version = process.env.VUE_APP_VERSION ?? "v1.0.0";

        onMounted(() => {
            // by default set the eup settings in state
            useEUPStore().setEUPSettings();
            isEUP.value = getEndUserPortalHost() ? true : false;
            checkPortalSettings();

            isLoading.value = true;

            languageService.value = new LanguageService();
        });

        let catchAttempts = 0;

        const checkPortalSettings = () => {
            isLoading.value = true;

            getCacheUserPortalSettings()
                .then((res) => {
                    settings.value = res;
                    isLoading.value = false;
                })
                .catch((error) => {
                    catchAttempts++;

                    if (catchAttempts < 3) {
                        checkPortalSettings();
                    }
                });
        };

        const changeLanguage = async (language) => {
            languageService.value.setSelectedLanguage(language);
            await languageService.value.callApiTranslation();
            location.reload();
        }

        const getImage = (image) => {
            return `url('${image}') no-repeat center`;
        };

        return {
            t,
            changeLanguage,
            getImage,
            isEUP,
            settings,
            version
        }
    }
}
</script>
<style scoped>
.eup-locked-text-color {
    color: #8a1818;
}

.large-logo-cover {
    object-fit: cover;
    width: 50%;
}
</style>
