<template>
    <EmailOTP
        v-if='showEmailOtp'
        :heading='emailOtpProps.heading'
        :subheading='emailOtpProps.subheading'
        :requestTokenApi='emailOtpProps.requestTokenApi'
        :validateTokenApi='emailOtpProps.validateTokenApi'
        :resendTimer='emailOtpProps.resendTimer'
        :resendTimerInMinutes='emailOtpProps.resendTimerInMinutes'
        @submitVerificationCodeEventCallback='submitVerificationCodeEventCallbackEmail'
        :hideVerifyCodeButton="hideVerifyCodeButton"
        :hideInputBox="hideInputBox"
        :automaticSendToken="automaticSendToken"
        :hideResendTimer="hideResendTimer"
        :overrideTokenRequestId="overrideTokenRequestId"
    ></EmailOTP>

    <SmsOTP
        v-if='showSmsOtp'
        :heading='smsOtpProps.heading'
        :subheading='smsOtpProps.subheading'
        :requestTokenApi='smsOtpProps.requestTokenApi'
        :validateTokenApi='smsOtpProps.validateTokenApi'
        :resendTimer='smsOtpProps.resendTimer'
        :resendTimerInMinutes='smsOtpProps.resendTimerInMinutes'
        @submitVerificationCodeEventCallback='submitVerificationCodeEventCallbackSms'
        :hideVerifyCodeButton="hideVerifyCodeButton"
        :hideInputBox="hideInputBox"
        :automaticSendToken="automaticSendToken"
        :hideResendTimer="hideResendTimer"
    ></SmsOTP>
</template>

<script>

import t from '@/service/Translate';
import EmailOTP from '@/components/otp/EmailOTP.vue';
import SmsOTP from '@/components/otp/SmsOTP.vue';

export default {
    components: { EmailOTP, SmsOTP },
    emits: ['submitVerificationCodeEventCallbackEmail', 'submitVerificationCodeEventCallbackSms'],
    props: {
        showEmailOtp: {
            type: Boolean,
            default: false,
        },
        showSmsOtp: {
            type: Boolean,
            default: false,
        },
        emailOtpProps: {
            type: Object,
            default() {
                return {
                    heading: 'Email default otp heading',
                    subheading: 'Email default otp subheading',
                    validateTokenApi: '/user/authentication/email/verify',
                    requestTokenApi: '/user/authentication/email/send',
                    resendTimer: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER,
                    resendTimerInMinutes: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES
                };
            },
        },
        smsOtpProps: {
            type: Object,
            default() {
                return {
                    heading: 'Sms default otp heading',
                    subheading: 'sms default otp subheading',
                    validateTokenApi: '/user/authentication/mobile/verify',
                    requestTokenApi: '/user/authentication/mobile/send',
                    resendTimer: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER,
                    resendTimerInMinutes: process.env.VUE_APP_AUTH_BY_EMAIL_RESEND_TIMER_IN_MINUTES
                };
            },
        },
        hideVerifyCodeButton : {
            type: Boolean,
            default : false
        },
        hideInputBox : {
            type: Boolean,
            default : false
        },
        hideResendTimer : {
            type: Boolean,
            default : false
        },
        automaticSendToken : {
            type : Boolean,
            default : true
        }
    },

    setup(props, { emit }) {
        function submitVerificationCodeEventCallbackEmail(event, isSuccess, data) {
            emit('submitVerificationCodeEventCallbackEmail', event, isSuccess, data);
        }

        function submitVerificationCodeEventCallbackSms(event, isSuccess, data) {
            emit('submitVerificationCodeEventCallbackSms', event, isSuccess, data);
        }

        return {
            t,

            /**
             * Variables
             */

            /**
             * Functions
             */
            submitVerificationCodeEventCallbackEmail,
            submitVerificationCodeEventCallbackSms

        };
    },
};
</script>
