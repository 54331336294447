export default [
    {
        path: '/user-portal/create/:stage',
        name: 'portal.create',
        exact: true,
        component: () => import('@/pages/portal/Create/Index'),
        meta: {
            auth: true,
            permission: ['user-portal.create']
        },
    },
    {
        path: '/user-portal/',
        name: 'User Portal List',
        exact: true,
        component: () => import('@/pages/portal/Index'),
        meta: {
            auth: true,
            permission: ['user-portal.access']
        },
    },
    {
        path: '/user-portal/:id',
        name: 'User Portal View',
        exact: true,
        component: () => import('@/pages/portal/View'),
        meta: {
            auth: true,
            permission: ['user-portal.view']
        },
    },
    {
        path: '/user-portal/edit/look-and-feel/:id',
        name: 'User Portal Edit Look and Feel',
        exact: true,
        component: () => import('@/pages/portal/Edit/EditLookAndFeel.vue'),
        meta: {
            auth: true,
            permission: ['user-portal.edit'],
        },
    },
    {
        path: '/user-portal/edit/:id',
        name: 'User Portal Edit',
        exact: true,
        component: () => import('@/pages/portal/Edit/EditUserPortal'),
        meta: {
            auth: true,
            permission: ['user-portal.edit']
        },
    },
];
