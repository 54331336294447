<template>
    <div class="pb-1 niw-input-wrapper" :class="{ 'debug-mode': debug }" @mouseenter="handleDebugMode(true)"
         @mouseleave="handleDebugMode(false)">
        <div v-if="debug && debugPanel" class="px-1 text-white font-bold cursor-pointer inline-block static"
             style="background-color: red; float: right; font-size: 8px"
             @click="debugLogData(JSON.parse(JSON.stringify($parent)))">LOG PROPS
        </div>
        <div class="font-bold mb-1" v-if="!labelFloat && label">
            <label
                class="niw-input-wrapper-label"
                :for="$attrs.id"
                :class="labelClass"
            >
                {{ label }}
                <span v-if="required"></span>
            </label>
            <template v-if="iconPosition == 'label'">
                <i v-if="rightIcon && !helpLink" class="ml-3 pi" :class="rightIcon" @click="handleRightIconClick"/>
                <i v-if="!helpLink && helpDescription" class="ml-3 cursor-pointer" :class="helpIconClass"
                   v-tooltip="helpDescription"/>
                <i v-if="helpLink && !helpDescription" class="ml-3 cursor-pointer" :class="helpIconClass"
                   @click="handleRightIconClick"/>
                <i v-if="helpLink && helpDescription" class="ml-3 cursor-pointer" :class="helpIconClass"
                   @click="handleRightIconClick" v-tooltip="helpDescription"/>
            </template>
        </div>
        <div class="flex align-items-center mb-2">

            <i v-if="leftIcon && leftIconOut" class="pi mr-2" :class="leftIcon"/>

            <div :class="[inputIconClass, labelFloatClass]" class="w-full">
                <i v-if="leftIcon && !leftIconOut" class="pi" :class="leftIcon"/>

                <slot name="input"></slot>

                <label v-if="labelFloat" :for="$attrs.id"
                       :class="[{ 'float-label-placeholder-offset': $attrs.placeholder || modelValue }, labelClass]">{{ label
                    }} <span class="nimbus-text-red line-height-0" v-if="required">●</span></label>

                <template v-if="iconPosition == 'input' && !rightIconOut">
                    <i v-if="rightIcon && !helpLink" class="pi" :class="rightIcon" @click="handleRightIconClick"/>
                    <i v-if="!helpLink && helpDescription" class="cursor-pointer" :class="helpIconClass"
                       v-tooltip="helpDescription"/>
                    <i v-if="helpLink && !helpDescription" class="cursor-pointer" :class="helpIconClass"
                       @click="handleRightIconClick"/>
                    <i v-if="helpLink && helpDescription" class="cursor-pointer" :class="helpIconClass"
                       @click="handleRightIconClick" v-tooltip="helpDescription"/>
                </template>
            </div>
            <template v-if="iconPosition == 'input' && rightIconOut">
                <i v-if="rightIcon && !helpLink" class="ml-2 pi" :class="rightIcon" @click="handleRightIconClick"/>
                <i v-if="!helpLink && helpDescription" class="ml-2 cursor-pointer" :class="helpIconClass"
                   v-tooltip="helpDescription"/>
                <i v-if="helpLink && !helpDescription" class="ml-2 cursor-pointer" :class="helpIconClass"
                   @click="handleRightIconClick"/>
                <i v-if="helpLink && helpDescription" class="ml-2 cursor-pointer" :class="helpIconClass"
                   @click="handleRightIconClick" v-tooltip="helpDescription"/>
            </template>
        </div>

        <div v-for="(message, index) in inputBottomMessages" :key="index">
            <div v-for="(description, index) in stringToArray($props[message.prop])"
                 class="flex align-items-center mb-1" :class="message.color" :key="index">
                <span v-if="!noMessageIcon"><i class="pi mr-1" :class="message.icon"></i></span>
                <small v-html="description"></small>
            </div>
        </div>

        <slot name="bottom"/>

        <div v-if="showHelp" class="help-card">
            <div class="mb-2">
                {{ helpDescription }}
            </div>
            <div>
                <a class="p-button p-button-sm p-component p-button-outlined mr-5 bg-white" type="button"
                   target="_blank" :href="helpLink">
                    <span class="p-button-label">{{ helpLinkButtonLabel }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
// CONFLUENCE https://resources.emersion.com/display/DEV/Nimbus+-+PrimeVue+Component+Usage#NimbusPrimeVueComponentUsage-NimbusInputWrapper
import { computed, ref } from 'vue';

export default {
    name: 'NimbusInputWrapper',
    props: {
        /**
         * Label that will appear on fixed/float
         */
        label: {
            type: String,
        },
        labelClass: {
            type: String,
        },
        /**
         * Label position
         */
        labelFloat: {
            type: Boolean,
            default: false,
        },
        /**
         * Left icon class
         */
        leftIcon: {
            type: String,
            default: null,
        },
        /**
         * Disable left icon
         */
        leftIconDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Right icon class
         */
        rightIcon: {
            type: String,
            default: null,
        },
        /**
         * Redirect URL when clicking help-icon
         */
        helpLink: {
            type: String,
            default: null,
        },
        /**
         * Text appearing on help tooltip/dialog
         */
        helpDescription: {
            type: String,
            default: null,
        },
        /**
         * Help icon class
         */
        helpIconClass: {
            type: String,
            default: 'pi pi-question-circle',
        },
        /**
         * Button label on help dialog
         */
        helpLinkButtonLabel: {
            type: String,
            default: 'Learn More',
        },
        /**
         * Hide/show red asterisk
         */
        required: {
            type: Boolean,
            default: false,
        },
        /**
         * Default message appearing under input with pi-info-circle icon [No Custom Style]
         */
        defaultMessage: {
            type: [String, Array],
            required: false,
        },
        /**
         * Blue message appearing under input with pi-info-circle icon
         */
        infoMessage: {
            type: [String, Array],
            required: false,
        },
        /**
         * Red message appearing under input with pi-exclamation-circle icon
         */
        errorMessage: {
            type: [String, Array],
            required: false,
        },
        /**
         * Orange message appearing under input with pi-warning-circle icon
         */
        warningMessage: {
            type: [String, Array],
            required: false,
        },
        /**
         * Green message appearing under input with pi-check-circle icon
         */
        successMessage: {
            type: [String, Array],
            required: false,
        },
        /**
         * Hide messages icon
         */
        noMessageIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * V-model
         */
        modelValue: {
            type: [String, Number, Array, Object, Boolean, Date],
            default: null,
        },
        /**
         * Position right-icon outside input
         */
        rightIconOut: {
            type: Boolean,
            default: false,
        },

        leftIconOut: {
            type: Boolean,
            default: false,
        },
        /**
         * Position of icon
         */
        iconPosition: {
            type: String,
            default: 'input',
        },
    },
    emits: ['rightIconClick'],
    setup(props, {emit}) {
        const showHelp = ref(false);

        const debugPanel = ref(false);
        /**
         * Message type that appears under the input
         * Note: Order of items has relevance on appearance
         */
        const inputBottomMessages = ref([
            {
                prop: 'infoMessage',
                icon: 'pi-info-circle',
                color: 'text-blue-500',
                border: 'niw-info'
            },
            {
                prop: 'successMessage',
                icon: 'pi-check-circle',
                color: 'text-green-500',
                border: 'niw-success'
            },
            {
                prop: 'warningMessage',
                icon: 'pi-exclamation-triangle',
                color: 'text-orange-500',
                border: 'niw-warning'
            },
            {
                prop: 'errorMessage',
                icon: 'pi-exclamation-circle',
                color: 'p-error',
                border: 'p-invalid',
            },
            {
                prop: 'defaultMessage',
                icon: 'pi-info-circle'
            },
        ]);

        /**
         * Emit 'rightIconClick' for hooking
         * Redirects to help-link if only help-link is configured
         * Opens help-dialog if description if configured
         *
         * @param event
         */
        const handleRightIconClick = (event) => {
            emit('rightIconClick', event);

            if (props.helpLink && !props.helpDescription) {
                window.open(props.helpLink);
            } else {
                showHelp.value = !showHelp.value;
            }
        };

        /**
         * Input border color class
         */
        const inputBorderColorClass = computed(() => {
            let color = null;

            inputBottomMessages.value.forEach((message) => {
                if (props[message.prop] != null && props[message.prop] !== '') {
                    color = message?.border ?? message?.color;
                }
            });

            return color;
        });

        /**
         * Input wrapper class depending on icons available
         */
        const inputIconClass = computed(() => {
            let classes = ['block'];

            if (!props.leftIconDisabled && props.leftIcon != null) {
                classes.push('p-input-icon-left');
            }

            if (props.rightIcon != null || props.helpLink != null || props.helpDescription != null) {
                classes.push('p-input-icon-right');
            }

            return classes;
        });

        /**
         * Class for floating label
         */
        const labelFloatClass = computed(() => {
            return props.labelFloat ? ['p-float-label'] : [];
        });

        /**
         * Check for debug mode
         *
         * @type {ComputedRef<string>}
         */
        const debug = computed(() => {
            return localStorage.getItem('niw-debug');
        });

        /**
         * handle debug panel visibility
         *
         * @param value
         */
        const handleDebugMode = (value) => {
            if (!debug.value) {
                return;
            }
            debugPanel.value = value;
        };

        /**
         * Log props
         *
         * @param value
         */
        const debugLogData = (value) => {
            console.log(value);
        };
        /**
         * Wrap string inside an array
         *
         * @param string
         * @returns {*[]|*}
         */
        const stringToArray = (string) => {
            if (!string) {
                return [];
            }

            if (Array.isArray(string)) {
                return string;
            }

            return [string];
        };

        return {
            /**
             * Data
             */
            inputIconClass,
            labelFloatClass,
            inputBottomMessages,
            inputBorderColorClass,
            showHelp,
            debug,
            debugPanel,

            /**
             * Function
             */
            stringToArray,
            handleRightIconClick,
            handleDebugMode,
            debugLogData,
        };
    },
};
</script>

<style>
/*.niw-input-wrapper {
    min-width: 20rem;
}*/

.help-card {
    background: #f5f9ff;
    box-shadow: none;
    color: #326fd1;
    padding: 15px;
    border-radius: 5px;
}

.float-label-placeholder-offset {
    top: 0.07rem !important;
    font-size: 12px !important;
    background: #fff !important;
}

.text-blue-500.niw-dynamic-border {
    border-color: var(--blue-400);
}

.niw-info.niw-dynamic-border {
    border-color: var(--blue-400);
}

.text-green-500.niw-dynamic-border {
    border-color: var(--green-400);
}

.niw-success.niw-dynamic-border {
    border-color: var(--green-400);
}

.niw-warning.niw-dynamic-border {
    border-color: var(--orange-400);
}

.text-orange-500.niw-dynamic-border {
    border-color: var(--orange-400);
}

.line-height-0 {
    line-height: 0;
}

.debug-mode {
    box-sizing: content-box;
    border: 1px solid red;
}
</style>
