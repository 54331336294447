const minLength = 8;
const regexLowercase = /[a-z]/;
const regexUppercase = /[A-Z]/;
const regexNumeric = /\d/;
const regexSpecial = /[@#$%^&*\-_!+=\[\]{}|\\:’,'\.?\/`~”();]/;

/**
 * Note:
 * Parse the condition instead of using one whole regexp, as noticed that there are some inconsistencies happening,
 * i.e., some string are not properly being caught, ex. "abcde1ss", etc.
 */
export function validate(password) {
    const
        hasLowerCase = regexLowercase.test(password),
        hasUpperCase = regexUppercase.test(password),
        hasNumeric = regexNumeric.test(password),
        hasSpecial = regexSpecial.test(password);

    const countMetConditions = [hasLowerCase, hasUpperCase, hasNumeric, hasSpecial].filter(Boolean).length;

    return password.length >= minLength && countMetConditions >= 3;
}
