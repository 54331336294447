<template>
    <div>
        <div class="login-body" v-if="!isExpired">
            <div class="login-wrapper">
                <div class="login-panel">
                    <div class="forgot-password-form">
                        <h4>
                            <strong>{{ t('lbl_create_new_password', 'Create New Password') }}</strong>
                        </h4>
                        <NimbusInputPassword toggleMask :errorMessage="errors['password']" :template="true" :label="t('lbl_new_password', 'New Password')" required :placeholder="t('lbl_enter_password', 'Enter Password')" v-model="newPassword">
                            <template #footer>
                                <Divider />
                                <p class="mt-2">{{ t('lbl_suggestions', 'Suggestions') }}</p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li>{{ t('lbl_one_lowercase', 'At least one lowercase') }}</li>
                                    <li>{{ t('lbl_one_uppercase', 'At least one uppercase') }}</li>
                                    <li>{{ t('lbl_one_numeric', 'At least one numeric') }}</li>
                                    <li>{{ t('lbl_minimum_characters', 'Minimum 8 characters') }}</li>
                                </ul>
                            </template>
                        </NimbusInputPassword>
                        <NimbusInputPassword toggleMask :errorMessage="errors['password_confirmation']" :label="t('lbl_confirm_password', 'Confirm Password')" required :placeholder="t('lbl_confirm_password', 'Confirm Password')" v-model="confirmPassword" />
                        <button class="p-button mt-3" @click="savePassword()">
                            <span>{{ t('lbl_save_password', 'Save Password') }}</span>
                        </button>
                    </div>
                </div>

                <login-image></login-image>

                <Dialog v-model:visible="showDialog" :closable="false" :modal="true" :style="{ width: '25vw' }">
                    <div class="confirmation-content">
                        <i class="pi pi-thumbs-up" style="font-size: 5rem; color: green"></i>
                        <h2>{{ t('lbl_create_password_header', 'New password saved') }}</h2>
                        <label>{{ t('lbl_create_password_message', 'You can now use your new password on your next log in') }}</label>
                        <button class="p-button p-button-outlined p-button-secondary mt-3" @click="goToYourAccount()">
                            <span>{{ t('lbl_go_to_your_account', 'Go to your account') }}</span>
                        </button>
                    </div>
                </Dialog>

                <Dialog v-model:visible="showDialogFailed" :closable="false" :modal="true" :style="{ width: '25vw' }">
                    <div class="confirmation-content">
                        <i class="pi pi-thumbs-down" style="font-size: 5rem; color: red"></i>
                        <h2>{{ t('lbl_create_password_header_failed', 'New password failed to saved') }}</h2>
                        <button class="p-button p-button-outlined p-button-secondary mt-3" @click="showDialogFailed = false">
                            <span>{{ t('lbl_try_again', 'Try again') }}</span>
                        </button>
                    </div>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import t from '@/service/Translate';
import { ref, onMounted } from 'vue';
import NimbusInputPassword from '@/components/nimbus/NimbusInputPassword.vue';
import LoginImage from '@/components/login/LoginImage.vue';
import { useRoute, useRouter } from 'vue-router';
import { resetPassword, updatePassword } from '@/service/ForgotPasswordService';
import { Response, DelayConstants } from '@/service/Constants';
import UserAuthenticationService from '@/service/UserAuthenticationService';

export default {
    components: { NimbusInputPassword, LoginImage },
    setup() {
        const showDialog = ref(false);
        const showDialogFailed = ref(false);
        const route = useRoute();
        const router = useRouter();
        const newPassword = ref('');
        const confirmPassword = ref('');
        const errors = ref([]);
        const isExpired = ref(true);
        const userAuthentication = ref(new UserAuthenticationService());

        onMounted(() => {
            resetPassword(route.params.id, route.params.token).then((response) => {
                if (response.status != Response.SUCCESS) {
                    router.push('/expired');
                } else {
                    isExpired.value = false;
                }
            });
        });

        const goToYourAccount = () => {
            userAuthentication.value
                .cachePermission()
                .then((response) => {
                    setTimeout(() => {
                        router.push('/user-profile')
                    }, DelayConstants.RELOAD_DELAY_1000);
                })
        };

        const savePassword = () => {
            let payload = {
                id: route.params.id,
                token: route.params.token,
                password: newPassword.value,
                password_confirmation: confirmPassword.value,
            };

            updatePassword(payload)
                .then((response) => {
                    userAuthentication.value.setBearer(response.access_token);
                    userAuthentication.value.setTwoFactorAuthentication(Date.now())
                    
                    if (response.status) {
                        showDialog.value = true;
                    } else {
                        showDialogFailed.value = true;
                    }
                })
                .catch((error) => {
                    errors.value = error.response.data;

                    if (payload.password !== payload.password_confirmation) {
                        errors.value['password_confirmation'] = errors.value['password'];
                    }
                });
        };

        return {
            /**
             * Variables
             */
            t,
            showDialog,
            newPassword,
            confirmPassword,
            showDialogFailed,
            errors,
            isExpired,

            /**
             * Functions
             */
            savePassword,
            goToYourAccount,
        };
    },
};
</script>
<style scoped>
.forgot-password-form {
    margin-top: 50%;
    text-align: left;
}
</style>
