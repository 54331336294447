export default [
    {
        path: '/',
        name: 'ticket.access',
        exact: true,
        component: () => import('@/pages/ticketing/Main.vue'),
        meta: {
            auth: true,
            permission: ['ticket.access'],
        },
    },
    {
        path: '/ticket',
        name: 'ticket.access',
        exact: true,
        component: () => import('@/pages/ticket/Main.vue'),
        meta: {
            auth: true,
            permission: ['ticket.access'],
            title : 'All Tickets'
        },
    },
    {
        path: '/ticket/my-ticket',
        name: 'ticket.my-ticket.access',
        exact: true,
        component: () => import('@/pages/ticket/MyTicket.vue'),
        meta: {
            auth: true,
            permission: ['my-ticket.access'],
            title : 'My Tickets'
        },
    },
    {
        path: '/ticket/create',
        name: 'ticket.create ',
        exact: true,
        component: () => import('@/pages/ticket/AddTicket.vue'),
        meta: {
            auth: true,
            permission: ['ticket.create'],
        },
    },
    {
        path: '/ticket/:id',
        name: 'ticket.id.access',
        component: () => import('@/pages/ticket/View.vue'),
        meta: {
            auth: true,
            permission: ['ticket.id.access'],
        },
    },
];
