<template>
    <div class="time-log-manual-form">
        <div class="absolute text-3xl custom-pi-times-icon"
             @click="cancelFormConfirmDialog = !cancelFormConfirmDialog"
        ><i class="pi pi-times"></i></div>
        <div class="grid grid-nogutter">
            <div class="col-6 mb-3">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_log_time_against', 'Log Time Against') }}</h5>
                <label><a :href="`#/ticket/${ticketDetails.id}`">Ticket No. {{ ticketDetails.id }}</a></label>
            </div>
            <div class="col-6 mb-5">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_account_name', 'Account Name') }}</h5>
                <label>{{ ticketDetails.accountName }}</label>
            </div>
            <div class="col-12 mb-5">
                <h5 class="text-base font-bold mb-1">{{ t('lbl_subject', 'Subject') }}</h5>
                <label>{{ ticketDetails.subject }}</label>
            </div>

            <div class="col-3 mt-3" v-can="'ticket.time-log.create.others'">
                <NimbusDropdown
                    class="min-w-full"
                    :options="accounts"
                    optionLabel="full_name_id"
                    optionValue="id"
                    :label="t('lbl_owned_by', 'Owned By')"
                    v-model="formInputs.owned_by"
                    :filter="true"
                    required
                    panelClass="time-log-options-panel-manual"
                >
                </NimbusDropdown>
            </div>

            <div class="col-4">
                <div class="flex flex-wrap justify-content-start card-container ml-4 gap-1" style="margin-top:0.9rem">
                    <NimbusFlatCard class='flex'>
                        <Content :label-text="t('lbl_time_zone', 'Time Zone')" layout='column'>
                            <template #content>
                                <div class="pt-2">
                                    <p class='text-gray-600'>{{ userStore.getUserTimezoneDisplay }}</p>
                                </div>
                            </template>
                        </Content>
                    </NimbusFlatCard>
                </div>
            </div>

            <div class="col-12 mt-2">
                <!-- DataTable Form -->
                <nimbus-data-table
                    ref="timeLogManualFormDataTable"
                    :rowFormRequestArray="requestFormModal"
                    :requestModelProps="formModal"
                    :isDTForm="true"
                    :paginatorProps="false"
                    :showHeaderContainerProps="false"
                    :showFooter="true"
                    :clearAllProps="false"
                    :colToggleProps="false"
                    :exportProps="false"
                    :api="listApi"
                    filterMode="menu"
                    :selectMultiple="false"
                    :searchTooltipProps="''"
                    :sortDefaultProps="sortDefault"
                    :permissionProps="'ticket.time-log.create.own'"
                    mode="create"
                    :tableName="dtName"
                    :showFormSave="false"
                    :displayAddMoreRows="false"
                    v-if="manualFormDataTable"
                >
                    <template #form-date-slot="slotProps">
                        <NimbusCalendar
                            v-if="slotProps.column.field === 'start_time'"
                            class="min-w-0 custom-date-width"
                            :placeholder="nimbusDefaultDateTimeFormat"
                            v-model="slotProps.items[slotProps.rowCount].start_time"
                            :manual-input="false"
                            :show-time="true"
                            @date-select="calculateRowLoggedTimeViaDate($event, slotProps.rowCount)"
                            :errorMessage="errors[`time_logs.${slotProps.rowCount}.start_time`]"
                            :class="{ 'p-invalid': errors[`time_logs.${slotProps.rowCount}.start_time`] }"/>

                        <NimbusCalendar
                            v-if="slotProps.column.field === 'end_time'"
                            class="min-w-0 custom-date-width"
                            :placeholder="nimbusDefaultDateTimeFormat"
                            v-model="slotProps.items[slotProps.rowCount].end_time"
                            :manual-input="false"
                            :show-time="true"
                            @date-select="calculateRowLoggedTimeViaDate($event, slotProps.rowCount, 'end_time')"
                            :errorMessage="errors[`time_logs.${slotProps.rowCount}.end_time`]"
                            :class="{ 'p-invalid': errors[`time_logs.${slotProps.rowCount}.end_time`] }"/>
                    </template>

                    <template #form-text-slot="slotProps">
                        <NimbusInputText
                            v-if="slotProps.column.field === 'hours'"
                            class="min-w-0 custom-text-width"
                            v-model="slotProps.items[slotProps.rowCount].hours"
                            placeholder="0"
                            @keypress="numbersOnly"
                            @blur="calculateRowLoggedTime($event, slotProps.rowCount)"
                            :class="{ 'p-invalid': errors[`time_logs.${slotProps.rowCount}.error_logged`] }"
                        />

                        <NimbusInputText
                            v-if="slotProps.column.field === 'minutes'"
                            class="min-w-0 custom-text-width"
                            placeholder="0"
                            v-model="slotProps.items[slotProps.rowCount].minutes"
                            @keypress="numbersOnly"
                            @blur="calculateRowLoggedTime($event, slotProps.rowCount)"
                            :class="{ 'p-invalid': errors[`time_logs.${slotProps.rowCount}.error_logged`] }"
                        />
                        <div class="relative"
                             v-if="slotProps.column.field === 'hours' && errors[`time_logs.${slotProps.rowCount}.error_logged`]"
                             style="margin-bottom:35px;">
                            <div class="flex align-items-baseline mb-1 p-error absolute custom-error-hours">
                                <span><i class="pi mr-1 pi-exclamation-circle"></i></span>
                                <small>{{ t('msg_error_hours_minutes', 'Enter values for Hours and/or Minutes.')
                                    }}</small>
                            </div>
                        </div>
                    </template>

                    <template #form-dropdown-slot="slotProps">
                        <NimbusDropdown
                            v-if="slotProps.column.field === 'billable_type'"
                            class="p-0 custom-dropdown-width"
                            v-model="slotProps.items[slotProps.rowCount].billable_type"
                            :options="billableTypes"
                            optionLabel="name"
                            optionValue="id"
                            :filter="true"
                            :showClear="true"
                            :placeholder="t('lbl_time_log_billable_type', 'Time Log Type')"
                            :errorMessage="errors[`time_logs.${slotProps.rowCount}.billable_type`]"
                            :class="{ 'p-invalid': errors[`time_logs.${slotProps.rowCount}.billable_type`] }"
                            style="min-width: 0 !important"/>

                        <NimbusDropdown
                            v-if="slotProps.column.field === 'item'"
                            class="p-0 custom-dropdown-width"
                            v-model="slotProps.items[slotProps.rowCount].item"
                            :options="itemLabours"
                            optionLabel="name"
                            optionValue="id"
                            :filter="true"
                            :showClear="true"
                            :placeholder="t('lbl_item', 'Item')"
                            style="min-width: 0 !important"/>
                    </template>
                </nimbus-data-table>
            </div>

            <div class="col-12 mt-2">
                <NimbusTextarea
                    :label="t('lbl_internal_note', 'Internal Note')"
                    :placeholder="t('lbl_enter_internal','Enter interal note')"
                    :autoResize="false"
                    rows="3"
                    v-model.trim="formInputs.internal_note"
                    :required="true"
                    :errorMessage="errors.internal_note"
                />
            </div>

            <div class="col-12 mt-3">
                <NimbusTextarea
                    :label="t('lbl_external_note', 'External Note')"
                    :placeholder="t('lbl_enter_external_note','Enter external note')"
                    :autoResize="false"
                    rows="3"
                    v-model.trim="formInputs.external_note"
                />
            </div>

            <div class="col-12 mt-2 text-right">
                <div class="action-button">
                    <Button class="p-button-outlined p-button-secondary font-bold"
                            :label="t('btn_cancel', 'Cancel')"
                            @click="cancelFormConfirmDialog = !cancelFormConfirmDialog"/>
                    <Button class="ml-3 font-bold"
                            :label="t('btn_save_time_entry', 'Save Time Entry')"
                            @click="saveTimeLogEntry"/>
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="cancelFormConfirmDialog" :style="{ width: '19%' }"
            :modal="true"
            class="p-fluid">
        <template #header>
            <h5 class="dialog-header b text-orange-600">
                <i class="pi pi-exclamation-triangle relative text-xl" style="top:3px"></i>
                <label class="ml-2 text-xl">{{ t('lbl_warning_dialog', 'Warning') }}</label>
            </h5>
        </template>
        <div>
            <p class="text-lg">
                {{ t('msg_time_cancel_dialog', 'Are you sure you want to cancel this time log?') }}
            </p>
        </div>
        <template #footer>
            <Button class="p-button p-button-outlined p-button-secondary p-component" :label="t('btn_no','No')"
                    @click="cancelFormConfirmDialog = !cancelFormConfirmDialog"/>
            <Button class="p-mr-2" :label="t('btn_yes','Yes')"
                    @click="closeTimeTrackingForm"/>
        </template>
    </Dialog>
</template>

<script>
import {ref, toRefs, watch} from "vue";
import t from "@/service/Translate"
import {useToast} from 'primevue/usetoast';
import moment from "moment";
import {numbersOnly} from "@/service/NimbusUtilities";
import {fixedDateTimeFormat, nimbusDefaultDateTimeFormat} from "@/service/DateService";
import {
    convertNumberToSeconds,
    storeTimeLogManualEntry,
    convertTimeDurationToAsHours,
    convertTimeDurationToMinutes,
} from "@/service/TimeTrackingService";
import {useUserStore} from "@/stores/user-store";
import UserAuthenticationService from "@/service/UserAuthenticationService";
import NimbusDataTable from "@/components/NimbusDataTable";
import NimbusCalendar from "@/components/nimbus/NimbusCalendar";
import NimbusDropdown from "@/components/nimbus/NimbusDropdown";
import NimbusInputText from "@/components/nimbus/NimbusInputText";
import NimbusTextarea from "@/components/nimbus/NimbusTextarea";
import NimbusFlatCard from "@/components/nimbus/NimbusFlatCard";
import Content from '@/components/account/summary/ReadOnlyCard/Content';

export default {
    name: "ManualForm",
    emits: ['timeLogAdded', 'closeTimeTrackingForm'],
    components: {
        NimbusDataTable,
        NimbusCalendar,
        NimbusDropdown,
        NimbusInputText,
        NimbusTextarea,
        NimbusFlatCard,
        Content
    },
    props: {
        ticketID: {
            type: Number,
            default: null
        },
        accountID: {
            type: Number,
            default: new UserAuthenticationService().getAuthenticatedUserID(),
        },
        accounts: {
            type: Array,
            default: () => []
        },
        billableTypes: {
            type: Array,
            default: () => []
        },
        itemLabours: {
            type: Array,
            default: () => []
        },
        ticketDetails: {
            type: Object,
            default: () => {} 
        }
    },
    setup(props, {emit}) {
        const userStore = useUserStore();
        const toast = useToast();
        const {ticketID, billableTypes, accountID} = toRefs(props);
        const formInputs = ref({
            ticket_id: '',
            owned_by: accountID.value.toString(),
            external_note: '',
            internal_note: ''
        })
        const errors = ref([]);
        const cancelFormConfirmDialog = ref(false);

        /**
         * DataTable Const
         */
        const manualFormDataTable = ref(true);
        const timeLogManualFormDataTable = ref(null);
        const formModal = ref([
            {
                start_time: null,
                end_time: null,
                hours: null,
                minutes: null,
                billable_type: null,
                item: null,
                total_logged_time: 0
            }
        ])
        const requestFormModal = ref(formModal.value);
        const dtName = ref('manual_time_log_dt_form');
        const listApi = ref('api/v1/time-log/form/manual');
        const sortDefault = ref({
            field: 'id',
            sort: 'asc',
        });

        const defaultBillableType = ref(1); // by default is non-billable for each rows

        // save time log entry
        const saveTimeLogEntry = async () => {
            try {
                /**
                 * We need to loop each data so we can format the date structured
                 */
                let time_logs = [];
                await requestFormModal.value.forEach((value, index) => {
                    time_logs.push({
                        start_time: (requestFormModal.value[index].start_time) ? fixedDateTimeFormat(requestFormModal.value[index].start_time) : '',
                        end_time: (requestFormModal.value[index].end_time) ? fixedDateTimeFormat(requestFormModal.value[index].end_time) : '',
                        hours: requestFormModal.value[index].hours,
                        minutes: requestFormModal.value[index].minutes,
                        billable_type: requestFormModal.value[index].billable_type,
                        item: requestFormModal.value[index].item,
                        total_logged_time: requestFormModal.value[index].total_logged_time
                    })
                })

                const payloads = {
                    is_manual_form: true,
                    ticket_id: parseInt(ticketID.value),
                    owned_by: formInputs.value.owned_by,
                    external_note: formInputs.value.external_note,
                    internal_note: formInputs.value.internal_note,
                    time_logs: time_logs
                }

                await storeTimeLogManualEntry(payloads);
                await emit('timeLogAdded', payloads);

                // show notifications
                let details = t('msg_new_time_added', 'A new time entry for') + ` ${ticketID.value} ` + t('msg_new_time_is_saved', 'is saved.');
                toast.add({
                    severity: 'success',
                    summary: t('msg_success_time_entry', 'Time Entry Added'),
                    detail: details,
                    life: 3000,
                });

                //clear the forms
                clearForms();
            } catch (error) {
                errors.value = error.response.data.errors;

                /**
                 * Custom error for hours/minutes section
                 */
                requestFormModal.value.forEach((value, index) => {
                    errors.value[[`time_logs.${index}.error_logged`]] = null;
                    if (requestFormModal.value[index].hours == null
                        || requestFormModal.value[index].minutes == null) {
                        errors.value[[`time_logs.${index}.error_logged`]] = [t('msg_error_hours_minutes', 'Enter values for Hours and/or Minutes.')]
                    }
                })

                toast.add({
                    severity: 'error',
                    summary: t('msg_error', 'Error'),
                    detail: t('msg_error_details', 'Please check for required fields and try again.'),
                    life: 3000,
                });
            }
        }

        /**
         * Function for calculating hours/minutes based on the start_time and end_time
         *
         * @param event
         * @param index
         * @param dataType
         */
        const calculateRowLoggedTimeViaDate = (event, index, dataType = 'start_time') => {
            if (dataType === 'start_time') {
                requestFormModal.value[index].start_time = event;
            } else {
                requestFormModal.value[index].end_time = event;
            }

            if (requestFormModal.value[index].start_time
                && requestFormModal.value[index].end_time) {

                let start_time = fixedDateTimeFormat(requestFormModal.value[index].start_time);
                let end_time = fixedDateTimeFormat(requestFormModal.value[index].end_time);

                errors.value[[`time_logs.${index}.end_time`]] = null;
                if (moment(end_time).isBefore(start_time)) {
                    errors.value[[`time_logs.${index}.end_time`]] = ['End date and time must be greater than Start date and time']
                    return;
                }

                let totalSecondsDiff = moment(end_time).diff(start_time, 'seconds');

                parseLoggedTimeValues(
                    index,
                    'date',
                    totalSecondsDiff
                );
            }
        }

        /**
         * Function for hours/minutes input box
         *
         * @param event
         * @param index
         */
        const calculateRowLoggedTime = (event, index) => {
            parseLoggedTimeValues(
                index,
                'input',
                requestFormModal.value[index].hours,
                requestFormModal.value[index].minutes
            );
        }

        /**
         * Calculate Logged Time
         *
         * @param index
         * @param event_type
         * @param hoursIndexField
         * @param minutesIndexField
         */
        const parseLoggedTimeValues = (index, event_type, hoursIndexField = 0, minutesIndexField = 0) => {
            let loggedHours = 0;
            let loggedMinutes = 0;
            let loggedIndexTotalTime = 0;

            if (event_type === 'input') {
                // calculate total time for both fields
                if (hoursIndexField) {
                    loggedHours = convertNumberToSeconds(hoursIndexField, 'Hour');
                }

                if (minutesIndexField) {
                    loggedMinutes = convertNumberToSeconds(minutesIndexField, 'Minute');
                }
                loggedIndexTotalTime = loggedHours + loggedMinutes;

                // apply the date in calendar
                let startDateTime = requestFormModal.value[index].start_time;
                if (!startDateTime) {
                    // set the date in current time
                    startDateTime = moment().format();
                    requestFormModal.value[index].start_time = moment(startDateTime).format(nimbusDefaultDateTimeFormat)
                }
                requestFormModal.value[index].end_time = moment(startDateTime).add(loggedIndexTotalTime, 'seconds').format(nimbusDefaultDateTimeFormat)
            } else {
                loggedIndexTotalTime = hoursIndexField;
            }

            // once total_time has been calculated, format the display value for hours/minutes
            let formattedHours = Math.floor(convertTimeDurationToAsHours(loggedIndexTotalTime));
            requestFormModal.value[index].hours = (formattedHours > 0) ? formattedHours : '';

            let formattedMinutes = Math.floor(convertTimeDurationToMinutes(loggedIndexTotalTime));
            requestFormModal.value[index].minutes = (formattedMinutes > 0) ? formattedMinutes : '';

            // insert the value for the total logged time
            requestFormModal.value[index].total_logged_time = loggedIndexTotalTime;
        }

        /**
         * Clear the whole manual form
         */
        const clearForms = () => {
            formInputs.value.external_note = '';
            formInputs.value.internal_note = '';

            errors.value = [];

            //reload data table
            reloadManualFormDataTable();
        }

        // reload manual form data table
        const reloadManualFormDataTable = () => {
            manualFormDataTable.value = false;
            setTimeout(() => {
                manualFormDataTable.value = true;
            }, 500);
        }

        /**
         * Close the Form
         */
        const closeTimeTrackingForm = () => {
            // clear form first
            clearForms();

            toast.add({
                severity: 'success',
                summary: t('msg_time_cancelled', 'Time Entry Cancelled'),
                detail: '',
                life: 3000,
            });

            emit('closeTimeTrackingForm');
        }

        // watch rows count and set the default billable type
        watch(() => timeLogManualFormDataTable.value?.rows, (table) => {
            if (table.length > 0) {
                requestFormModal.value[table.length - 1].start_time = moment().format(nimbusDefaultDateTimeFormat);
                if (billableTypes.value.length > 0) {
                    requestFormModal.value[table.length - 1].billable_type = defaultBillableType.value;
                }
            }
        })

        return {
            /**
             * Constants
             */
            t,
            userStore,
            formInputs,
            errors,
            cancelFormConfirmDialog,
            nimbusDefaultDateTimeFormat,
            /**
             * DataTable
             */
            manualFormDataTable,
            timeLogManualFormDataTable,
            formModal,
            requestFormModal,
            dtName,
            listApi,
            sortDefault,
            /**
             * Functions
             */
            saveTimeLogEntry,
            numbersOnly,
            calculateRowLoggedTimeViaDate,
            calculateRowLoggedTime,
            closeTimeTrackingForm,
            clearForms
        }
    }
}
</script>

<style>
.time-log-manual-form .p-datatable-footer {
    border: 0 !important;
    background-color: #FFFFFF !important;
}

/** Align the content on top **/
.time-log-manual-form .p-datatable-tbody > tr > td {
    vertical-align: top;
}
</style>

<style scoped>
.time-log-manual-form .custom-pi-times-icon {
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.time-log-manual-form .custom-date-width {
    width: 192px !important
}

.time-log-manual-form .custom-text-width {
    width: 68px !important
}

.time-log-manual-form .custom-dropdown-width {
    width: 150px !important
}

/** Custom error message for hours **/
.time-log-manual-form .custom-error-hours {
    min-width: 168px;
    display: block !important;
    top: -3px;
}
</style>

