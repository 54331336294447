export default [
    {
        path: '/forgot-password',
        name: 'user.forgot.password',
        exact: true,
        component: () => import('@/pages/forgot_password/Index.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/create-new-password/:id/:token',
        name: 'user.create.password',
        exact: true,
        component: () => import('@/pages/forgot_password/CreateNewPassword.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/expired',
        name: 'user.link.expired',
        exact: true,
        component: () => import('@/pages/forgot_password/ExpiredLink.vue'),
        meta: {
            auth: false,
        },
    }
];