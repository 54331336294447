export default [
    {
        path: '/role',
        name: 'role.access',
        exact: true,
        component: () => import('@/pages/security/role/Main.vue'),
        meta: {
            auth: true,
            permission: ['role.access'],
        },
    },
    {
        path: '/role/create',
        name: 'role.create',
        component: () => import('@/pages/security/role/Create.vue'),
        meta: {
            auth: true,
            permission: ['role.create'],
        },
    },
    {
        path: '/role/edit/:id',
        name: 'role.edit',
        component: () => import('@/pages/security/role/Edit.vue'),
        meta: {
            auth: true,
            permission: ['role.edit'],
        },
    },
    {
        path: '/role/:id',
        name: 'role.id.access',
        exact: true,
        component: () => import('@/pages/security/role/Single.vue'),
        meta: {
            auth: true,
            permission: ['role.view'],
            wrapped: true,
        },
    },
    {
        path: '/role/:id/user',
        name: 'role.id.user.access',
        exact: true,
        component: () => import('@/pages/security/role/AccountList.vue'),
        meta: {
            auth: true,
            permission: ['role.id.user.access'],
            wrapped: true,
        },
    },
    {
        path: '/role-group',
        name: 'Role Group Page Listing',
        exact: true,
        component: () => import('@/pages/security/role_group/Index.vue'),
        meta: {
            auth: true,
            permission: ['role-group.access'],
        },
    },
    {
        path: '/role-group/:id',
        name: 'Role Group View Page',
        exact: true,
        component: () => import('@/pages/security/role_group/View.vue'),
        meta: {
            auth: true,
            permission: ['role-group.view'],
        },
    },
];
