export default [
    {
        path: '/ticket/queue',
        name: 'Ticket Queue Index',
        exact: true,
        component: () => import('@/pages/ticket_queue/Index.vue'),
        meta: {
            auth: true,
            permission: ['ticket.queue.access'],
        },
    },
];
