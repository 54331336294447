export default [
    {
        path: '/tax-rate/',
        name: 'Tax Rates',
        exact: true,
        component: () => import('@/pages/tax_rate/Index.vue'),
        meta: {
            auth: true,
            permission: ['tax-rate.access'],
        },
    },
    {
        path: '/tax-rate/:id',
        name: 'View Tax Rate',
        exact: true,
        component: () => import('@/pages/tax_rate/View.vue'),
        meta: {
            auth: true,
            permission: ['tax-rate.view'],
        },
    },
];
