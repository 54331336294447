export default [
    {
        path: '/ticket/category',
        name: 'Ticket Category Index',
        exact: true,
        component: () => import('@/pages/ticket_category/Index.vue'),
        meta: {
            auth: true,
            permission: ['ticket.category.access'],
        },
    },
];
