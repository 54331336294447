export default [
    {
        path: '/time-logs/:ticket_id/report',
        name: 'Time Logs Report',
        exact: true,
        component: () => import('@/pages/time_tracking/Index.vue'),
        meta: {
            auth: true,
            permission: ['ticket.time-log.access'],
        },
    },
];
