<template>
    <NimbusInputWrapper v-bind="$props" ref="wrapper" @right-icon-click="(e) => $emit('rightIconClick', e)" right-icon-out left-icon-disabled>
        <template #input>
            <!-- v-tooltip directive throws error when given a null value -->
            <span v-if="tooltip" v-tooltip="tooltip">
                <Calendar
                    v-bind="$attrs"
                    @update:modelValue="updateInput"
                    :inputClass="[inputBorderColorClass, inputClass, 'niw-dynamic-border', inputBorderColorClass ? '' : 'border-right-none']"
                    :modelValue="modelValue"
                    class="w-full"
                    :dateFormat="dateFormat"
                    :showIcon="showIcon"
                    icon="pi pi-calendar"
                    :selection-mode="selectionMode"
                    :view="view"
                />
            </span>
            <!-- render component with no v-tooltip directive if tooltip is null -->
            <Calendar
                v-else
                v-bind="$attrs"
                @update:modelValue="updateInput"
                :inputClass="[inputBorderColorClass, inputClass, 'niw-dynamic-border', inputBorderColorClass ? '' : 'border-right-none']"
                :modelValue="modelValue"
                class="w-full"
                :dateFormat="dateFormat"
                :showIcon="showIcon"
                icon="pi pi-calendar"
                :selection-mode="selectionMode"
                :view="view"
            />
        </template>
        <template #bottom>
            <slot></slot>
        </template>
    </NimbusInputWrapper>
</template>

<script>
import Calendar from 'primevue/calendar';
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';

import { onMounted, ref } from 'vue';

export default {
    name: 'NimbusCalendar',
    components: {
        NimbusInputWrapper,
        Calendar,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: [String, Date],
        },
        tooltip: {
            type: String,
            default: null,
        },
        dateFormat: {
            type: String,
            default: 'd M yy',
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        selectionMode: {
            type: String,
            default: 'single',
        },
        view: {
            type: String,
            default: 'date', // month | year | date
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);
        const inputBorderColorClass = ref(null);

        onMounted(() => {
            inputBorderColorClass.value = wrapper.value.inputBorderColorClass;
        });

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = (event) => {
            emit('update:modelValue', event);
        };

        return {
            /**
             * Data
             */
            inputBorderColorClass,
            wrapper,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>

<style>
.p-calendar .p-button,
.p-calendar .p-button:enabled:hover {
    background-color: #ffffff !important;
    border: 1px solid #ced4da;
    color: #000000;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-left: 0;
}
</style>
