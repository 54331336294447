import * as api from '@/core/apis';

export async function getDetailsViaToken(token) {
    return await api.get(`/account/verify/${token}`);
}

export async function saveVerifiedAccount(token, password) {
    return await api.post(`/account/verify/${token}`, {
        password: password,
    });
}

export async function sendVerificationEmail (userId, accountId) {
    return await api.post('/account/email-verification', {
        user_id: userId,
        account_id: accountId,
    });
}
