export default class NotificationService {
    /**
     * Trigger the toast
     *
     * @param context
     * @param severity
     * @param summary
     * @param message
     * @param duration
     * @param position
     * @param styleClass
     * @param contentStyleClass
     */
    fire(context, severity, summary, message, duration, position, styleClass,contentStyleClass) {

        context.add({
            severity: severity,
            summary: summary,
            detail: message,
            life: duration || 3000,
            group: position,
            styleClass : styleClass,
            contentStyleClass: contentStyleClass
        });
    }

}