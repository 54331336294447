export default [
    {
        path: '/account-list',
        name: 'Accounts',
        exact: true,
        component: () => import('@/pages/account/account-list/AccountMain'),
        meta: {
            auth: true,
            permission: ['account.access'],
            redirect: 'account.show'
        },
    },
    {
        path: '/account/security',
        name: 'Accounts Security',
        exact: true,
        component: () => import('@/pages/account/Security/Index'),
        meta: {
            auth: true,
            permission: ['account.security'],
        },
    },
    {
        path: '/account/:accountId',
        name: 'account.show',
        exact: true,
        component: () => import('@/pages/account/View'),
        meta: {
            auth: true,
            permission: ['account.view'],
        },
    },
    {
        path: '/account/create/:stage',
        name: 'account.create',
        exact: true,
        component: () => import('@/pages/account/Create/Index'),
        meta: {
            auth: true,
            permission: ['account.create'],
        },
    },
    /**
     * This is added here for testing as permissions on route (routes/end_user_portal/index.js) is not yet ready
     */
    {
        path: '/account/account-security',
        name: 'End-User Portal - Account Security',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_security/Index'),
        meta: {
            auth: true,
            permission: ['account.access'],
        },
    },
    {
        path: '/account/account-summary',
        name: 'End-User Portal - Account Summary',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_summary/Main'),
        meta: {
            auth: true,
            permission: ['account.access'],
        },
    },
];
