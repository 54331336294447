<script setup>
import PreAuthLayout from '@/pages/end_user_portal/PreAuthLayout';
import NimbusInputPassword from '@/components/nimbus/NimbusInputPassword';
import { computed, onMounted, ref, watch } from 'vue';
import translate from '@/service/Translate';
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';
import { useRoute, useRouter } from 'vue-router';
import { getEndUserPortalHost } from '@/service/EndUserPortalService';
import { ACCOUNT_VERIFICATION, DIALOG_DETAILS } from './accountVerificationConstants';
import { getDetailsViaToken, saveVerifiedAccount, sendVerificationEmail } from '@/service/AccountVerificationService';
import { validate } from '@/service/passwordService';
import NimbusDialog from '@/components/dialog/NimbusDialog.vue';
import * as api from '@/core/apis';
import { useAutoTimer } from '@/stores/auto-timer';
import UserAuthenticationService, { getUserRealm } from '@/service/UserAuthenticationService';
import { useUserStore } from '@/stores/user-store';
import { useToast } from 'primevue/usetoast';

const
    route = useRoute(),
    router = useRouter();
const t = translate;
const toast = useToast();

const
    autoTimerStore = useAutoTimer(),
    userStore = useUserStore();

const userService = new UserAuthenticationService();

const verificationDetails = ref({});
const payload = ref({
    password: '',
    confirmPassword: '',
});

const errorPassword = ref(false);
const errorMessage = ref({
    confirmPassword: '',
});

const passwordClass = ref('');
const disableSaveBtn = ref(true);

const showDialog = ref(false);
const showDialogDetails = ref({});

const loader = ref({
    saveBtn: false,
    dialogBtn: false,
})

const arePasswordsFilled = computed(() => {
    return !!payload.value.password && !!payload.value.confirmPassword;
});

onMounted(async () => {
    await init();
});

watch(arePasswordsFilled, (newValue) => {
    disableSaveBtn.value = !newValue;
})

async function init() {
    try {
        const res = await getDetailsViaToken(route.query.token)
        verificationDetails.value = res.data;
    } catch (error) {
        if (error.response.status === 410) {
            verificationDetails.value = error.response.data.data;
            showDialogDetails.value = Object.assign(DIALOG_DETAILS.EXPIRED, { btnAction: resendEmail });
            showDialog.value = true;
        } else {
            await router.push('/404');
        }
    }
}

async function verify() {
    const { password, confirmPassword } = payload.value;

    // reset
    errorPassword.value = false;
    errorMessage.value.confirmPassword = ''
    passwordClass.value = '';

    if (!validate(password)) {
        errorPassword.value = true;
        passwordClass.value = 'p-invalid';
        return false;
    }

    if (password !== confirmPassword) {
        errorMessage.value.confirmPassword = 'Password does not match.';
        passwordClass.value = 'p-invalid';
        return false;
    }

    loader.value.saveBtn = true;
    await saveVerifiedAccount(route.query.token, password);

    loader.value.saveBtn = false;
    showDialog.value = true;
    showDialogDetails.value = Object.assign(DIALOG_DETAILS.SUCCESS, { btnAction: redirectToDashboard });
}

async function resendEmail() {
    const { user_id, account_id } = verificationDetails.value;

    loader.value.dialogBtn = true;

    try {
        await sendVerificationEmail(user_id, account_id)
        toast.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'E-mail has been re-sent.',
            life: 3000,
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to resend e-mail. Please try again later.',
            life: 3000,
        });
    } finally {
        loader.value.dialogBtn = false;
    }
}

async function redirectToDashboard() {
    loader.value.dialogBtn = true;

    try {
        const requestPayload = {
            username: verificationDetails.value.username,
            password: payload.value.password,
            grant_type: 'password',
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            scope: '',
            source: 'nimbus_end_user_portal',
            eup_nimbus_url : getEndUserPortalHost(),
        };

        const res = await api.post('login', requestPayload);

        await autoTimerStore.resetStateValues();
        await retrieveUserRealm(res.data.access_token)
        userService.setBearerSession(res.data)
    } catch (error) {
        toast.add({
            severity: 'error',
            detail: error.response.data.message,
            life: 3000,
        });
    } finally {
        loader.value.dialogBtn = false;
    }
}

async function retrieveUserRealm(token) {
    const res = await getUserRealm(token);
    const userRealm = res.data.data

    userService.setBearer(token);

    // get the timezone for user
    await userStore.getUserTimezone();

    // get account settings
    await userStore.getUserAccountSettings();
}

</script>

<template>
    <Toast />
    <PreAuthLayout class="page-account-verification">
        <div class="w-6">
            <h2 class="font-bold text-xl">{{ ACCOUNT_VERIFICATION.pageTitle }}</h2>
            <p>{{ ACCOUNT_VERIFICATION.description }}</p>

            <div class="flex flex-column gap-5 mt-5">
                <NimbusInputWrapper
                    :label="t('lbl_email', 'Email')"
                >
                    <template #input>
                        <div class="flex align-items-center">
                            <InputText
                                :placeholder="t('lbl_email', 'Email')"
                                v-model="verificationDetails.email"
                                class="w-full"
                                disabled
                            />
                            <Button
                                icon="pi pi-info-circle"
                                severity="secondary"
                                v-tooltip="'You can only change your email address after you log in. You need to create a username and password first.'"
                                size="large"
                                text
                                rounded
                            />
                        </div>
                    </template>
                </NimbusInputWrapper>

                <section>
                    <h4 class="font-bold text-xl mb-4">
                        {{ t('lbl_create_username_and_password', 'Create Username and Password') }}
                    </h4>
                    <div class="flex flex-column gap-2">
                        <NimbusInputWrapper
                            :label="t('lbl_username', 'Username')"
                            required
                        >
                            <template #input>
                                <InputText
                                    :placeholder="t('placeholder_name', 'Username')"
                                    v-model="verificationDetails.username"
                                    class="w-full"
                                    disabled
                                />
                            </template>
                        </NimbusInputWrapper>
                        <NimbusInputPassword
                            :label="t('lbl_password', 'Password')"
                            :placeholder="t('placeholder_create_password', 'Password')"
                            v-model="payload.password"
                            :template="true"
                            required
                            toggle-mask
                            :class="passwordClass"
                        >
                            <template #footer>
                                <div class="mt-5">
                                    <p>Minimum 8 characters and should have 3 out of the 4:</p>
                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                        <li>At least one lowercase</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one numeric</li>
                                        <li>At least one special character @ # $ % ^ & * - _ ! + = [] {} | \ : ’ , . ? /
                                            ` ~ ”
                                            ()
                                            ;
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </NimbusInputPassword>
                        <NimbusInputPassword
                            :label="t('lbl_confirm_password', 'Confirm Password')"
                            :placeholder="t('placeholder_confirm_password', 'Confirm Password')"
                            v-model="payload.confirmPassword"
                            :feedback="false"
                            required
                            toggle-mask
                            :class="passwordClass"
                            :error-message="errorMessage.confirmPassword"
                        />
                        <div
                            v-if="errorPassword"
                            class="text-sm flex column-gap-2 align-items-start text-danger"
                        >
                            <i class="pi pi-exclamation-circle"></i>
                            <div>
                                <p class="mb-0">Password does not meet the requirements.</p>
                                <p class="mb-0">Minimum 8 characters and should have 3 out of the 4:</p>
                                <ul class="pl-3 ml-2 mt-0">
                                    <li>At least one lowercase</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one numeric</li>
                                    <li>At least one special character @ # $ % ^ & * - _ ! + = [] {} | \ : ’ , . ? /
                                        ` ~ ” () ;
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mt-2">
                            <Button
                                :label="ACCOUNT_VERIFICATION.btnSave"
                                type="submit"
                                @click="verify"
                                :disabled="disableSaveBtn"
                                :loading="loader.saveBtn"
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </PreAuthLayout>

    <!-- Dialog -->
    <NimbusDialog
        v-model:visible="showDialog"
        :icon="showDialogDetails.icon"
        :iconSeverity="showDialogDetails.iconSeverity"
    >
        <div>
            <h5>{{ showDialogDetails.summary }}</h5>
            <p>{{ showDialogDetails.detail }}</p>
        </div>

        <template #cta>
            <Button
                :label="showDialogDetails.btnLabel"
                :loading="loader.dialogBtn"
                :severity="showDialogDetails.btnSeverity"
                :outlined="showDialogDetails.btnOutline"
                @click="showDialogDetails.btnAction"
            />
        </template>
    </NimbusDialog>
</template>
