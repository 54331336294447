import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_NIMBUS_API_URL}api/v1/`,
    withCredentials: false
})

axiosInstance.interceptors.request.use(
    config => {
        //Set the default header here
        config.headers['X-Requested-With'] = 'XMLHttpRequest'

        //Set the bearer token if exist
        const token = localStorage.getItem('nimbus-token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        //Set the translation language if exist, if not default to en
        let nimbusLanguage = localStorage.getItem('nimbus-selected-language')
        config.headers['X-Localization'] = (nimbusLanguage) ? nimbusLanguage : 'en-gb'

        return config
    },
    error => {
        return Promise.reject(error)
    })

axiosInstance.interceptors.response.use(
    response => {
        //When success return the promise data
        let data = response.data
        return Promise.resolve(data)
    },
    error => {
        //For now return the full error object
        //error.response.data - Content for the error data
        //error.response.status - Status code of the error
        //error.response.headers - Set of headers of an error
        return Promise.reject(error)
    })

export default axiosInstance