import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { logoutCurrent } from '@/service/UserService';
import moment from 'moment';
import ls from '@/stores/local-storage';
import { Prefixes } from '@/service/Constants';
import UserAuthenticationService from '@/service/UserAuthenticationService';
import { refreshToken } from '@/service/RefreshTokenService';
import { getExpiryTime, getWarningTime, getActiveTime } from '@/service/AutoLogoutService';
import { useUserStore } from '@/stores/user-store';

export const useAutoLogout = defineStore(
    'auto-logout',
    () => {
        const userStore = useUserStore();
        const router = useRouter();
        const expiryTime = ref(getExpiryTime());
        const warningTime = ref(getWarningTime());
        const timer = ref(0);
        const timerInterval = ref(null);
        const warningTimer = ref(expiryTime.value - warningTime.value);
        const warningTimerInterval = ref(warningTimer);
        const userService = new UserAuthenticationService();
        /**
         * determine how token refresh frequently
         * by default it will refresh every 10 minutes.
         */
        const activeTimer = ref(getActiveTime())
        const activeInterval = ref()
        const isUserIdle = ref(false)

        /** State to determined if the time reached the warning time */
        const isTimeWarned = ref(false);

        /** State to determined if the session is ended in idle **/
        const showIdleWarningInLogin = ref(false);

        /**
         * Set the Timer from the Account Settings
         */
        const setSettingsTimer = () => {
            expiryTime.value = userStore.getALExpirationTime();
            warningTimer.value = parseInt(expiryTime.value - warningTime.value);
            warningTimerInterval.value = warningTimer.value;
        };

        // function to refresh the values
        const staySignedIn = () => {
            refreshToken();

            ls.set('al-stay-logged-in', moment());
            ls.set('al-is-timer-warned', false);

            resetStoreValues();
            // reset active timer
            resetActiveTimerValues()
        };

        // function to request in API to clear the token and logout the user
        const logoutActiveUser = async () => {
            try {
                await logoutCurrent();
                // set the bearer token to null
                userService.setBearer(null);
                userService.removePermissions();
                userService.removeAuthenticatedUserID();
                userService.setTwoFactorAuthentication(null);

                await resetStoreValues();
                // redirect the user to login page
                await removeLSInAutoLogout();
                // reset timer values
                resetActiveTimerValues()

                router.push('/login');
            } catch (error) {
                await removeLSInAutoLogout();
                /**
                 * There are instances if multiple tabs are open, there are delay on the sharing of state for each tab
                 * So we need to force the tab to redirect in login
                 *
                 */
                router.push('/login');
                // reset timer values
                resetActiveTimerValues()
            }
        };

        // function to start the timer
        const autoStartTimer = () => {
            clearTimerInterval()
            if (timer.value <= 0) {
                timerInterval.value = window.setInterval(() => {
                    if (!userService.isAuthenticated()) {
                        clearTimerInterval();
                        resetStoreValues();

                        return;
                    }
                    // check for time warned interval
                    if (!isTimeWarned.value && warningTime.value <= timer.value) {
                        isTimeWarned.value = true;
                        isUserIdle.value = true
                        ls.set('al-is-timer-warned', true);
                        clearInterval(timerInterval.value);
                        // start the timer in warning dialog
                        return;
                    }
                    timer.value++;
                }, 1000); // interval set to one sec.
            }
        };

        // clear data related on the timer
        const resetTimer = (on_hide_event = false) => {
            // we need to check if time warning is not active
            if (!isTimeWarned.value || on_hide_event || timer.value < warningTime.value) {
                // force to clear the interval
                clearTimerInterval();

                // clear active interval
                clearActiveInterval()

                // reset the values
                resetStoreValues();

                // start the timer again
                autoStartTimer();

                setActiveTimer();
            }
        };

        // force stop the timer
        const clearTimerInterval = () => {
            clearInterval(timerInterval.value);
        };

        // reset values
        const resetStoreValues = () => {
            // reset the values
            timer.value = 0;
            warningTimer.value = expiryTime.value - warningTime.value;
            warningTimerInterval.value = warningTimer.value;
            isTimeWarned.value = false;
        };

        // function to remove persisted local storage used in auto logout
        const removeLSInAutoLogout = () => {
            const lsItems = ['al-stay-logged-in', 'al-is-timer-warned', 'al-auto-logout', 'al-warning-timer'];
            lsItems.forEach((item) => {
                if (ls.get(item)) {
                    ls.remove(item);
                }
                localStorage.removeItem(Prefixes.nimbusLocalStorage + item);
            });

            resetStoreValues();
        };

        // reset the active values and
        const resetActiveTimerValues = () => {
            activeTimer.value = getActiveTime()
            isUserIdle.value = false
        }

        const setActiveTimer = () => {
            // clear the interval first before execute
            clearActiveInterval()

            activeInterval.value = window.setInterval(() => {
                if (!userService.isAuthenticated()) return
                if (activeTimer.value > 0) {
                    activeTimer.value--;
                } else {
                    clearInterval(activeInterval.value)
                    if(!isUserIdle.value) {
                        triggerRefreshToken()
                    }
                }
            },1000);
        }

        // trigger refresh token and reset values
        const triggerRefreshToken = () => {
            refreshToken();
            activeTimer.value = getActiveTime()
            isUserIdle.value = false
            setActiveTimer()
        }

        // clear the interval of active timer
        const clearActiveInterval = () => {
            clearInterval(activeInterval.value)
        }

        return {
            /**
             * State
             */
            timer,
            expiryTime,
            warningTime,
            activeTimer,
            isUserIdle,
            isTimeWarned,
            warningTimerInterval,
            showIdleWarningInLogin,
            /**
             * Getters
             */
            warningTimer,
            /**
             * Actions
             */
            setSettingsTimer,
            staySignedIn,
            logoutActiveUser,
            autoStartTimer,
            resetTimer,
            resetStoreValues,
            removeLSInAutoLogout,
            setActiveTimer,
            clearActiveInterval,
            resetActiveTimerValues,
            clearTimerInterval
        };
    },
    {
        persist: true,
        share: {
            omit: [
                'expiryTime',
                'warningTime',
                'isTimeExpired',
                'activeTimer',
                'isUserIdle',
                'isTimeWarned',
                'warningTimer',
                'staySignedIn',
                'logoutActiveUser',
                'clearTimerInterval',
                'autoStartTimer',
                'resetStoreValues',
                'removeLSInAutoLogout',
                'setActiveTimer',
                'resetActiveTimerValues'
            ],
            // Override global config for this store.
            enable: true,
            initialize: true,
        },
    }
);
