<template>
    <NimbusInputWrapper v-bind="$props" ref="wrapper" @right-icon-click="(e) => $emit('rightIconClick', e)" right-icon-out left-icon-disabled>
        <template #input>
            <!-- v-tooltip directive throws error when given a null value -->
            <span v-if="tooltip" v-tooltip="tooltip">
                <Dropdown v-bind="$attrs" @change="updateInput" :class="[wrapper?.inputBorderColorClass, inputClass]" :modelValue="modelValue" class="w-full niw-dynamic-border" />
            </span>

            <!-- custom dropdown with badge -->
            <Dropdown
                v-else-if="$slots['customize-option']"
                v-bind="$attrs"
                @change="updateInput"
                :class="[wrapper?.inputBorderColorClass, inputClass]"
                :modelValue="modelValue"
                class="w-full niw-dynamic-border"
            >
                <template #value>
                    <slot name="customize-option" />
                </template>
                <template #option>
                    <slot name="customize-option" />
                </template>
            </Dropdown>

            <!--
                @todo:
                    This is a replacement for the above condition for more flexibility. For now, just let it be,
                    since it needs to check for there usage.
            -->
            <Dropdown
                v-else-if="$slots['custom-value'] && $slots['custom-option']"
                v-bind="$attrs"
                @change="updateInput"
                :class="[wrapper?.inputBorderColorClass, inputClass]"
                :modelValue="modelValue"
                class="w-full niw-dynamic-border"
            >
                <template #value="slotProps">
                    <slot name="custom-value" :value="slotProps.value" :placeholder="slotProps.placeholder" />
                </template>
                <template #option="slotProps">
                    <slot name="custom-option" :option="slotProps" />
                </template>
            </Dropdown>

            <!-- render component with no v-tooltip directive if tooltip is null -->
            <Dropdown
                v-else
                v-bind="$attrs"
                @change="updateInput"
                :class="[wrapper?.inputBorderColorClass, inputClass]"
                :modelValue="modelValue"
                class="w-full niw-dynamic-border"
            />
        </template>
        <template #bottom>
            <slot />
        </template>
    </NimbusInputWrapper>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';

import { ref } from 'vue';

export default {
    name: 'NimbusDropdown',
    components: {
        NimbusInputWrapper,
        Dropdown,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: [String, Number],
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = (event) => {
            emit('update:modelValue', event.value);
        };

        return {
            /**
             * Data
             */
            wrapper,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>
