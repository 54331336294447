export default [
    {
        path: '/contact',
        name: 'Contacts',
        exact: true,
        component: () => import('@/pages/contact/Index'),
        meta: {
            auth: true,
            permission: ['contact.access', 'portal.contact.access'],
        },
    },
];
