export default [
    {
        path: '/not-found',
        name: 'not-found',
        exact: true,
        component: () => import('@/pages/NotFound.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/404',
        name: 'Not Found',
        component: () => import('@/pages/NotFound.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: () => import('@/pages/Access.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/pages/Error.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/page-unknown',
        name: 'Page not found',
        exact: true,
        component: () => import('@/pages/NotFound.vue'),
        meta: {
            auth: false,
        },
    }
]