<template>
    <div class="gap-5 px-3" :class="[layout === 'row' ? 'flex' : 'flex-column']">
        <label class="flex align-items-center font-bold">
            <i v-if="labelIcon" class="pi mr-2" :class="labelIcon"></i>
            <div>{{ labelText }}</div>
        </label>
        <slot name="content" />
    </div>
</template>

<script>
export default {
    name: 'Content',
    props: {
        labelText: {
            type: String,
            required: true,
        },
        labelIcon: {
            type: String,
            required: false,
            default: null,
        },
        layout: {
            type: String,
            default: 'row',
        },
    },
};
</script>

<style scoped></style>
