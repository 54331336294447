export default [
    {
        path: '/feature/',
        name: 'Feature',
        exact: true,
        component: () => import('@/pages/feature/Index.vue'),
        meta: {
            auth: true,
            permission: ['feature.access']
        },
    },
    {
        path: '/feature/create',
        name: 'Create Feature',
        exact: true,
        component: () => import('@/pages/feature/Create.vue'),
        meta: {
            auth: true,
            permission: ['feature.create']
        },
    },
    {
        path: '/feature/edit/:id',
        name: 'Edit Feature',
        exact: true,
        component: () => import('@/pages/feature/Edit.vue'),
        meta: {
            auth: true,
            permission: ['feature.edit']
        },
    },
    {
        path: '/feature/:id',
        name: 'View Feature',
        exact: true,
        component: () => import('@/pages/feature/View.vue'),
        meta: {
            auth: true,
            permission: ['feature.view']
        },
    },
]